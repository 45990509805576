import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import modules from "@/store/modules";

Vue.use(Vuex);

const localKey = "A55_" + process.env.VUE_APP_MODE;

const vuexLocal = new VuexPersistence({
	strictMode: true,
	key: localKey + "_midgard",
	modules: ["auth", "config", "banking", "monitoring"],
});

const store = new Vuex.Store({
	strict: true,
	modules,
	mutations: {
		RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
	},
	plugins: [vuexLocal.plugin],
});

export default store;
