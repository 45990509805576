import { RG_EMITTERS, UFS } from "@/core/constants";

const rgEmitters = RG_EMITTERS.map(({ label }) => ({
	id: label,
	name: label,
}));

const ufs = [...UFS]
	.sort((a, b) => (a.nome > b.nome ? 1 : -1))
	.map(({ sigla }) => ({
		id: sigla,
		name: sigla,
	}));

const GENDERS = [
	{ id: "F", name: "Feminino" },
	{ id: "M", name: "Masculino" },
];

export const parseNaturalPerson = (person) => {
	if (!person) return;
	const { idEmitter, idEmitterUF, gender, personalContactInfo } = person;

	const { state } = personalContactInfo || {};
	const [splittedEmitter, splittedEmitterUF] =
		typeof idEmitter === "string" ? idEmitter.split("-") : [];
	const emitter = rgEmitters.find((it) => it.id === splittedEmitter);
	const uf = ufs.find((it) => it.id === splittedEmitterUF);

	return {
		...person,
		...personalContactInfo,
		percentageOwned: "",
		idEmitter: emitter || idEmitter || undefined,
		idEmitterUF: uf || idEmitterUF || undefined,
		gender: gender && GENDERS.find((it) => it.id === gender),
		state: state && ufs.find((it) => it.id === state),
	};
};
