import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { NotFoundError, UnexpectedError } from "@/core/errors";

export const remoteLoadOperationGuarantees = (httpClient, baseUrl) => ({
	run: async () => {
		const response = await httpClient.request({
			method: HttpMethod.GET,
			url: `${baseUrl}/proposals/guarantees`,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				return response.body;
			}

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError("Garantias não encontradas!");

			default:
				throw new UnexpectedError(
					"Ocorreu um erro inesperado ao buscar as garantias!"
				);
		}
	},
});
