import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import {
	NotFoundError,
	UnexpectedError,
	InvalidModelError,
} from "@/core/errors";

export const remoteCreateDuplicate = (httpClient, baseUrl) => ({
	run: async (payload) => {
		const response = await httpClient.request({
			url: `${baseUrl}/boleto`,
			method: HttpMethod.POST,
			body: {
				expected_cashflow_id: payload.expected_cashflow_id,
				amount: payload.amount,
				due_date: payload.due_date,
				consider_fine: payload.consider_fine,
				partial: payload.partial,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.CREATED:
				return response.body;

			case HttpStatusCode.BAD_REQUEST:
				throw new InvalidModelError(
					"Essa parcela já possui um boleto em aberto!"
				);

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError("Boleto solicitado não encontrado!");

			default:
				throw new UnexpectedError(
					"Ocorreu um erro inesperado ao emitir segunda via!"
				);
		}
	},
});
