import { hasActiveErrors } from "@/core/utils";
import { validateRequired } from "@/core/validators";

export const createEnum = (value, allowedValues) => {
	const errors = { params: {} };

	errors.required = !validateRequired(value);
	errors.enum = !allowedValues.includes(value);
	errors.params.enum = allowedValues;

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value };
};
