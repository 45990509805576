export const getPersonDocumentGroups = () => ({
	run: (documentNumber, clientProcess) => {
		const groups = [];

		const groupsKeys = Object.keys(clientProcess.groups);

		for (const groupKey of groupsKeys) {
			const foundPersonInGroup = clientProcess.groups[groupKey].find(
				(person) => person.document_number === documentNumber
			);

			if (foundPersonInGroup) {
				groups.push(groupKey);
			}
		}

		return groups;
	},
});
