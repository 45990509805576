import { HttpMethod, HttpStatusCode } from "@/core/adapters";

export const remoteLoadPartnershipOrders = (httpClient, baseUrl) => ({
	run: async (input = {}) => {
		let query = {};

		Object.entries(input.metadata).map(([key, value]) => {
			const defaultFilter = ["", 0, undefined, null].includes(value);
			if (!defaultFilter) query[key] = value;
		});

		const response = await httpClient.request({
			method: HttpMethod.GET,
			url: `${baseUrl}/partner/order/receivable`,
			query,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				const { results, ...metadata } = response.body;

				return { results, metadata };
			}

			default:
				throw new String("Erro ao buscar pedidos dos parceiros.");
		}
	},
});