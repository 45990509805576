const companyProfile = () => ({
	cnpj: undefined,
	name: undefined,
	registeredName: undefined,
	foundingDate: undefined,
	percentageOwned: 0,
	fund: false,
	id: undefined,
	relatedTaxId: undefined,
});

const companyContact = () => ({
	email: undefined,
	phone: undefined,
	website: undefined,
	averageAnnualRevenue: undefined,
	industry: {},
	zipCode: undefined,
	complement: undefined,
	address: undefined,
	number: undefined,
	neighborhood: undefined,
	city: undefined,
	state: undefined,
});

const company = () => ({
	cnpj: "",
	name: "",
	registeredName: "",
	foundingDate: "",
	fund: false,
	id: "",
	relatedTaxId: "",
	email: "",
	phone: "",
	website: "",
	averageAnnualRevenue: "",
	industry: {},
	zipCode: "",
	complement: "",
	address: "",
	number: "",
	neighborhood: "",
	city: "",
	state: "",
});

const personProfile = () => ({
	cpf: undefined,
	isSigner: false,
	isRequiredSigner: false,
	isProcurator: false,
	isSignerProcurator: false,
	isGuarantor: false,
	percentageOwned: 0,
	name: undefined,
	procuratorId: undefined,
	clientRepresentativeId: undefined,
	taxId: undefined,
	isSpouse: undefined,
	isDivorced: undefined,
	relatedTaxId: undefined,
});

const naturalPerson = () => ({
	id: undefined,
	name: undefined,
	idNumber: undefined,
	idEmitter: undefined,
	idEmissionDate: undefined,
	idEmitterUF: undefined,
	percentageOwned: undefined,
	phone: undefined,
	email: undefined,
	motherName: undefined,
	fatherName: undefined,
	taxId: undefined,
	naturality: undefined,
	nationality: undefined,
	birthDate: undefined,
	gender: undefined,
	zipCode: undefined,
	complement: undefined,
	address: undefined,
	number: undefined,
	neighborhood: undefined,
	city: undefined,
	state: undefined,
	maritalStatus: undefined,
	marriageRegime: undefined,
	spouse: undefined,
});

const spouse = () => ({
	id: undefined,
	name: undefined,
	// documentType: undefined,
	idNumber: undefined,
	idEmitter: undefined,
	idEmissionDate: undefined,
	birthDate: undefined,
	idEmitterUF: undefined,
	taxId: undefined,
	phone: undefined,
	email: undefined,
});

const naturalPersonComplementary = () => ({
	id: undefined,
	monthlyIncome: 0,
	netWorth: 0,
	companyName: undefined,
	position: undefined,
});

const pepForm = () => ({
	pep: false,
	pepPeriod: "",
	pepPosition: "",
	pepOrganization: "",
});

const generalData = () => ({
	email: undefined,
	phone: undefined,
	socialCapital: undefined,
	averageAnnualRevenue: 0,
	employee: 0,
	taxType: undefined,
	riscoSocioAmbiental: undefined,
	openCapital: undefined,
});

const disbursement = () => ({
	bankName: undefined,
	bankAgency: undefined,
	bankAccount: undefined,
	bankAccountDigit: undefined,
});

export const getDefaultModel = (type) => {
	const models = {
		company,
		companyProfile,
		companyContact,
		naturalPerson,
		personProfile,
		spouse,
		naturalPersonComplementary,
		pepForm,
		generalData,
		disbursement,
	};

	return models[type]();
};
