import gql from "graphql-tag";

const personFragment = `
	edges {
		node {
			id
			naturality
			nationality
			personalContactInfo {
				address
				number
				neighborhood
				city
				state
				zipCode
				phone
				email
			}
			taxId
			monthlyIncome
			gender
			netWorth
			fatherName
			motherName
			marriageRegime {
				id
				description
			}
			maritalStatus {
				id
				statusDescription
			}
			spouse {
				id
				taxId
				name
				idNumber
				idEmitter
				idEmissionDate
				personalContactInfo {
					email
					phone
				}
			}
			name
			birthDate
			idNumber
			idEmitter
			idEmissionDate
			position
			otherRevenue
			pep
			pepPosition
			pepOrganization
			pepRelationships {
				edges {
					node {
						id
						relationshipType
					}
				}
			}
			pepPeriod
			pepSecondary {
				edges {
					node {
						id
						person2 {
							id
							name
							taxId
							pepRelationships {
								edges {
									node {
										id
										relationshipType
									}
								}
							}
							pepPosition
							pepOrganization
						}
						relationshipType
					}
				}
			}
		}
	}
`;

const buildQuery = (taxIds) => {
	const rawVariables = [];

	const queries = taxIds
		.map((taxId, index) => {
			const variableName = `$taxId${index}`;
			rawVariables.push({
				index,
				name: variableName,
			});

			return `
				person${index}: personDetails(taxId: ${variableName}) {
					${personFragment}
				}
			`;
		})
		.join("\n");

	const rawVariablesNames = rawVariables
		.map(({ name }) => `${name}: String`)
		.join(", ");
	const query = gql`
		query AllPerson(${rawVariablesNames}) {
			${queries}
		}
	`;

	const variables = rawVariables.reduce(
		(previousVariables, currentRawVariable) => ({
			...previousVariables,
			[currentRawVariable.name.replace("$", "")]:
				taxIds[currentRawVariable.index],
		}),
		{}
	);

	return { query, variables };
};

export const remoteGetPeopleRepository = (graphQLClient) => ({
	run: async (taxIds) => {
		try {
			if (!taxIds.length) {
				return [];
			}

			const { query, variables } = buildQuery(taxIds);
			const response = await graphQLClient.query({ query, variables });
			const people = [];

			Object.keys(response.data).forEach((dataName) => {
				const person = response.data[dataName].edges?.[0]?.node;
				person && people.push(person);
			});

			return people;
		} catch (error) {
			console.log(error, { ...error });
			throw new Error("Erro ao buscar lista de pessoas!");
		}
	},
});
