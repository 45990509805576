import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { PROPOSAL_STATUS } from "@/core/entities";
import { UnexpectedError } from "@/core/errors";

const formatDate = (value, options = null) => {
	const formatter = new Intl.DateTimeFormat("pt-BR", {
		dateStyle: "short",
		...options,
	});
	return formatter.format(value);
};

const mapRates = ({ number_installments, prepayment_fee, ...rate }) => ({
	...rate,
	installments: number_installments,
	prepaymentFee: prepayment_fee,
});

export const mapProposal = ({
	created_at,
	product_id,
	status,
	vehicle_id,
	...proposal
}) => ({
	...proposal,
	createdAt: created_at && formatDate(new Date(created_at)),
	productId: product_id,
	remoteRates: proposal.rates.map(mapRates),
	rates: proposal.rates.map(mapRates),
	status:
		status === "pending allocation"
			? PROPOSAL_STATUS.PENDING_ALLOCATION
			: status,
	vehicleId: vehicle_id && vehicle_id !== "undefined" ? +vehicle_id : null,
});

export const remoteLoadProposals = (httpClient, baseUrl) => ({
	run: async ({ page = 1, ...filters } = {}) => {
		const query = { page };
		filters.cnpj && (query.cnpj = filters.cnpj);
		filters.status?.length && (query.status = filters.status.join(","));

		const response = await httpClient.request({
			method: HttpMethod.GET,
			url: `${baseUrl}/proposals`,
			query,
		});

		if (response.statusCode === HttpStatusCode.OK) {
			const { results, ...metadata } = response.body;

			const data = results.map(mapProposal);

			return {
				data,
				metadata: { ...metadata, currentPage: page },
			};
		} else {
			throw new UnexpectedError("Ocorreu um erro inesperado!");
		}
	},
});
