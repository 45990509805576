function checkValueType(value) {
	const typeOfValue = typeof value;

	if (typeOfValue !== "number") {
		throw new Error(
			`value must be a number, provided ${value} as '${typeOfValue}'`
		);
	}
}

/**
 * @param {number} value
 * @returns number
 */
export function convertToCents(value) {
	checkValueType(value);

	return Math.round(value * 100);
}

/**
 *
 * @param {number} cents
 * @param {number} decimals
 * @returns number
 */
export function convertFromCents(cents) {
	checkValueType(cents);

	return +(cents / 100).toFixed(2);
}
