<template>
	<table
		class="el-month-table"
		@click="handleMonthTableClick"
		aria-label="Calendario Mensal"
	>
		<tbody>
			<tr v-for="(row, key) in rows" :key="key">
				<td v-for="(cell, key2) in row" :key="key2" :class="getCellStyle(cell)">
					<div>
						<a class="cell">{{
							t("el.datepicker.months." + months[cell.text])
						}}</a>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script type="text/babel">
import Locale from "element-ui/src/mixins/locale";
import {
	isDate,
	range,
	getDayCountOfMonth,
	nextDate,
} from "element-ui/src/utils/date-util";
import { hasClass } from "element-ui/src/utils/dom";
import {
	arrayFindIndex,
	coerceTruthyValueToArray,
	arrayFind,
} from "element-ui/src/utils/util";

const datesInMonth = (year, month) => {
	const numOfDays = getDayCountOfMonth(year, month);
	const firstDay = new Date(year, month, 1);
	return range(numOfDays).map((n) => nextDate(firstDay, n));
};

const removeFromArray = function (arr, pred) {
	const idx =
		typeof pred === "function" ? arrayFindIndex(arr, pred) : arr.indexOf(pred);
	return idx >= 0 ? [...arr.slice(0, idx), ...arr.slice(idx + 1)] : arr;
};

const clearDate = (date) => {
	return new Date(date.getFullYear(), date.getMonth());
};

const getMonthTimestamp = function (time) {
	if (typeof time === "number" || typeof time === "string") {
		return clearDate(new Date(time)).getTime();
	} else if (time instanceof Date) {
		return clearDate(time).getTime();
	} else {
		return NaN;
	}
};
export default {
	mixins: [Locale],
	props: {
		//eslint-disable-next-line
		year: {},
		//eslint-disable-next-line
		disabledDate: {},
		//eslint-disable-next-line
		value: {},
		//eslint-disable-next-line
		selectionMode: {
			default: "month",
		},
		//eslint-disable-next-line
		minDate: {},
		//eslint-disable-next-line
		maxDate: {},
		//eslint-disable-next-line
		defaultValue: {
			validator(val) {
				// null or valid Date Object
				return (
					val === null ||
					isDate(val) ||
					(Array.isArray(val) && val.every(isDate))
				);
			},
		},
		//eslint-disable-next-line
		date: {},
	},
	data() {
		return {
			months: [
				"jan",
				"feb",
				"mar",
				"apr",
				"may",
				"jun",
				"jul",
				"aug",
				"sep",
				"oct",
				"nov",
				"dec",
			],
			tableRows: [[], [], []],
			lastRow: null,
			lastColumn: null,
		};
	},
	computed: {
		rows() {
			const rows = this.tableRows;
			const disabledDate = this.disabledDate;
			const selectedDate = [];
			const now = getMonthTimestamp(new Date());

			for (let i = 0; i < 3; i++) {
				const row = rows[i];
				for (let j = 0; j < 4; j++) {
					let cell = row[j];
					if (!cell) {
						cell = {
							row: i,
							column: j,
							type: "normal",
							inRange: false,
							start: false,
							end: false,
						};
					}

					cell.type = "normal";

					const index = i * 4 + j;
					const time = new Date(this.date.getFullYear(), index).getTime();
					cell.inRange =
						time >= getMonthTimestamp(this.minDate) &&
						time <= getMonthTimestamp(this.maxDate);
					cell.start = this.minDate && time === getMonthTimestamp(this.minDate);
					cell.end = this.maxDate && time === getMonthTimestamp(this.maxDate);
					const isToday = time === now;

					if (isToday) {
						cell.type = "today";
					}
					cell.text = index;
					let cellDate = new Date(time);
					cell.disabled =
						typeof disabledDate === "function" && disabledDate(cellDate);
					cell.selected = arrayFind(
						selectedDate,
						(date) => date.getTime() === cellDate.getTime()
					);

					this.$set(row, j, cell);
				}
			}
			return rows;
		},
	},
	methods: {
		cellMatchesDate(cell, date) {
			const value = new Date(date);
			return (
				this.date.getFullYear() === value.getFullYear() &&
				Number(cell.text) === value.getMonth()
			);
		},
		getDefaultValue() {
			if (!this.defaultValue) {
				return [];
			}
			return Array.isArray(this.defaultValue)
				? this.defaultValue
				: [this.defaultValue];
		},
		getCellStyle(cell) {
			const style = {};
			const year = this.date.getFullYear();
			const today = new Date();
			const month = cell.text;
			const defaultValue = getDefaultValue();
			style.disabled =
				typeof this.disabledDate === "function"
					? datesInMonth(year, month).every(this.disabledDate)
					: false;
			style.current =
				arrayFindIndex(
					coerceTruthyValueToArray(this.value),
					(date) => date.getFullYear() === year && date.getMonth() === month
				) >= 0;
			style.today = today.getFullYear() === year && today.getMonth() === month;
			style.default = defaultValue.some((date) =>
				this.cellMatchesDate(cell, date)
			);

			if (cell.inRange) {
				style["in-range"] = true;

				if (cell.start) {
					style["start-date"] = true;
				}

				if (cell.end) {
					style["end-date"] = true;
				}
			}
			return style;
		},
		handleMonthTableClick(event) {
			let target = event.target;
			if (target.tagName === "A") {
				target = target.parentNode.parentNode;
			}
			if (target.tagName === "DIV") {
				target = target.parentNode;
			}
			if (target.tagName !== "TD") return;
			if (hasClass(target, "disabled")) return;

			const column = target.cellIndex;
			const row = target.parentNode.rowIndex;
			const month = row * 4 + column;
			const newDate = new Date(this.date.getFullYear(), month, 1);
			const value = this.value || [];

			const times = [...value].map((it) => it.getTime());
			const selected = times.indexOf(newDate.getTime()) >= 0;

			const newValue = selected
				? removeFromArray(value, (date) => date.getTime() === newDate.getTime())
				: [...value, newDate];

			this.$emit("pick", newValue);
		},
	},
};
</script>
