import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import { setInterceptors } from "@/plugins/axios";
import { makeRemoteLoadProposalRefusal } from "@/core/factories";

const loadProposalRefusal = makeRemoteLoadProposalRefusal();

export const proposalsAPI = axios.create({
	baseURL: process.env.VUE_APP_A55_PROPOSALS_API,
});

setInterceptors(proposalsAPI);

const state = {
	refusalDetailsMap: {},
};

const getters = {
	[types.PROPOSALS_REFUSAL_DETAILS]: "refusalDetailsMap",
};

const mutations = {
	[types.PROPOSALS_REFUSAL_DETAILS]: (state, refusalDetail) => {
		state.refusalDetailsMap = {
			...state.refusalDetailsMap,
			[refusalDetail.proposalId]: refusalDetail,
		};
	},
};

const actions = {
	[types.PROPOSALS_PROPOSAL]: (ctx, cnpj) => {
		return proposalsAPI
			.get(`/proposals/with-simulation/${cnpj}`)
			.then((response) => response.data);
	},
	[types.PROPOSALS_CREATE_PROPOSAL]: (ctx, payload) => {
		return proposalsAPI.post("/proposals", payload);
	},
	[types.PROPOSALS_DELETE_PROPOSAL]: (ctx, id) => {
		return proposalsAPI.delete(`/proposals/delete/${id}`);
	},
	[types.PROPOSALS_REFUSAL_DETAILS]: async ({ commit, state }, proposalId) => {
		if (state.refusalDetailsMap[proposalId]) {
			return state.refusalDetailsMap[proposalId];
		}

		const refusal = await loadProposalRefusal.run(proposalId);
		commit(types.PROPOSALS_REFUSAL_DETAILS, refusal);
		return refusal;
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
