export const createLocalStorageAdapter = () => ({
	async clear() {
		localStorage.clear();
	},

	async get(key) {
		const value = localStorage.getItem(key);

		try {
			return JSON.parse(value);
		} catch (error) {
			return value;
		}
	},

	async remove(key) {
		localStorage.removeItem(key);
	},

	async set(key, value) {
		if (value) {
			localStorage.setItem(key, JSON.stringify(value));
		}
	},
});
