import { hasActiveErrors } from "@/core/utils";
import { PROPOSAL_STATUS } from "@/core/entities";
import { validateRequired, validateMinLength } from "@/core/validators";
import { createAmount, createNumber, createGrace } from "@/core/value-objects";

const createProposalRate = (value, minAmount, maxAmount) => {
	const amount = createAmount(value.amount, minAmount, maxAmount);
	const grace = createGrace(value.grace);
	const installments = createNumber(value.installments, 1, 36);
	const rate = createNumber(
		value.rate,
		0,
		Infinity,
		"minPercent",
		"maxPercent"
	);
	const tac = createNumber(value.tac, 0, Infinity, "minPercent", "maxPercent");
	const prepaymentFee = createNumber(
		value.prepaymentFee,
		0,
		Infinity,
		"minPercent",
		"maxPercent"
	);

	return {
		amount: amount.errors,
		grace: grace.errors,
		installments: installments.errors,
		rate: rate.errors,
		tac: tac.errors,
		prepaymentFee: prepaymentFee.errors,
	};
};

export const createProposalRates = (
	proposalInput,
	minLength,
	isCreate = true
) => {
	const errors = { params: {} };
	const { rates } = proposalInput;
	const disableValidations =
		isCreate && proposalInput.status === PROPOSAL_STATUS.REJECTED;
	const minAmount = !disableValidations ? 0.01 : 0;
	const maxAmount = Infinity;

	errors.required = !disableValidations && !validateRequired(rates);
	errors.minLength =
		!disableValidations && !validateMinLength(rates, minLength);
	errors.each = (rates || []).map((rate) =>
		createProposalRate(rate, minAmount, maxAmount)
	);
	errors.params.minLength = minLength;

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value: rates };
};
