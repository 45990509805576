import { remoteDeletePerson } from "@/core/usecases/people";

import {
	makeRemoteDeleteGuarantor,
	makeRemoteDeleteRepresentative,
	makeRemoteDeleteCompany,
	makeRemoteDeleteProcurator,
} from "@/core/factories/people";

export const makeRemoteDeletePerson = () =>
	remoteDeletePerson(
		makeRemoteDeleteGuarantor(),
		makeRemoteDeleteRepresentative(),
		makeRemoteDeleteProcurator(),
		makeRemoteDeleteCompany()
	);
