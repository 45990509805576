import * as names from "../names";

const component = {
	[names.CREDIT_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'credit' */ "@/views/Credit/index"),
	[names.CREDIT_PROPOSAL_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'credit' */ "@/views/Credit/Proposal/index"),
	[names.CREDIT_PROPOSAL_CREATE]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'credit' */ "@/views/Credit/Proposal/CreateOrEdit"),
	[names.CREDIT_PROPOSAL_EDIT]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'credit' */ "@/views/Credit/Proposal/CreateOrEdit"),
	[names.CREDIT_PROPOSAL_LIST]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'credit' */ "@/views/Credit/Proposal/List"),
	[names.CREDIT_QUERY]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'credit' */ "@/views/Credit/Query/index"),
	[names.CREDIT_RECEIVABLES_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'credit' */ "@/views/Credit/Receivables/index"),
};

const childrens = [];

childrens.push({
	path: "proposal",
	component: () => component[names.CREDIT_PROPOSAL_INDEX],
	meta: {
		label: "Propostas",
		path: "/credit/proposal",
		icon: "price_check",
		hidden: true,
	},
	children: [
		{
			path: "new",
			name: names.CREDIT_PROPOSAL_CREATE,
			component: () => component[names.CREDIT_PROPOSAL_CREATE],
			meta: {
				label: "Nova proposta",
			},
		},
		{
			path: ":proposalId/edit",
			name: names.CREDIT_PROPOSAL_EDIT,
			meta: {
				label: "Editar proposta",
			},
			props: (to) => ({ proposalId: +to.params.proposalId }),
			beforeEnter(to, from, next) {
				const notFoundRoute = { name: names.NOT_FOUND };

				try {
					const id = +to.params.proposalId;
					Number.isInteger(id) && id > 0 ? next() : next(notFoundRoute);
				} catch (error) {
					next(notFoundRoute);
				}
			},
			component: () => component[names.CREDIT_PROPOSAL_EDIT],
		},
		{
			path: "",
			name: names.CREDIT_PROPOSAL_LIST,
			component: () => component[names.CREDIT_PROPOSAL_LIST],
			meta: {
				hideLabel: true,
			},
		},
	],
});

childrens.push({
	path: "receivables",
	name: names.CREDIT_RECEIVABLES_INDEX,
	component: () => component[names.CREDIT_RECEIVABLES_INDEX],
	meta: {
		label: "Recebíveis",
		path: "/credit/receivables",
		icon: "calendar_month",
	},
});

childrens.push({
	path: "query",
	name: names.CREDIT_QUERY,
	component: () => component[names.CREDIT_QUERY],
	meta: {
		label: "Consulta",
		path: "/credit/query",
		icon: "search",
	},
});

export default {
	path: "credit",
	name: names.CREDIT_INDEX,
	component: () => component[names.CREDIT_PROPOSAL_INDEX],
	redirect: { name: names.CREDIT_PROPOSAL_LIST },
	meta: {
		isGroup: true,
		label: "Crédito",
		path: "/credit",
		icon: "flag",
		hidden: true,
	},
	children: childrens,
};
