import { hasActiveErrors } from "@/core/utils";
import {
	validateMaxValue,
	validateMinValue,
	validateNumber,
	validateRequired,
} from "@/core/validators";

const defaultMaxValue = Number.MAX_SAFE_INTEGER;

export const createNumber = (
	value,
	minValue = 0,
	maxValue = defaultMaxValue,
	minValueProp = "minValue",
	maxValueProp = "maxValue",
	required = true
) => {
	const errors = { params: {} };

	errors.required = required && !validateRequired(value);
	errors.number = required && !validateNumber(value);
	errors[minValueProp] = required && !validateMinValue(value, minValue);
	errors[maxValueProp] = required && !validateMaxValue(value, maxValue);
	errors.params[minValueProp] = minValue;
	errors.params[maxValueProp] = maxValue;

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value };
};
