import { remoteGetCompanyData } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const parentCompanyQuery = gql`
	query allClient($cnpj: String!) {
		allClient(cnpj: $cnpj) {
			edges {
				node {
					id
					name
					registeredName
					cnpj
					foundingDate
					fund
					email
					phone
					website
					averageAnnualRevenue
					industry {
						id
						industry
					}
					zipCode
					complement
					address
					number
					neighborhood
					city
					state
				}
			}
		}
	}
`;

export const makeRemoteGetCompanyData = () =>
	remoteGetCompanyData(clients["client-manager"], parentCompanyQuery);
