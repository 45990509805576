import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { UnexpectedError } from "@/core/errors";

/*
 * type doc = 'addendum' | 'confession_of_debt'
 */
export const remoteLoadRenegotiationDocument = (httpClient, baseUrl) => ({
	run: async (instrumentId, doc) => {
		const response = await httpClient.request({
			url: `${baseUrl}/get-report/${instrumentId}/${doc}`,
			method: HttpMethod.GET,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return response.body;

			default:
				throw new UnexpectedError("Erro inesperado ao gerar documento!");
		}
	},
});
