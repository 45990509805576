import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { UnexpectedError } from "@/core/errors";

export const remoteCreateRenegotiationInstrument = (httpClient, baseUrl) => ({
	run: async (renegotiation_id) => {
		const response = await httpClient.request({
			url: `${baseUrl}/renegotiation-instrument`,
			method: HttpMethod.POST,
			body: {
				renegotiation_id,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return response.body;

			case HttpStatusCode.CREATED:
				return response.body;

			default:
				throw new UnexpectedError(
					"Erro inesperado ao criar instrumento da renegociação!"
				);
		}
	},
});
