import * as types from "@/store/types";
import * as Sentry from "@sentry/vue";
import axios from "axios";
import shortly from "vuex-shortly";

import { makeLocalLoadUserPermissions } from "@/core/factories";

const state = {
	type: null, // staff | baas
	user: {},
	userPermissions: [],
	users: [],
	token: null,
};

const getters = {
	[types.AUTH_USER]: "user",
	[types.AUTH_USER_PERMISSIONS]: "userPermissions",
	[types.AUTH_USERS]: "users",
	[types.AUTH_TOKEN]: "token",
	[types.AUTH_TYPE]: "type",
};

const mutations = {
	[types.AUTH_USER]: (state, payload) => {
		const { email, id, organization: { cnpj } = { cnpj: "" } } = payload;

		Sentry.setUser({ email, id, username: cnpj });

		state.user = { ...payload };
	},
	[types.AUTH_USER_PERMISSIONS]: "userPermissions",
	[types.AUTH_USERS]: "users",
	[types.AUTH_TOKEN]: "token",
	[types.AUTH_TYPE]: "type",
};

const actions = {
	[types.AUTH_VERIFY]: async ({ commit }) => {
		const response = await axios.get("/user");
		const { ...userData } = response.data.user;
		commit(types.AUTH_USER, userData);

		const userPermissions = await makeLocalLoadUserPermissions(userData).run();
		commit(types.AUTH_USER_PERMISSIONS, userPermissions);

		return response;
	},
	[types.AUTH_LOGOUT]: ({ commit }) => {
		Sentry.configureScope((scope) => scope.setUser(null));
		commit(types.AUTH_TOKEN, null);
		commit(types.AUTH_USER, {});
	},
	[types.AUTH_CREATE_USER]: (ctx, payload) => {
		return axios.post("/createUser", payload);
	},
	[types.AUTH_RECEITAWS]: (ctx, cnpj) => {
		return axios.get(`/v1/cnpj/${cnpj}`, {
			baseURL: "https://www.receitaws.com.br",
			headers: {
				Authorization: `Bearer ${process.env.VUE_APP_A55_RECEITA_KEY}`,
			},
		});
	},
	[types.AUTH_TERMS_DOWNLOAD]: (ctx, cnpj) => {
		return axios.get(`/auth/get-terms-accept?cnpj=${cnpj}`);
	},
	[types.AUTH_GOOGLE_LOGIN]: async ({ commit }, token) => {
		const { data } = await axios.post("/auth/google/signin", {
			token,
		});
		const { user } = data;
		commit(types.AUTH_TOKEN, user.token);
		commit(types.AUTH_USER, user);
		commit(types.AUTH_TYPE, "staff");
		return user;
	},
	[types.AUTH_LOGIN]: async ({ commit }, payload) => {
		const { data } = await axios.post("/auth/signin", {
			user: {
				email: payload.email,
				password: payload.password,
			},
		});
		console.log(data);
		const { user } = data;
		commit(types.AUTH_TOKEN, user.token);
		commit(types.AUTH_USER, user);
		commit(types.AUTH_TYPE, "baas");
		return user;
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
