import gql from "graphql-tag";
import { remoteSubmitMaritalData } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";

import { makeRemoteGetPersonByDocument } from "@/core/factories/people";
const getPersonByDocument = makeRemoteGetPersonByDocument();

const SpouseCreateMutation = gql`
	mutation spouseCreate(
		$name: String!
		$taxId: String!
		$birthDate: Date!
		$idNumber: String!
		$idEmitter: String!
		$idEmissionDate: Date!
		$personalContactInfo: ContactInfoInput!
	) {
		createPerson(
			name: $name
			taxId: $taxId
			birthDate: $birthDate
			idNumber: $idNumber
			idEmitter: $idEmitter
			idEmissionDate: $idEmissionDate
			personalContactInfo: $personalContactInfo
		) {
			person {
				id
				name
				taxId
			}
		}
	}
`;

const SpouseUpdateMutation = gql`
	mutation updateSpouse(
		$personIdGraphql: ID!
		$name: String
		$taxId: String
		$birthDate: Date
		$idNumber: String
		$idEmitter: String
		$idEmissionDate: Date
		$personalContactInfo: ContactInfoInput
	) {
		updatePerson(
			personIdGraphql: $personIdGraphql
			name: $name
			taxId: $taxId
			birthDate: $birthDate
			idNumber: $idNumber
			idEmitter: $idEmitter
			idEmissionDate: $idEmissionDate
			personalContactInfo: $personalContactInfo
		) {
			person {
				id
				name
				taxId
			}
		}
	}
`;

const MaritalStatusMutation = gql`
	mutation updateSpouse(
		$personIdGraphql: ID!
		$maritalStatusIdGraphql: ID
		$spouseIdGraphql: ID
		$marriageRegimeId: Int
	) {
		updatePerson(
			personIdGraphql: $personIdGraphql
			maritalStatusIdGraphql: $maritalStatusIdGraphql
			spouseIdGraphql: $spouseIdGraphql
			marriageRegimeId: $marriageRegimeId
		) {
			person {
				id
				name
				taxId
			}
		}
	}
`;

export const makeRemoteSubmitMaritalData = () =>
	remoteSubmitMaritalData(
		clients["client-manager"],
		getPersonByDocument,
		SpouseCreateMutation,
		SpouseUpdateMutation,
		MaritalStatusMutation
	);
