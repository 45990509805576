const amountKeys = ["minAmount", "maxAmount"];
const percentKeys = ["minPercent", "maxPercent"];
const allErrorKeys = [
	...amountKeys,
	...percentKeys,
	"cnpj",
	"each",
	"enum",
	"maxLength",
	"minLength",
	"maxValue",
	"minValue",
	"number",
	"required",
	"status",
	"reason_for_change",
	"string",
];

export const hasActiveErrors = (errors) => {
	const errorsKeys = Object.keys(errors).filter(
		(errorKey) => errorKey !== "params"
	);

	return errorsKeys.some((errorKey) => {
		if (!allErrorKeys.includes(errorKey)) {
			return hasActiveErrors(errors[errorKey]);
		}

		if (errorKey !== "each") {
			if (!errors.params) {
				return hasActiveErrors(errors[errorKey]);
			}

			return errors[errorKey];
		}

		return errors.each?.some((nestedValue) => {
			if (nestedValue.params) {
				return hasActiveErrors(nestedValue);
			}

			const nestedValueKeys = Object.keys(nestedValue);

			return nestedValueKeys.some((nestedErrorKey) =>
				hasActiveErrors(nestedValue[nestedErrorKey])
			);
		});
	});
};
