import axios from "axios";
import store from "@/store/index";
import { AUTH_TOKEN } from "@/store/types";

const setRequestInterceptor = (ctx) => {
	ctx.interceptors.request.use(
		(config) => {
			const interceptedConfig = config;
			const token = store.getters[AUTH_TOKEN];
			if (!interceptedConfig.login) {
				interceptedConfig.headers.common = {
					Authorization: `Token ${token}`,
				};
			}
			return interceptedConfig;
		},
		(error) => Promise.reject(error)
	);
};

const setResponseInterceptor = (ctx) => {
	ctx.interceptors.response.use(
		(response) => response,
		(error) => {
			if (401 === error.response.status) {
				window.location = process.env.VUE_APP_A55_AUTH;
			}
			return Promise.reject(error);
		}
	);
};

export const setInterceptors = (ctx) => {
	setRequestInterceptor(ctx);
	setResponseInterceptor(ctx);
};

axios.defaults.baseURL = process.env.VUE_APP_A55_AUTH_API;

setInterceptors(axios);
