import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { UnexpectedError } from "@/core/errors";

export const remoteCreateRenegotiationCalculation = (httpClient, baseUrl) => ({
	run: async (payload) => {
		const response = await httpClient.request({
			url: `${baseUrl}/renegotiation-calculation`,
			method: HttpMethod.POST,
			body: {
				...payload,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return response.body;

			case HttpStatusCode.CREATED:
				return response.body;

			default:
				throw new UnexpectedError("Erro inesperado ao calcular renegociação!");
		}
	},
});
