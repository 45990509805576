import { remoteUpdateClientGeneralData } from "@/core/usecases";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

import { makeLocalParseClientGeneralData } from "@/core/factories";

const mutation = gql`
	mutation updateClient(
		$cnpj: String!
		$employee: Int
		$socialCapital: BigInt
		$averageAnnualRevenue: BigInt
		$email: String!
		$phone: String
		$taxTypeIdGraphql: ID
		$openCapital: Boolean
		$riscoSocioAmbientalIdGraphql: ID
	) {
		updateClient(
			cnpj: $cnpj
			employee: $employee
			socialCapital: $socialCapital
			averageAnnualRevenue: $averageAnnualRevenue
			email: $email
			phone: $phone
			taxTypeIdGraphql: $taxTypeIdGraphql
			openCapital: $openCapital
			riscoSocioAmbientalIdGraphql: $riscoSocioAmbientalIdGraphql
		) {
			client {
				employee
				socialCapital
				averageAnnualRevenue
				email
				openCapital
				phone
				taxType {
					id
					description
				}
				riscoSocioAmbiental {
					id
					description
				}
			}
		}
	}
`;

export const makeRemoteUpdateClientGeneralData = () =>
	remoteUpdateClientGeneralData(
		clients["client-manager"],
		mutation,
		makeLocalParseClientGeneralData()
	);
