import { CustomError } from "./CustomError";

export class ValidationError extends CustomError {
	constructor(message, errors) {
		super(message);

		this.name = "ValidationError";
		this.errors = errors;
	}
}
