import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { NotFoundError, UnexpectedError } from "@/core/errors";
import { mapProposal } from "./remoteLoadProposals";

export const remoteLoadProposal = (httpClient, baseUrl) => ({
	run: async (proposalId) => {
		const response = await httpClient.request({
			method: HttpMethod.GET,
			url: `${baseUrl}/proposals/${proposalId}/`,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				return mapProposal(response.body);
			}

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError(
					`Proposta com id '${proposalId}' não encontrada!`
				);

			default:
				throw new UnexpectedError(
					`Ocorreu um erro inesperado ao buscar proposta #${proposalId}!`
				);
		}
	},
});
