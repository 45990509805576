export const localParseClientBusinessData = () => ({
	run: (model) => {
		const [street, number, complement] = model.address?.split?.(",") || [];
		const secondaryActivityList =
			model?.secondaryActivity?.edges
				?.map((it) => it.node)
				.map(({ id, code, description }) => ({
					id,
					name: `${code || "N/A"} | ${description || "N/A"}`,
				})) || [];

		const mainActivityIdGraphql = model?.mainActivity
			? {
					id: model.mainActivity?.id,
					name: `${model.mainActivity?.code} | ${model.mainActivity?.description}`,
			  }
			: {};

		return {
			...model,
			street,
			number,
			complement,
			mainActivityIdGraphql,
			secondaryActivityList,
			societaryType: model.societaryType,
		};
	},
});
