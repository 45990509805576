import { remoteCreateCompanyRelationship } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const mutation = gql`
	mutation createCompanyRelationship(
		$percentageOwned: Float!
		$childCompanyId: String!
		$parentCompanyId: String!
	) {
		createUpdateCompanyRelationship(
			percentageOwned: $percentageOwned
			clientTaxId: $childCompanyId
			partnerTaxId: $parentCompanyId
		) {
			companyRelationship {
				id
				parentCompany {
					cnpj
				}
				childCompany {
					cnpj
				}
			}
		}
	}
`;

export const makeRemoteCreateCompanyRelationship = () =>
	remoteCreateCompanyRelationship(clients["client-manager"], mutation);
