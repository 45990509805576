import { InvalidModelError, UnexpectedError } from "@/core/errors";
import { HttpMethod, HttpStatusCode } from "@/core/adapters";

export const remoteCalculatePenalty = (httpClient, baseUrl) => ({
	run: async (payload) => {
		const response = await httpClient.request({
			method: HttpMethod.POST,
			url: `${baseUrl}/calculation_penalty_arrears_boleto`,
			body: {
				base_date: payload.dueDate,
				default_interest: 1,
				fine_moratorium: 2,
				contract_number: payload.contractNumber,
				overdue_installment: payload.overdueInstallment,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return response.body;

			case HttpStatusCode.BAD_REQUEST: {
				throw new InvalidModelError("Dados inválidos para calculo da multa");
			}

			default:
				throw new UnexpectedError("Erro inesperado ao calcular a multa!");
		}
	},
});
