import * as names from "../names";

const component = {
	[names.DASHBOARD_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'dashboard' */ "@/views/Dashboard"),
	[names.DASHBOARD_OVERVIEW]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'dashboard' */ "@/views/Dashboard/Overview"),
	[names.DASHBOARD_COMPANY]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'dashboard' */ "@/views/Dashboard/Company"),
	[names.DASHBOARD_DEFAULTERS]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'dashboard' */ "@/views/Dashboard/Defaulters"),
};

const routes = {
	path: "dashboard",
	name: names.DASHBOARD_INDEX,
	component: () => component[names.DASHBOARD_INDEX],
	redirect: { name: names.DASHBOARD_OVERVIEW },
	meta: {
		label: "Dashboard",
		icon: "bar_chart",
		path: "/dashboard",
	},
	children: [
		{
			path: "/",
			name: names.DASHBOARD_OVERVIEW,
			meta: {
				hideLabel: true,
			},
			component: () => component[names.DASHBOARD_OVERVIEW],
		},
		{
			path: "defaulters",
			name: names.DASHBOARD_DEFAULTERS,
			component: () => component[names.DASHBOARD_DEFAULTERS],
			meta: {
				label: "Inadimplentes",
			},
		},
		{
			path: ":taxId",
			name: names.DASHBOARD_COMPANY,
			meta: {
				label: "Detalhes da empresa",
			},
			props: (to) => ({ taxId: to.params.taxId }),
			beforeEnter(to, from, next) {
				if (to.params.taxId?.length !== 14) {
					return next({ name: names.NOT_FOUND });
				}

				return next();
			},
			component: () => component[names.DASHBOARD_COMPANY],
		},
	],
};

const getRouteViewByName = (route) => {
	const view = component?.[route?.name];
	const hasChildren = route?.children?.length;
	return {
		...route,
		children: hasChildren && route.children.map(getRouteViewByName),
		component: () => (view ? view : route.component),
	};
};

const theRoutes = getRouteViewByName(routes);
export default theRoutes;
