import * as types from "@/store/types";
import shortly from "vuex-shortly";
import {
	makeRemoteLoadPartnership,
	makeRemoteLoadRiskPolicy,
} from "@/core/factories/partnership";

const loadPartnership = makeRemoteLoadPartnership();

const state = {
	partnershipList: [],
	
};

const getters = {
	[types.PARTNERSHIP_LIST]: "partnershipList",
	
};

const mutations = {
	[types.PARTNERSHIP_LIST]: "partnershipList",
	
};

const actions = {
	[types.PARTNERSHIP_LIST]: async ({ commit }, { metadata }) => {
		const partnerships = await loadPartnership.run({
			metadata,
		});

		commit(types.PARTNERSHIP_LIST, partnerships.results || []);
		return partnerships.results || [];
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});