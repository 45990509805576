import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { NotFoundError, UnexpectedError } from "@/core/errors";

export const remoteDeleteProposal = (httpClient, baseUrl) => ({
	run: async (proposalId) => {
		const response = await httpClient.request({
			method: HttpMethod.DELETE,
			url: `${baseUrl}/proposals/${proposalId}/`,
		});

		switch (response.statusCode) {
			case HttpStatusCode.NO_CONTENT:
				return true;

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError(
					`Proposta com id #${proposalId} não encontrada!`
				);

			default:
				throw new UnexpectedError("Erro ao deletar a proposta!");
		}
	},
});
