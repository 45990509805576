import * as names from "../names";

const component = {
	[names.COLLECTION_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'collection' */ "@/views/Collection/index"),
	[names.COLLECTION_DRAWEE]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'collection' */ "@/views/Collection/Drawee"),
	[names.COLLECTION_AMORTIZATION]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'collection' */ "@/views/Collection/Amortization"),
	[names.COLLECTION_RENEGOTIATION]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'collection' */ "@/views/Collection/Renegotiation"),
	[names.COLLECTION_RENEGOTIATION_CALC]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'collection' */ "@/views/Collection/Renegotiation/Calculator"),
	[names.COLLECTION_OPERATION_LIST]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'collection' */ "@/views/Collection/Operation"),
	[names.COLLECTION_OPERATION_VIEW]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'collection' */ "@/views/Collection/Operation/View"),
	[names.COLLECTION_BOLETOS]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'collection' */ "@/views/Collection/Boletos/index"),
};

export default {
	path: "collection",
	name: names.COLLECTION_INDEX,
	component: () => component[names.COLLECTION_INDEX],
	redirect: { name: names.COLLECTION_AMORTIZATION },
	meta: {
		isGroup: true,
		label: "Cobrança",
		path: "/collection",
		icon: "paid",
	},
	children: [
		{
			path: "amortizations",
			name: names.COLLECTION_AMORTIZATION,
			component: () => component[names.COLLECTION_AMORTIZATION],
			meta: {
				label: "Amortizações",
				path: "/collection/amortizations",
				icon: "date_range",
			},
		},
		{
			component: () => component[names.COLLECTION_OPERATION_LIST],
			name: names.COLLECTION_OPERATION_LIST,
			path: "operations",
			meta: {
				hideGroups: [],
				label: "Operações",
				path: "/collection/operations",
				icon: "format_list_numbered",
			},
			children: [
				{
					component: () => component[names.COLLECTION_OPERATION_VIEW],
					name: names.COLLECTION_OPERATION_VIEW,
					path: ":operationCode/:contractNumber?",
					meta: {
						label: "Detalhes da operação",
					},
				},
			],
		},
		/* {
			path: "drawee",
			component: () => component[names.COLLECTION_DRAWEE],
			name: names.COLLECTION_DRAWEE,
			meta: {
				label: "Sacado",
				path: "/collection/drawee",
				icon: "payments",
				ignoreCrumb: true,
			},
		}, */
		{
			path: "renegotiation",
			component: () => component[names.COLLECTION_RENEGOTIATION],
			name: names.COLLECTION_RENEGOTIATION,
			meta: {
				label: "Renegociação",
				path: "/collection/renegotiation",
				icon: "discount",
				ignoreCrumb: true,
			},
			children: [
				{
					component: () => component[names.COLLECTION_RENEGOTIATION_CALC],
					name: names.COLLECTION_RENEGOTIATION_CALC,
					path: "/collection/renegotiation/calculator",
					meta: {
						label: "Caculadora",
					},
				},
			],
		},
		{
			path: "boletos",
			component: () => component[names.COLLECTION_BOLETOS],
			name: names.COLLECTION_BOLETOS,
			meta: {
				label: "Boletos",
				path: "/collection/boletos",
				icon: "description",
				ignoreCrumb: true,
			},
		},
	],
};
