import allocation from "./resources/allocation.json";
import cashflow from "./resources/cashflow.json";
import cnab from "./resources/cnab.json";
import proposal from "./resources/proposal.json";
import risk from "./resources/risk.json";
import errors from "./general/errors.json";
import sentences from "./general/sentences.json";
import table from "./components/table.json";
import drawee from "./resources/drawee.json";
import transaction from "./resources/transaction.json";
import user from "./resources/user.json";
import validations from "./general/validations.json";
import words from "./general/words.json";

export default {
	allocation,
	cashflow,
	drawee,
	errors,
	cnab,
	proposal,
	risk,
	sentences,
	table,
	transaction,
	user,
	validations,
	words,
};
