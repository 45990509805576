export const CX_INDEX = "CX_INDEX";
export const CX_RESET_PASSWORD = "RESET_PASSWORD";
export const CX_USER_INDEX = "USER_INDEX";
export const CX_USER_CREATE = "USER_CREATE";
export const CX_USER_EDIT = "USER_EDIT";
export const CX_USER_LIST = "USER_LIST";

export const COLLECTION_INDEX = "COLLECTION_INDEX";
export const COLLECTION_DRAWEE = "COLLECTION_DRAWEE";
export const COLLECTION_AMORTIZATION = "COLLECTION_AMORTIZATION";
export const COLLECTION_RENEGOTIATION = "COLLECTION_RENEGOTIATION";
export const COLLECTION_RENEGOTIATION_CALC = "COLLECTION_RENEGOTIATION_CALC";
export const COLLECTION_OPERATION_LIST = "COLLECTION_OPERATION_LIST";
export const COLLECTION_OPERATION_VIEW = "COLLECTION_OPERATION_VIEW";
export const COLLECTION_BOLETOS = "COLLECTION_BOLETOS";

export const CUSTOMERJOURNEY_INDEX = "CUSTOMERJOURNEY_INDEX";
export const CUSTOMERJOURNEY_MANAGEMENT_UPSELL_CREATE = "CUSTOMERJOURNEY_MANAGEMENT_UPSELL_CREATE";
export const CUSTOMERJOURNEY_MANAGEMENT_RESEARCHES_CREATE = "CUSTOMERJOURNEY_MANAGEMENT_RESEARCHES_CREATE";

export const CREDIT_INDEX = "CREDIT_INDEX";
export const CREDIT_PROPOSAL_INDEX = "CREDIT_PROPOSAL_INDEX";
export const CREDIT_PROPOSAL_CREATE = "CREDIT_PROPOSAL_CREATE";
export const CREDIT_PROPOSAL_EDIT = "CREDIT_PROPOSAL_EDIT";
export const CREDIT_PROPOSAL_LIST = "CREDIT_PROPOSAL_LIST";
export const CREDIT_QUERY = "CREDIT_QUERY";
export const CREDIT_CALCULATE_PROPOSAL = "CREDIT_CALCULATE_PROPOSAL";
export const CREDIT_RECEIVABLES_INDEX = "CREDIT_RECEIVABLES_INDEX";
export const CREDIT_QUERY_HISTORY = "CREDIT_QUERY_HISTORY";

export const DASHBOARD_INDEX = "DASHBOARD_INDEX";
export const DASHBOARD_OVERVIEW = "DASHBOARD_OVERVIEW";
export const DASHBOARD_COMPANY = "DASHBOARD_COMPANY";
export const DASHBOARD_DEFAULTERS = "DASHBOARD_DEFAULTERS";

export const FORMALIZATION_INDEX = "FORMALIZATION_INDEX";
export const FORMALIZATION_TRANSACTION_VIEW = "FORMALIZATION_TRANSACTION_VIEW";
export const FORMALIZATION_TRANSACTION_DOCS = "FORMALIZATION_TRANSACTION_DOCS";
export const FORMALIZATION_TRANSACTION_PROFILE = "FORMALIZATION_TRANSACTION_PROFILE";
export const FORMALIZATION_TRANSACTION_CCB = "FORMALIZATION_TRANSACTION_CCB";
export const FORMALIZATION_TRANSACTION_ALLOCATION = "FORMALIZATION_TRANSACTION_ALLOCATION";

export const ASSETS_INDEX = "ASSETS_INDEX";
export const ASSETS_MONITORING_QMM = "ASSETS_MONITORING_QMM";
export const ASSETS_MONITORING_RETENTION = "ASSETS_MONITORING_RETENTION";
export const ASSETS_MONITORING_BALANCE = "ASSETS_MONITORING_BALANCE";
export const ASSETS_TRANSFER_INDEX = "ASSETS_TRANSFER_INDEX";
export const ASSETS_TRANSFER_VIEW = "ASSETS_TRANSFER_VIEW";
export const ASSETS_TRANSFER_RETURNS = "ASSETS_TRANSFER_RETURNS";

export const INCEPTION_INDEX = "INCEPTION_INDEX";
export const INCEPTION_LEADS = "INCEPTION_LEADS";
export const INCEPTION_VIEW = "INCEPTION_VIEW";
export const INCEPTION_ACCOUNT_PLANS = "INCEPTION_ACCOUNT_PLANS";

export const SETTINGS_INDEX = "SETTINGS_INDEX";
export const SETTINGS_COMPANY_LIST = "SETTINGS_COMPANY_LIST";
export const SETTINGS_COMPANY_VIEW = "SETTINGS_COMPANY_VIEW";
export const SETTINGS_COMPANY_RISK = "SETTINGS_COMPANY_RISK";
export const SETTINGS_USER = "SETTINGS_USER";
export const SETTINGS_VEHICLES = "SETTINGS_VEHICLES";
export const SETTINGS_ISSUERS = "SETTINGS_ISSUERS";

export const NOT_FOUND = "404_NOT_FOUND";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_BAAS = "SIGN_IN_BAAS";
export const PARTNERSHIP_INDEX = "PARTNERSHIP_INDEX";
export const PARTNERSHIP_MANAGEMENT = "PARTNERSHIP_MANAGEMENT";
export const PARTNERSHIP_LIST = "PARTNERSHIP_LIST";
export const PARTNERSHIP_ORDERS = "PARTNERSHIP_ORDERS";
export const PARTNERSHIP_ORDERS_LIST = "PARTNERSHIP_ORDERS_LIST";
