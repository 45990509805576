import * as names from "../names";

const component = {
	[names.CX_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'frontoffice' */ "@/views/FrontOffice/index"),
	[names.CX_RESET_PASSWORD]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'frontoffice' */ "@/views/FrontOffice/ResetPassword"),
	[names.CX_USER_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'frontoffice' */ "@/views/FrontOffice/UserIndex"),
	[names.CX_USER_LIST]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'frontoffice' */ "@/views/FrontOffice/UserList"),
	[names.CX_USER_CREATE]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'frontoffice' */ "@/views/FrontOffice/UserCreateOrEdit"),
	[names.CX_USER_EDIT]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'frontoffice' */ "@/views/FrontOffice/UserCreateOrEdit"),
};

export default {
	path: "front-office",
	component: () => component[names.CX_INDEX],
	redirect: { name: names.CX_USER_LIST },
	meta: {
		isGroup: true,
		label: "Front Office",
		path: "/front-office",
		icon: "support_agent",
		hidden: true,
	},
	children: [
		{
			path: "manage-users",
			component: () => component[names.CX_USER_INDEX],
			meta: {
				label: "Gerenciar usuários",
				path: "/front-office/manage-users",
				icon: "price_check",
				ignoreCrumb: true,
			},
			children: [
				{
					path: "create",
					name: names.CX_USER_CREATE,
					meta: {
						label: "Novo usuário",
					},
					component: () => component[names.CX_USER_CREATE],
				},
				{
					path: ":userId/edit",
					name: names.CX_USER_EDIT,
					meta: { label: "Editar usuário" },
					props: (to) => ({ userId: +to.params.userId }),
					beforeEnter(to, from, next) {
						const notFoundRoute = { name: names.NOT_FOUND };

						try {
							const id = +to.params.userId;
							Number.isInteger(id) && id > 0 ? next() : next(notFoundRoute);
						} catch (error) {
							next(notFoundRoute);
						}
					},
					component: () => component[names.CX_USER_EDIT],
				},
				{
					path: "",
					name: names.CX_USER_LIST,
					meta: {
						hideLabel: true,
					},
					component: () => component[names.CX_USER_LIST],
				},
			],
		},
		{
			path: "reset-password",
			name: names.CX_RESET_PASSWORD,
			component: () => component[names.CX_RESET_PASSWORD],
			meta: {
				label: "Resetar senha",
				path: "/front-office/reset-password",
				icon: "vpn_key",
			},
		},
	],
};
