export const isLegalAge = (rule, value, callback) => {
	let birth = new Date(value);
	let today = new Date();

	let nowyear = today.getFullYear();
	let nowmonth = today.getMonth();
	let nowday = today.getDate();

	let birthyear = birth.getFullYear();
	let birthmonth = birth.getMonth();
	let birthday = birth.getDate();

	let age = nowyear - birthyear;
	let age_month = nowmonth - birthmonth;
	let age_day = nowday - birthday;

	if ((age == 18 && age_month <= 0 && age_day <= 0) || age < 18) {
		return callback(new Error("Precisamos que seja maior de idade."));
	}
	return callback();
};

export const isCNPJ = (rule, value, callback) => {
	const digits = value.replace(/[\D]/gi, "");

	let dig1 = 0;
	let dig2 = 0;

	const validation = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

	const digito = parseInt(digits.charAt(12) + digits.charAt(13));

	const getRest = (dig) => (dig % 11 < 2 ? 0 : 11 - (dig % 11));

	validation.forEach((v, i) => {
		dig1 += i > 0 ? digits.charAt(i - 1) * v : 0;
		dig2 += digits.charAt(i) * v;
	});

	dig1 = getRest(dig1);
	dig2 = getRest(dig2);

	const isValid = dig1 * 10 + dig2 === digito;

	if (isValid) {
		return callback();
	}
	return callback(
		new Error("CNPJ inválido, por favor verifique se está correto.")
	);
};

export const isPhone = (rule, value, cb) => {
	const digits = value.replace(/[\D]/gi, "");
	const phoneRegExp = new RegExp(/\d{10,11}/, "g");

	if (phoneRegExp.test(digits)) {
		return cb();
	}

	return cb(new Error("Telefone inválido"));
};

export const formatPhone = (str) => {
	if (["", null, undefined, 0].includes(str)) return "";

	return str.replace(
		str.length === 10 ? /^(\d{2})(\d{4})(\d{4})/ : /^(\d{2})(\d{5})(\d{4})/,
		"($1) $2-$3"
	);
};

export const isCPF = (rule, cpf, callback) => {
	if (!cpf || cpf.length === "") callback();

	const ERROR_MSG = "CPF Inválido";

	const isInvalid = (val, rest, pos) =>
		rest !== parseInt(val.substring(pos, pos + 1));

	const sumDigit = (val, digit) =>
		11 -
		(val
			.substring(0, digit)
			.split("")
			.reduce((acc, curr, index) => {
				acc += parseInt(curr) * (digit + 1 - index);
				return acc;
			}, 0) %
			11);

	const getRest = (sum) => (sum > 9 ? 0 : sum);

	cpf = cpf.replace(/[\D]/gi, "");

	if (!cpf.match(/^\d+$/)) return callback(new Error(ERROR_MSG));

	if (cpf === "00000000000" || cpf.length !== 11)
		return callback(new Error(ERROR_MSG));

	if (isInvalid(cpf, getRest(sumDigit(cpf, 9)), 9))
		return callback(new Error(ERROR_MSG));

	if (isInvalid(cpf, getRest(sumDigit(cpf, 10)), 10))
		return callback(new Error(ERROR_MSG));

	return callback();
};

export const isCNPJorCPF = (r, v, cb) => {
	if (v === "") cb(new Error("Preencha"));
	const cleaned = v.replace(/[\D]/gi, "");
	if (cleaned.length <= 11) {
		return isCPF(r, v, cb);
	} else {
		return isCNPJ(r, v, cb);
	}
};

export const isFullName = (name) =>
	name?.split(" ").filter(Boolean)?.length > 1;
