import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { NotFoundError, UnexpectedError } from "@/core/errors";

export const remoteSimulateProposalQitech = (httpClient, baseUrl) => ({
	run: async (payload) => {
		const response = await httpClient.request({
			method: HttpMethod.POST,
			url: `${baseUrl}/qitech`,
			body: payload,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				return {
					disbursementValue: response.body.valor,
					disbursementDate: response.body.dataDesembolso,
					installments: response.body.numerosParcelas,
					installmentsValues: response.body.valorParcelas,
					firstDueDate: response.body.dataPrimeiroVencimento,
					lastDueDate: response.body.dataUltimoVencimento,
					grace: response.body.carencia,
					tac: response.body.tac,
					prepaymentFee: response.body.taxaLiquidacao || 0,
					iof: response.body.iof,
					grossAmount:
						response.body.valorParcelas * response.body.numerosParcelas,
					cet: {
						monthly: response.body.cetMensal,
						annual: response.body.cetAnual,
					},
					interest: {
						monthly: response.body.taxaJurosMes,
						annual: response.body.taxaJurosAno,
					},
				};
			}

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError(`Simulação não encontrada!`);

			default:
				throw new UnexpectedError(
					`Ocorreu um erro inesperado ao simular oferta!`
				);
		}
	},
});
