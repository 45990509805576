import axios from "axios";
import shortly from "vuex-shortly";
import * as types from "@/store/types";
import { setInterceptors } from "@/plugins/axios";

export const clientManagerApi = axios.create({
	baseURL: process.env.VUE_APP_A55_CLIENT_MANAGER_REST,
});

setInterceptors(clientManagerApi);

const state = {
	riskLevels: [],
	companies: [],
	allCompanies: {
		updatedAt: 0,
		data: [],
	},
	industries: [],
	pagination: {
		page: 1,
		pageSize: 10,
		count: 0,
		pages: 1,
	},
};

const getters = {
	[types.CLIENT_MANAGER_COMPANIES]: "companies",
	[types.CLIENT_MANAGER_INDUSTRIES]: "industries",
	[types.CLIENT_MANAGER_INDUSTRIES_ACTIVE]: (state) =>
		state.industries.filter(({ active }) => active),
	[types.CLIENT_MANAGER_PAGINATION]: "pagination",
	[types.CLIENT_MANAGER_ALL_COMPANIES]: "allCompanies",
};

const mutations = {
	[types.CLIENT_MANAGER_COMPANIES]: "companies",
	[types.CLIENT_MANAGER_INDUSTRIES]: "industries",
	[types.CLIENT_MANAGER_PAGINATION]: "pagination",
	[types.CLIENT_MANAGER_ALL_COMPANIES]: "allCompanies",
};

const actions = {
	[types.CLIENT_MANAGER_COMPANIES]: async ({ commit }, params) => {
		const response = await clientManagerApi.get(`/api/clients/`, { params });
		const { data, ...pagination } = response?.data;
		commit(types.CLIENT_MANAGER_COMPANIES, data);
		commit(types.CLIENT_MANAGER_PAGINATION, pagination);
		return data;
	},
	[types.CLIENT_MANAGER_INDUSTRIES]: async ({ commit }) => {
		const { data: industries } = await clientManagerApi.get("/industrys/");
		commit(types.CLIENT_MANAGER_INDUSTRIES, industries);
		return industries;
	},
	[types.CLIENT_MANAGER_USES_CREDIT]: async () => {
		const { data: usesCredit } = await clientManagerApi.get("/use-of-credit/");
		return usesCredit;
	},
	[types.CLIENT_MANAGER_ALL_COMPANIES]: async ({ commit }) => {
		const response = await clientManagerApi.get(`/api/v2/company`);
		const timestamp = Date.now();
		const { results } = response?.data;

		commit(types.CLIENT_MANAGER_ALL_COMPANIES, {
			updatedAt: timestamp,
			data: results,
		});
		return results;
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
