import { remoteGetAllActivities } from "@/core/usecases";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const query = gql`
	query {
		allActivity {
			edges {
				node {
					id
					code
					description
				}
			}
		}
	}
`;

export const makeRemoteGetAllActivities = () =>
	remoteGetAllActivities(clients["client-manager"], query);
