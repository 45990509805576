import axios from "axios";
import shortly from "vuex-shortly";
import * as types from "@/store/types";
import { setInterceptors } from "@/plugins/axios";

const docManagerApi = axios.create({
	baseURL: process.env.VUE_APP_A55_DOCUMENT_REST,
});

setInterceptors(docManagerApi);

const state = {
	processes: [],
	allClientProcesses: [],
	allClientProcessDocuments: [],
	currentClientProcess: {},
};

const getters = {
	[types.DOCUMENTS_PROCESSES]: "processes",
	[types.DOCUMENTS_ALL_CLIENT_PROCESSES]: "allClientProcesses",
	[types.DOCUMENTS_ALL_CLIENT_PROCESS_DOCUMENTS]: "allClientProcessDocuments",
	[types.DOCUMENTS_GET_CLIENT_PROCESS_BY_ID]: "currentClientProcess",
	[types.DOCUMENTS_CLIENT_PROCESSES]: "clientProcesses",
};

const mutations = {
	[types.DOCUMENTS_PROCESSES]: "processes",
	[types.DOCUMENTS_ALL_CLIENT_PROCESSES]: "allClientProcesses",
	[types.DOCUMENTS_ALL_CLIENT_PROCESS_DOCUMENTS]: "allClientProcessDocuments",
	[types.DOCUMENTS_GET_CLIENT_PROCESS_BY_ID]: "currentClientProcess",
	[types.DOCUMENTS_CLIENT_PROCESSES]: "clientProcesses",
};

const actions = {
	[types.DOCUMENTS_CLIENT_PROCESSES]: async ({ commit }, clientProcessId) => {
		if (!clientProcessId) return;
		const response = await docManagerApi.get(
			`/clientProcess/${clientProcessId}`
		);
		commit(types.DOCUMENTS_CLIENT_PROCESSES, response.data);
		return response;
	},
	[types.DOCUMENTS_PROCESSES]: async ({ commit }) => {
		const response = await docManagerApi.get(`/process`);
		if (response.data) {
			const processes = response.data.filter(
				(it) => it.latest_schema._id !== null
			);
			commit(types.DOCUMENTS_PROCESSES, processes);
		}
		return response;
	},
	[types.DOCUMENTS_ALL_CLIENT_PROCESSES]: async ({ commit }, client_tax_id) => {
		const response = await docManagerApi.get("/clientProcess", {
			params: {
				client_tax_id,
			},
		});
		commit(types.DOCUMENTS_ALL_CLIENT_PROCESSES, response?.data || []);
		return response;
	},
	[types.DOCUMENTS_GET_CLIENT_PROCESS_BY_ID]: async (
		{ commit },
		{ clientProcessId }
	) => {
		const response = await docManagerApi.get(
			`/clientProcess/${clientProcessId}`
		);
		commit(types.DOCUMENTS_GET_CLIENT_PROCESS_BY_ID, response?.data || {});
		return response;
	},
	[types.DOCUMENTS_ALL_CLIENT_PROCESS_DOCUMENTS]: async (
		{ commit },
		{ clientProcessId, clientTaxId, page, pageSize }
	) => {
		const params = {
			clientTaxId,
			clientProcessId,
		};
		if (page) params.page = page;
		if (pageSize) params.pageSize = pageSize;
		const response = await docManagerApi.get("/documents", {
			params,
		});
		commit(types.DOCUMENTS_ALL_CLIENT_PROCESS_DOCUMENTS, response?.data || []);
		return response;
	},
	[types.DOCUMENTS_DOWNLOAD_LINK]: (ctx, documentId) => {
		return docManagerApi.get(`/documents/${documentId}/downloadLink`);
	},
	[types.DOCUMENTS_DOWNLOAD_FILE_LINK]: (ctx, { documentId, fileId }) => {
		return docManagerApi.get(`/documents/${documentId}/downloadLink/${fileId}`);
	},
	[types.DOCUMENTS_DELETE_FILE]: (ctx, { documentId, fileId }) => {
		return docManagerApi.delete(`/documents/${documentId}/file/${fileId}`);
	},
	[types.DOCUMENTS_PROCESSES_CREATE]: (ctx, payload) => {
		return docManagerApi.post(`/process`, payload);
	},
	[types.DOCUMENTS_PROCESSES_UPDATE]: (ctx, { processId, payload }) => {
		return docManagerApi.put(`/process/${processId}`, payload);
	},
	[types.DOCUMENTS_PROCESSES_CREATE_TEMPLATE]: (
		ctx,
		{ processId, payload }
	) => {
		return docManagerApi.post(`/process/${processId}/template`, payload);
	},
	[types.DOCUMENTS_PROCESSES_DELETE_CLIENT_PROCESS]: (ctx, clientProcessId) => {
		return docManagerApi.delete(`/clientProcess/${clientProcessId}`);
	},
	[types.DOCUMENTS_PROCESSES_CREATE_CLIENT_PROCESS]: (ctx, payload) => {
		return docManagerApi.post(`/clientProcess`, payload);
	},
	[types.DOCUMENTS_REVIEW]: (ctx, { documentId, payload }) => {
		let endpoint = `/documents/${documentId}/review`;
		if (payload.fileId) {
			endpoint += `/${payload.fileId}`;
			delete payload.fileId;
		}
		return docManagerApi.post(endpoint, payload);
	},
	[types.DOCUMENTS_ADD_PERSON_TO_GROUP]: (
		ctx,
		{ clientProcessId, group, payload }
	) => {
		return docManagerApi.post(
			`/clientProcess/${clientProcessId}/group/${group}/members`,
			payload
		);
	},
	[types.DOCUMENTS_ANALYZE_CLIENT_PROCESS]: (
		ctx,
		{ clientProcessId, payload }
	) => {
		return docManagerApi.post(
			`/clientProcess/${clientProcessId}/analyze`,
			payload
		);
	},
	[types.DOCUMENTS_CREATE_PENDENCY]: (ctx, { payload }) => {
		return docManagerApi.post(`/pendency`, payload);
	},
	[types.DOCUMENTS_UPDATE_PENDENCY]: (ctx, { pendencyId, payload }) => {
		return docManagerApi.patch(`/pendency/${pendencyId}`, payload);
	},
	[types.DOCUMENTS_DELETE_PENDENCY]: (ctx, { pendencyId }) => {
		return docManagerApi.delete(`/pendency/${pendencyId}`);
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
