import store from "@/store";

// Closure: used in mutations[key]
// return update { key, value } method
export const getModelKeyMutator =
	(stateKey) =>
	(state, { key, value }) => {
		state[stateKey][key] = value;
	};

// Closure: Receives a vuex type and return a function
// to update { key, value } of the received type
export const getUpdateKeyHandler = (type) => {
	return (key, value) => {
		store.dispatch(type, { key, value });
	};
};

export const filterKeysByModel = (object, objectModel = {}) => {
	const allowedKeys = Object.keys(objectModel);
	const entries = Object.entries(object);

	const filtered = entries.filter(([key]) => allowedKeys.includes(key));
	return Object.fromEntries(filtered);
};
