import { remoteGetPersonByDocument } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const personQuery = gql`
	query personDetails($taxId: String!) {
		personDetails(taxId: $taxId, first: 1) {
			edges {
				node {
					id
					taxId
					name
					birthDate
					idNumber
					idEmitter
					idEmissionDate
					fatherName
					motherName
					gender
					netWorth
					companyName
					position
					monthlyIncome
					nationality
					naturality
					pep
					pepPeriod
					pepPosition
					pepOrganization
					pepRelationships {
						edges {
							node {
								relationshipType
								person2 {
									name
									taxId
									pepPeriod
									pepPosition
									pepOrganization
								}
							}
						}
					}
					marriageRegime {
						id
						description
					}
					maritalStatus {
						id
						statusDescription
					}
					personalContactInfo {
						phone
						email
						zipCode
						address
						number
						complement
						neighborhood
						city
						state
					}
					spouse {
						id
						taxId
						name
						birthDate
						idNumber
						idEmitter
						idEmissionDate
						personalContactInfo {
							phone
							email
							zipCode
							address
							number
							complement
							neighborhood
							city
							state
						}
					}
				}
			}
		}
	}
`;

const companyQuery = gql`
	query allClient($cnpj: String!) {
		allClient(cnpj: $cnpj) {
			edges {
				node {
					id
					name
					registeredName
					cnpj
					foundingDate
					fund
					email
					phone
					website
					averageAnnualRevenue
					industry {
						id
						industry
					}
					zipCode
					complement
					address
					number
					neighborhood
					city
					state
				}
			}
		}
	}
`;

export const makeRemoteGetPersonByDocument = () =>
	remoteGetPersonByDocument(
		clients["client-manager"],
		personQuery,
		companyQuery
	);
