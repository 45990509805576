const mapEdges = (edges) => {
	return edges.map((edge) => edge.node);
};

const mapCompany = (company) => {
	const propNameMap = {
		clientboardmemberSet: "clientBoardMembers",
		clientrepresentativeSet: "clientRepresentatives",
		parentCompanies: "parentCompanies",
		procuratorSet: "procurators",
		secondaryActivity: "secondaryActivities",
	};

	return Object.keys(company).reduce((mappedCompany, prop) => {
		const propName = propNameMap[prop] || prop;
		const propValue = !company[prop]?.edges
			? company[propName]
			: mapEdges(company[prop].edges);

		return {
			...mappedCompany,
			[propName]: propValue,
		};
	}, {});
};

export const remoteLoadCompanyDetails = (GraphQLClient, query) => ({
	run: async (companyTaxId) => {
		const response = await GraphQLClient.query({
			query,
			variables: { cnpj: companyTaxId },
		});

		const company = response?.data?.allClient?.edges?.[0]?.node;
		return mapCompany(company);
	},
});
