import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import {
	InvalidModelError,
	NotFoundError,
	UnexpectedError,
} from "@/core/errors";

export const remoteLoadProcessingDeal = (httpClient, baseUrl) => ({
	run: async (taxId) => {
		const response = await httpClient.request({
			url: `${baseUrl}/api/v2/client/registration`,
			method: HttpMethod.GET,
			query: {
				deal_status: "PROCESSING",
				tax_id: taxId,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return response?.body;

			case HttpStatusCode.BAD_REQUEST:
				throw new InvalidModelError("Dados inválidos");

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError("Não encontrado");

			default:
				throw new UnexpectedError("Erro inesperado");
		}
	},
});
