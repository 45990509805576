import Vue from "vue";
import money from "v-money";

Vue.prototype.$money = {
	decimal: ",",
	thousands: ".",
	prefix: "R$ ",
	suffix: "",
	precision: 2,
	masked: false,
};

Vue.use(money, Vue.prototype.$money);
