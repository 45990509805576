import { HttpStatusCode } from "@/core/adapters";

export const authorizeHttpClientDecorator = (
	appMode,
	eventService,
	httpClient,
	storage
) => ({
	async request(request) {
		const key = `A55_${appMode}_midgard`;
		const account = await storage.get(key);
		let modifiedRequest = request;

		if (account) {
			modifiedRequest = {
				...modifiedRequest,
				headers: {
					...modifiedRequest.headers,
					Authorization: `Token ${account.auth.user.token}`,
				},
			};
		}

		const response = await httpClient.request(modifiedRequest);

		const logoutStatusCodes = [
			HttpStatusCode.UNAUTHORIZED,
			HttpStatusCode.FORBIDDEN,
		];
		if (logoutStatusCodes.includes(response.statusCode)) {
			eventService.emit("unauthorized");
		}

		return response;
	},
});
