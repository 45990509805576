import { clearDocument } from "@/core/utils";

export const remoteUpdateClientBusinessData = (
	graphQLClient,
	mutation,
	parseClientBusinessData
) => ({
	run: async (model) => {
		const address = [model.street, model.number, model.complement]
			.filter((it) => typeof it === "string")
			.map((it) => it.trim())
			.join(",");

		const parsed = {
			...model,
			address,
			secondaryActivityList: model.secondaryActivityList?.map(({ id }) => ({
				secondaryActivityIdGraphql: id,
			})),
			mainActivityIdGraphql: model.mainActivityIdGraphql?.id,
			cnpj: clearDocument(model.cnpj),
			zipCode: clearDocument(model.zipCode),
		};

		const deleteThese = [
			"street",
			"number",
			"complement",
			"mainActivity",
			"secondaryActivity",
		];

		const variables = Object.fromEntries(
			Object.entries(parsed).filter(([key]) => !deleteThese.includes(key))
		);

		return graphQLClient
			.mutate({
				fetchPolicy: "no-cache",
				mutation,
				variables,
			})
			.then((response) => response.data?.updateClient?.client)
			.then(parseClientBusinessData.run);
	},
});
