export const remoteLoadTransactionDetails = (graphQLClient, query) => ({
	run: async (transactionId) => {
		return graphQLClient
			.query({
				query,
				fetchPolicy: "no-cache",
				variables: { id: transactionId },
			})
			.then((response) => response.data)
			.then((data) => data?.allTransaction?.edges?.[0]?.node);
	},
});
