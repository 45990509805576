export const formatCurrency = (
	value,
	options = { style: "currency", currency: "BRL" }
) => {
	const formatter = new Intl.NumberFormat("pt-BR", {
		...options,
	});

	return formatter.format(value);
};
