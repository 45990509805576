import Vue from "vue";
import Router from "vue-router";
import routeMap from "./routes/index";
import * as names from "./names";

const Private = () => import(/* webpackPrefetch: true */ /* webpackChunkName: 'private' */ "@/views/Private");
const NotFound = () => import(/* webpackPrefetch: true */ /* webpackChunkName: 'public' */ "@/views/NotFound");
const Unauthorized = () => import(/* webpackPrefetch: true */ /* webpackChunkName: 'public' */ "@/views/Unauthorized");
const SignIn = () => import(/* webpackPrefetch: true */ /* webpackChunkName: 'public' */ "@/views/SignIn");
const SignInBaas = () => import(/* webpackPrefetch: true */ /* webpackChunkName: 'public' */ "@/views/SignInBaas");

Vue.use(Router);

const mapMenu = (menuItem) =>
	menuItem.meta.isGroup ? { ...menuItem.meta, name: menuItem.name, children: menuItem.children.map(mapMenu) } : { ...menuItem.meta };

export const menuEntries = routeMap.map(mapMenu);

const router = new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			component: Private,
			redirect: { name: names.SIGN_IN },
			children: routeMap,
		},
		{
			path: "/staff",
			name: names.SIGN_IN,
			hidden: true,
			component: SignIn,
			meta: {
				public: true,
			},
		},
		{
			path: "/entrar",
			name: names.SIGN_IN_BAAS,
			hidden: true,
			component: SignInBaas,
			meta: {
				public: true,
			},
		},
		{
			path: "/unauthorized",
			name: names.UNAUTHORIZED,
			hidden: true,
			component: Unauthorized,
		},
		{
			path: "*",
			name: names.NOT_FOUND,
			hidden: true,
			component: NotFound,
		},
	],
});

export const routeNames = names;
export default router;
