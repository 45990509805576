import { InvalidModelError, UnexpectedError } from "@/core/errors";
import { HttpMethod, HttpStatusCode } from "@/core/adapters";

export const remoteCreateAgreement = (httpClient, baseUrl) => ({
	run: async (payload) => {
		const { instrument_id, amount, date } = payload;

		const response = await httpClient.request({
			method: HttpMethod.POST,
			url: `${baseUrl}/agreement`,
			body: {
				instrument_id,
				amount,
				date,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.CREATED:
				return response.body;

			case HttpStatusCode.OK:
				return response.body;

			case HttpStatusCode.BAD_REQUEST: {
				throw new InvalidModelError(
					"Dados inválidos na para criação de execução judicial"
				);
			}

			default:
				throw new UnexpectedError(
					"Erro inesperado ao criar execução judicial!"
				);
		}
	},
});
