import gql from "graphql-tag";
import { UnexpectedError } from "@/core/errors";

const query = gql`
	query ($expectedCashflowEntryId: ID!) {
		expectedCashflowEntry(id: $expectedCashflowEntryId) {
			installmentId
		}
	}
`;

export const remoteLoadInstallmentId = (graphQLClient) => ({
	run: async (expectedCashflowEntryId) => {
		try {
			const expectedCashflowEntryIdGraphQL = window.btoa(
				`ExpectedCashFlowEntryNode:${expectedCashflowEntryId}`
			);

			const response = await graphQLClient.query({
				query,
				variables: { expectedCashflowEntryId: expectedCashflowEntryIdGraphQL },
			});

			return response.data?.expectedCashflowEntry?.installmentId;
		} catch (error) {
			throw new UnexpectedError("Erro ao buscar informações");
		}
	},
});
