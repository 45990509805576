import store from "@/store";
import router from "@/router";
import * as types from "@/store/types";

export const guard = async (to, from, next) => {
	if (to.meta.permission) {
		if (
			store.getters[types.AUTH_USER].groups.indexOf(to.meta.permission) >= 0
		) {
			next();
		} else {
			next("/");
		}
		return;
	}
	if (to.meta.hideGroups?.length) {
		const hideGroups = to.meta.hideGroups;
		const userGroups = store.getters[types.AUTH_USER].groups;

		if (userGroups.every((group) => hideGroups.indexOf(group) === -1)) {
			next();
		} else {
			next("/");
		}
		return;
	}
	if (!from.name) {
		try {
			await store.dispatch(types.AUTH_VERIFY);
			next();
		} catch (error) {
			store.dispatch(types.AUTH_LOGOUT);
			window.location = `${process.env.VUE_APP_A55_AUTH}?redirect=${window.location}`;
		}
	} else next();
};

router.afterEach(() => {
	if (document.querySelector(".a-loader")) return;
	window.NProgress.done();
});

router.beforeEach(async (to, from, next) => {
	if (to.meta.public) next();
	else {
		window.NProgress.start();
		await guard(to, from, next);
		window.NProgress.done();
	}
});
