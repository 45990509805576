import { createEventAdapter } from "@/core/adapters";

let eventAdapter;

export const makeEventAdapter = () => {
	if (!eventAdapter) {
		eventAdapter = createEventAdapter();
	}

	return eventAdapter;
};
