import { remoteGetAllRiskLevel } from "@/core/usecases";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const query = gql`
	query {
		allRiscoSocioAmbiental {
			edges {
				node {
					id
					description
				}
			}
		}
	}
`;

export const makeRemoteGetAllRiskLevel = () =>
	remoteGetAllRiskLevel(clients["client-manager"], query);
