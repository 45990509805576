import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { NotFoundError, UnexpectedError } from "@/core/errors";
import { mapProposal } from "./remoteLoadProposals";

const timezone = "T00:00-03:00";

const NA_TEXT = "Indisponível";

const formatUndef = (val, cb, formatted) =>
	val !== undefined ? cb(formatted || val) : NA_TEXT;

export const remoteSimulateProposalAccepted = (
	httpClient,
	baseUrl,
	convertFromCents,
	convertToCents,
	formatDate,
	formatMoney,
	formatPercentage
) => ({
	run: async (proposalId) => {
		const response = await httpClient.request({
			method: HttpMethod.GET,
			url: `${baseUrl}/proposals/${proposalId}/`,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				const remoteProposal = mapProposal(response.body);
				const {
					id,
					rates,
					rate_accepted,
					qitech_acceptance_data,
					...proposalRest
				} = remoteProposal;

				const [acceptedRate] = rates.filter(
					(rate) => rate.id === rate_accepted
				);

				const NA_TEXT = "Indisponível";
				const formatters = {
					money: (val) => {
						return formatUndef(val, formatMoney);
					},
					percentage: (val) => {
						return formatUndef(val, formatPercentage);
					},
					date: (val) => {
						return formatUndef(val, formatDate, new Date(val + timezone));
					},
					grossMoney: (val, installments) => {
						return formatUndef(
							val,
							formatMoney,
							convertFromCents(convertToCents(val) * installments)
						);
					},
					empty: (val) => {
						return val !== undefined ? val : NA_TEXT;
					},
				};

				return {
					...proposalRest,
					rates: [
						{
							amount: formatters.money(acceptedRate?.amount),
							cet: {
								annual: formatters.percentage(
									qitech_acceptance_data?.annual_cet
								),
								monthly: formatters.percentage(
									qitech_acceptance_data?.monthly_cet
								),
							},
							disbursementDate: formatters.date(
								qitech_acceptance_data?.disbursement_date
							),
							firstDueDate: formatters.date(
								qitech_acceptance_data?.first_payment_due_date
							),
							lastDueDate: formatters.date(
								qitech_acceptance_data?.last_payment_due_date
							),
							grace: formatters.empty(acceptedRate?.grace),
							installments: formatters.empty(acceptedRate?.installments),
							grossAmount: formatters.grossMoney(
								qitech_acceptance_data?.installments_value,
								acceptedRate?.installments
							),
							installmentAmount: formatters.money(
								qitech_acceptance_data?.installments_value
							),
							insterest: {
								annual: formatters.percentage(
									qitech_acceptance_data?.annual_interest_rate
								),
								monthly: formatters.percentage(acceptedRate?.rate),
								settling: formatters.percentage(acceptedRate?.prepaymentFee),
							},
							iof: formatters.money(qitech_acceptance_data?.iof),
							rateId: formatters.empty(acceptedRate?.id),
							tac: formatters.percentage(acceptedRate?.tac),
							proposalId: formatters.empty(id),
						},
					],
				};
			}

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError(
					`Proposta com id '${proposalId}' não encontrada!`
				);

			default:
				throw new UnexpectedError(
					`Ocorreu um erro inesperado ao buscar proposta #${proposalId}!`
				);
		}
	},
});
