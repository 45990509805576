import { clearDocument } from "@/core/utils";

export const remoteGetCompanyData = (graphQLClient, query) => ({
	run: async (cnpj) => {
		const variables = { cnpj: clearDocument(cnpj) };

		return graphQLClient
			.query({
				query,
				fetchPolicy: "no-cache",
				errorPolicy: "ignore",
				notifyOnNetworkStatusChange: true,
				variables,
			})
			.then(
				(res) =>
					res.data.allClient?.edges?.[0]?.node || {
						taxId: clearDocument(cnpj),
					}
			);
	},
});
