import { clearDocument, filterKeysByModel } from "@/core/utils";

export const localStoreCompanyData = (
	storeClient,
	ufs,
	profileType,
	profileDefaultModel,
	contactDataType,
	contactDefaultModel
) => ({
	run: (company, relatedTaxId, boardmembers = []) => {
		const { industry, state } = company;
		const foundMember =
			boardmembers.find(
				({ taxId }) => taxId === clearDocument(company?.cnpj || company?.taxId)
			) || {};

		const companyProfile = filterKeysByModel(
			{
				...profileDefaultModel,
				...foundMember,
				...company,
				percentageOwned: foundMember?.percentage,
				cnpj: clearDocument(company?.cnpj || company?.taxId),
				relatedTaxId,
			},
			profileDefaultModel
		);

		const companyContactData = filterKeysByModel(
			{
				...contactDefaultModel,
				...company,
				industry: {
					id: industry?.id,
					name: industry?.industry,
				},
				state: state && ufs.find((it) => it.id === state),
			},
			contactDefaultModel
		);

		storeClient.dispatch(profileType, companyProfile);
		storeClient.dispatch(contactDataType, companyContactData);
	},
});
