import { remoteDeleteGuarantor } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const updateGuarantor = gql`
	mutation updateTransactionTerms(
		$transactionIdGraphql: ID
		$guarantors: [PersonInput]
	) {
		updateTransactionTerms(
			transactionIdGraphql: $transactionIdGraphql
			guarantors: $guarantors
		) {
			transaction {
				id
			}
		}
	}
`;

export const makeRemoteDeleteGuarantor = () =>
	remoteDeleteGuarantor(clients.underwriting, updateGuarantor);
