import { remoteUpdateDisbursementAccount } from "@/core/usecases";
import { makeLocalGetBankList } from "@/core/factories";

import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const mutation = gql`
	mutation updateTransactionTerms(
		$transactionIdGraphql: ID
		$bankName: String
		$bankAgency: String
		$bankAccount: String
		$bankAccountDigit: String
	) {
		updateTransactionTerms(
			transactionIdGraphql: $transactionIdGraphql
			bankName: $bankName
			bankAgency: $bankAgency
			bankAccount: $bankAccount
			bankAccountDigit: $bankAccountDigit
		) {
			transaction {
				id
				bankName
				bankAgency
				bankAgencyDigit
				bankAccount
				bankAccountDigit
			}
		}
	}
`;

export const makeRemoteUpdateDisbursementAccount = () =>
	remoteUpdateDisbursementAccount(
		makeLocalGetBankList(),
		clients["underwriting"],
		mutation
	);
