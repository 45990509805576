import * as names from "../names";

const component = {
	[names.SETTINGS_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'private' */ "@/views/Settings"),
	[names.SETTINGS_COMPANY_LIST]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'company' */ "@/views/Company/List"),
	[names.SETTINGS_COMPANY_VIEW]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'company' */ "@/views/Company/Details"),
	[names.SETTINGS_VEHICLES]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'private' */ "@/views/Vehicle/Vehicles"),
	[names.SETTINGS_ISSUERS]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'private' */ "@/views/Issuers/Issuers"),
	[names.SETTINGS_USER]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'private' */ "@/views/Users"),
	// [names.SETTINGS_DOCUMENT_LIST]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'private' */ "@/views/Formalization/Documentation/List"),
};

export default {
	path: "settings",
	name: names.SETTINGS_INDEX,
	meta: {
		isGroup: true,
		label: "Configurações",
		icon: "settings_applications",
		path: "/settings",
	},
	redirect: { name: names.SETTINGS_VEHICLES },
	component: () => component[names.SETTINGS_INDEX],
	children: [
		{
			path: "vehicles",
			name: names.SETTINGS_VEHICLES,
			meta: {
				label: "Veículos",
				path: "/settings/vehicles",
				icon: "drive_eta",
			},
			component: () => component[names.SETTINGS_VEHICLES],
		},
		{
			path: "issuers",
			name: names.SETTINGS_ISSUERS,
			meta: {
				label: "Emissores",
				path: "/settings/issuers",
				icon: "local_atm",
			},
			component: () => component[names.SETTINGS_ISSUERS],
		},
		{
			path: "users",
			name: names.SETTINGS_USER,
			meta: {
				label: "Usuários",
				path: "/settings/users",
				icon: "people",
			},
			component: () => component[names.SETTINGS_USER],
		},
	],
};
