const modules = {};

var files;
try {
	files = require.context(".", false, /\.js$/);
} catch (error) {
	if (error instanceof TypeError) files = false;
	else throw error;
}

if (files) {
	files.keys().forEach((fileName) => {
		if (fileName === "./index.js") return;
		const moduleName = fileName.replace(/(\.\/|\.js)/g, "");
		modules[moduleName] = files(fileName).default;
	});
}

export default modules;
