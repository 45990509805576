import { remoteGetClientGeneralData } from "@/core/usecases";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

import { makeLocalParseClientGeneralData } from "@/core/factories";

const query = gql`
	query allClient($active: Boolean, $cnpj: String) {
		allClient(active: $active, cnpj: $cnpj) {
			edges {
				node {
					employee
					socialCapital
					averageAnnualRevenue
					email
					openCapital
					phone
					taxType {
						id
						description
					}
					riscoSocioAmbiental {
						id
						description
					}
				}
			}
		}
	}
`;

export const makeRemoteGetClientGeneralData = () =>
	remoteGetClientGeneralData(
		clients["client-manager"],
		query,
		makeLocalParseClientGeneralData()
	);
