import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { PROPOSAL_STATUS } from "@/core/entities";
import {
	InvalidModelError,
	UnauthorizedError,
	UnexpectedError,
} from "@/core/errors";

export const remoteAcceptProposal = (httpClient, baseUrl) => ({
	run: async (proposal, rateId) => {
		if (!rateId) {
			throw new InvalidModelError("Esta oferta ainda não foi salva!");
		}

		if (proposal.status !== PROPOSAL_STATUS.APPROVED) {
			throw new UnauthorizedError(
				"Só é possível aceitar propostas com status de aprovada!"
			);
		}

		const { productId } = proposal;
		const response = await httpClient.request({
			method: HttpMethod.POST,
			url: `${baseUrl}/operations/register`,
			body: { productId, rateId },
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return true;

			case HttpStatusCode.BAD_REQUEST:
				throw new InvalidModelError(
					"Verifique os dados do aceite da proposta!"
				);

			default:
				throw new UnexpectedError("Erro inesperado ao aceitar proposta!");
		}
	},
});
