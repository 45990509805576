export const getLocalDate = (
	date = new Date(),
	options = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		timeZone: "UTC",
	}
) => {
	const methods = {
		object: () => date.toLocaleDateString("pt-br", options),
		default: () => new Date(date).toLocaleDateString("pt-br", options),
	};

	return methods?.[typeof date]?.() || methods?.default?.();
};
