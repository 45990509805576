import { hasActiveErrors } from "@/core/utils";
import {
	validateMaxLength,
	validateMinLength,
	validateRequired,
} from "@/core/validators";

export const createString = (value, minLength = 5, maxLength = 5000) => {
	const errors = { params: {} };

	errors.required = !validateRequired(value);
	errors.minLength = !validateMinLength(value, minLength);
	errors.maxLength = !validateMaxLength(value, maxLength);
	errors.params.minLength = minLength;
	errors.params.maxLength = maxLength;

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value };
};
