import {
	formatDocument,
	getLocalDate,
	formatCurrency,
	clearDocument,
} from "@/core/utils";

const _defaults = Object.entries({
	borrowerTaxId: "",
	borrowerName: "",
	first: 50,
	offset: 0,
});

const format = (key, value) => {
	const toFormat = {
		borrowerTaxId: (value) => clearDocument(value),
	};

	return toFormat?.[key]?.(value) || value;
};

const mapTransaction = (it) => ({
	id: it.id,
	name: it.borrower?.name,
	taxId: formatDocument(it.borrowerTaxId),
	status: it.currentVersion?.status?.description,
	maximumAmount: formatCurrency(it.currentVersion?.totalAmount || 0),
	createdAt: getLocalDate(it.currentVersion?.createdAt),
	dueIn: getLocalDate(it.currentVersion?.trancheSet?.edges?.[0]?.node?.dueIn),
});

export const remoteLoadAllTransaction = (graphQLClient, query) => ({
	run: async (params) => {
		const variables = Object.fromEntries(
			_defaults.map(([key, value]) => [
				key,
				format(key, params?.[key]) || value,
			])
		);

		const response = await graphQLClient.query({
			query,
			fetchPolicy: "no-cache",
			errorPolicy: "ignore",
			variables,
		});

		const { edges, pageInfo } = response?.data?.allTransaction;

		const transactions = edges.map((it) => it.node);

		return {
			data: transactions.map(mapTransaction),
			metadata: pageInfo,
		};
	},
});
