import { remoteGetDisbursementAccount } from "@/core/usecases";
import { makeLocalGetBankList } from "@/core/factories";

import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const query = gql`
	query allTransaction($borrowerTaxId: String) {
		allTransaction(borrowerTaxId: $borrowerTaxId, first: 1) {
			edges {
				node {
					id
					bankName
					bankAgency
					bankAgencyDigit
					bankAccount
					bankAccountDigit
				}
			}
		}
	}
`;

export const makeRemoteGetDisbursementAccount = () =>
	remoteGetDisbursementAccount(
		makeLocalGetBankList(),
		clients["underwriting"],
		query
	);
