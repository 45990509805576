import { remoteUpdateComplementaryData } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const updateComplementaryData = gql`
	mutation updatePerson(
		$personIdGraphql: ID!
		$companyName: String
		$position: String
		$monthlyIncome: Int
		$netWorth: Int
		# pep
		$pep: Boolean
		$pepPeriod: String
		$pepOrganization: String
		$pepPosition: String
	) {
		updatePerson(
			personIdGraphql: $personIdGraphql
			monthlyIncome: $monthlyIncome
			netWorth: $netWorth
			companyName: $companyName
			position: $position
			# pep
			pep: $pep
			pepPeriod: $pepPeriod
			pepOrganization: $pepOrganization
			pepPosition: $pepPosition
		) {
			person {
				id
				taxId
				name
			}
		}
	}
`;

export const makeRemoteUpdateComplementaryData = () =>
	remoteUpdateComplementaryData(
		clients["client-manager"],
		updateComplementaryData
	);
