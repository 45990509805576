import { UnauthorizedError, ValidationError } from "@/core/errors";
import {
	createCNPJ,
	createNumber,
	createProposalObservation,
	createProposalReasonForChange,
	createProposalRates,
	createProposalRefusal,
	createProposalStatus,
} from "@/core/value-objects";
import { verifyValueObjects } from "@/core/utils";

export const PROPOSAL_STATUS = {
	PENDING_APPROVAL: "pending_approval",
	APPROVED: "approved",
	REJECTED: "rejected",
	PENDING_ALLOCATION: "pending_allocation",
	PENDING: "pending",
	ACCEPTED: "accepted",
	REFUSED: "refused",
	CANCELED: "canceled",
	EXPIRED: "expired",
	INDICATIVE: "indicative",
	ERROR: "error",
};

export const PROPOSAL_REFUSAL_REASON = {
	CREDIT_AMOUNT: "valor_do_credito_oferecido",
	DEADLINE: "prazo",
	OTHER: "outro",
	RATE: "taxa",
};

export const REASON_OFFER = {
	NOT_INFORMED: "nao_informado",
	RENEGOTIATION_CUSTOMER: "renegociacao_com_cliente",
	ERROR_CORRECTION: "correcao_de_erro",
	CAMPAIGN_OR_ACTION: "campanha_ou_acao_de_aquisicao",
	OTHERS: "outras",
};

export const createEmptyRate = () => ({
	amount: 0,
	grace: 0,
	installments: 0,
	rate: 0,
	tac: 0,
	prepaymentFee: 0,
	keyId: Date.now() + Math.random(),
});

export const createEmptyRefusal = () => ({
	detail: null,
	newCreditAmount: 0,
	newDeadline: 0,
	reason: PROPOSAL_REFUSAL_REASON.CREDIT_AMOUNT, // outro, prazo, taxa, valor_do_credito_oferecido
});

export const createEmptyProposal = () => ({
	cnpj: "",
	observation: null,
	partner: undefined,
	productId: null,
	rates: [createEmptyRate()],
	status: "",
	vehicleId: null,
	reasonOffer: null,
	guarantee: null,
});

const proposalInputMessages = {
	accepted() {
		throw new UnauthorizedError(
			"Não é possível criar ou alterar uma proposta com status de aceita!"
		);
	},
	error() {
		throw new UnauthorizedError(
			"Não é possível criar ou alterar uma proposta com status de erro!"
		);
	},
	refused() {
		throw new UnauthorizedError(
			"Não é possível alterar uma proposta com status de recusada!"
		);
	},
};

const throwProposalErros = (proposalInput, oldProposal) => {
	const isIndicative = [proposalInput.status, oldProposal?.status].includes(
		PROPOSAL_STATUS.INDICATIVE
	);

	if (isIndicative) {
		throw new UnauthorizedError(
			"Não é possível criar ou alterar uma proposta indicativa por aqui!"
		);
	}

	if (!oldProposal && proposalInput.status === PROPOSAL_STATUS.REFUSED) {
		throw new UnauthorizedError(
			"Não é possível criar uma proposta com status de recusada!"
		);
	}

	if (proposalInputMessages[proposalInput.status]) {
		const throwProposalInputError = proposalInputMessages[proposalInput.status];
		throwProposalInputError();
	}

	if (oldProposal && proposalInput.cnpj !== oldProposal?.cnpj) {
		throw new UnauthorizedError(
			"Não é possível alterar a empresa associada à proposta!"
		);
	}
};

const handleProposalInput = (
	proposalInput,
	valueObjects,
	errorsMap,
	oldProposal,
	refusalInput
) => {
	if (proposalInput.observation) {
		const observation = createProposalObservation(proposalInput);

		valueObjects.push(observation);
		errorsMap.observation = observation.errors;
	}

	if (proposalInput.reason_for_change) {
		const reason_for_change = createProposalReasonForChange(
			proposalInput.reason_for_change
		);

		valueObjects.push(reason_for_change);
		errorsMap.reason_for_change = reason_for_change.errors;
	}

	if (proposalInput.partner) {
		const partner = createCNPJ(proposalInput.partner);

		valueObjects.push(partner);
		errorsMap.partner = partner.errors;
	}

	if (proposalInput.vehicleId || oldProposal?.vehicleId) {
		const vehicleId = createNumber(proposalInput.vehicleId);

		valueObjects.push(vehicleId);
		errorsMap.vehicleId = vehicleId.errors;
	}

	const refusalIsRequired =
		oldProposal &&
		oldProposal.status !== PROPOSAL_STATUS.REFUSED &&
		proposalInput.status === PROPOSAL_STATUS.REFUSED;

	if (refusalIsRequired) {
		const refusal = createProposalRefusal(refusalInput);

		valueObjects.push(refusal);
		errorsMap.refusal = refusal.errors;
	}
};

export const createProposalEntity = (
	proposalInput,
	clientRisk,
	refusalInput,
	oldProposal = null
) => {
	throwProposalErros(proposalInput, oldProposal);

	const disableProduct =
		!oldProposal && proposalInput.status === PROPOSAL_STATUS.REJECTED;

	const cnpj = createCNPJ(proposalInput.cnpj);
	const productId = createNumber(
		proposalInput.productId,
		0,
		Number.MAX_SAFE_INTEGER,
		"minValue",
		"maxValue",
		!disableProduct
	);
	const rates = createProposalRates(proposalInput, 1, !oldProposal);
	const status = createProposalStatus(proposalInput.status);
	// const risk = createClientRisk(clientRisk);

	const valueObjects = [cnpj, productId, rates, status];

	const errorsMap = {
		cnpj: cnpj.errors,
		productId: productId.errors,
		status: status.errors,
		rates: rates.errors,
	};

	handleProposalInput(
		proposalInput,
		valueObjects,
		errorsMap,
		oldProposal,
		refusalInput
	);

	const hasSomeError = verifyValueObjects(valueObjects);

	if (hasSomeError) {
		throw new ValidationError("Verifique os dados da proposta", errorsMap);
	}

	return proposalInput;
};
