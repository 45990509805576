import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { InvalidModelError, UnexpectedError } from "@/core/errors";

export const remoteCreateReceivablesOptin = (HttpClient, url) => ({
	run: async (cnpj) => {
		const response = await HttpClient.request({
			url: `${url}/optin/`,
			method: HttpMethod.POST,
			body: {
				cnpj,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return response.body;

			case HttpStatusCode.BAD_REQUEST:
				throw new InvalidModelError("Dados inválidos");

			case HttpStatusCode.DEFAULT:
				throw new UnexpectedError("Erro inesperado");
		}
	},
});
