import { hasActiveErrors } from "@/core/utils";
import { validateCNPJ, validateRequired } from "@/core/validators";

export const createCNPJ = (value) => {
	const errors = { params: {} };

	errors.required = !validateRequired(value);
	errors.cnpj = !value || !validateCNPJ(value);
	errors.params.cnpj = value;

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value };
};
