import { hasActiveErrors } from "@/core/utils";
import {
	validateRequired,
	validateMinLength,
	validateMaxLength,
} from "@/core/validators";
import { PROPOSAL_STATUS } from "@/core/entities";

export const createProposalObservation = (
	proposal,
	minLength = 10,
	maxLength = 2500
) => {
	const { observation } = proposal;
	const errors = { params: {} };
	const observationIsRequired =
		[PROPOSAL_STATUS.REJECTED].includes(proposal.status) ||
		!!proposal.observation;

	if (observationIsRequired) {
		errors.required = !validateRequired(observation);
		errors.minLength = !validateMinLength(observation, minLength);
		errors.maxLength = !validateMaxLength(observation, maxLength);
		errors.params.minLength = minLength;
		errors.params.maxLength = maxLength;
	}

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value: observation };
};
