export const remoteDeleteProcurator = (graphQLClient, procuratorDelete) => ({
	run: async (procuratorId) => {
		await graphQLClient.mutate({
			mutation: procuratorDelete,
			variables: {
				procuratorIdGraphql: procuratorId,
			},
		});
	},
});
