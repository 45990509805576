import { remoteUpdateClientBusinessData } from "@/core/usecases";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

import { makeLocalParseClientBusinessData } from "@/core/factories";

const mutation = gql`
	mutation updateClient(
		$name: String!
		$cnpj: String!
		$registeredName: String
		$societaryType: String
		$foundingDate: Date
		$zipCode: String
		$address: String
		$neighborhood: String
		$city: String
		$state: String
		$mainActivityIdGraphql: ID
		$secondaryActivityList: [SecondaryActivityInput]
	) {
		updateClient(
			name: $name
			cnpj: $cnpj
			registeredName: $registeredName
			societaryType: $societaryType
			foundingDate: $foundingDate
			zipCode: $zipCode
			address: $address
			neighborhood: $neighborhood
			city: $city
			state: $state
			mainActivityIdGraphql: $mainActivityIdGraphql
			secondaryActivityList: $secondaryActivityList
		) {
			client {
				name
				cnpj
				registeredName
				societaryType
				foundingDate
				zipCode
				address
				neighborhood
				city
				state
				mainActivity {
					id
					code
					description
				}
				secondaryActivity {
					edges {
						node {
							id
							code
							description
						}
					}
				}
			}
		}
	}
`;

export const makeRemoteUpdateClientBusinessData = () =>
	remoteUpdateClientBusinessData(
		clients["client-manager"],
		mutation,
		makeLocalParseClientBusinessData()
	);
