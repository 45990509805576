import { remoteLoadProposalRefusal } from "@/core/usecases";
import { makeAuthorizeHttpClientDecorator } from "./authorizeHttpClientDecoratorFactory";
import { makeFormatDateAdapter } from "./formatDateAdapterFactory";
import { makeFormatMoneyAdapter } from "./formatMoneyAdapterFactory";

export const makeRemoteLoadProposalRefusal = () =>
	remoteLoadProposalRefusal(
		makeAuthorizeHttpClientDecorator(),
		process.env.VUE_APP_A55_PROPOSALS_API,
		makeFormatDateAdapter(),
		makeFormatMoneyAdapter()
	);
