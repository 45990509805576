export const localParseClientGeneralData = (VueMaskFilter) => ({
	run: (data) => {
		if (!data) return;

		const riskData = {
			id: data.riscoSocioAmbiental?.id,
			name: data.riscoSocioAmbiental?.description,
		};

		const taxTypeData = {
			id: data.taxType?.id,
			name: data.taxType?.description,
		};

		return {
			...data,
			riscoSocioAmbiental: riskData.id ? riskData : null, // Um tem que ser undefined, o outro null
			taxType: taxTypeData.id ? taxTypeData : undefined, // Não sei explicar o porquê

			phone: VueMaskFilter(data.phone, "(##) #####-####"),
			socialCapital: data?.socialCapital || null,
			averageAnnualRevenue: data?.averageAnnualRevenue || null,
		};
	},
});
