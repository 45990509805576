import { remoteCreateOrUpdateCompany } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const createCompany = gql`
	mutation createClient(
		$registeredName: String!
		$foundingDate: Date!
		$name: String!
		$cnpj: String!
		$fund: Boolean!
		$email: String
		$phone: String
		$website: String
		$averageAnnualRevenue: BigInt
		$industryIdGraphql: ID
		$zipCode: String
		$complement: String
		$address: String
		$neighborhood: String
		$number: String
		$city: String
		$state: String
	) {
		createClient(
			cnpj: $cnpj
			foundingDate: $foundingDate
			fund: $fund
			email: $email
			phone: $phone
			website: $website
			averageAnnualRevenue: $averageAnnualRevenue
			industryIdGraphql: $industryIdGraphql
			zipCode: $zipCode
			complement: $complement
			address: $address
			number: $number
			neighborhood: $neighborhood
			city: $city
			state: $state
			name: $name
			registeredName: $registeredName
		) {
			client {
				id
				cnpj
			}
		}
	}
`;

const updateCompany = gql`
	mutation updateClient(
		$registeredName: String!
		$foundingDate: Date!
		$name: String!
		$cnpj: String!
		$fund: Boolean!
		$email: String
		$phone: String
		$website: String
		$averageAnnualRevenue: BigInt
		$industryIdGraphql: ID
		$zipCode: String
		$complement: String
		$address: String
		$neighborhood: String
		$number: String
		$city: String
		$state: String
	) {
		updateClient(
			cnpj: $cnpj
			foundingDate: $foundingDate
			fund: $fund
			email: $email
			phone: $phone
			website: $website
			averageAnnualRevenue: $averageAnnualRevenue
			industryIdGraphql: $industryIdGraphql
			zipCode: $zipCode
			complement: $complement
			address: $address
			number: $number
			neighborhood: $neighborhood
			city: $city
			state: $state
			name: $name
			registeredName: $registeredName
		) {
			client {
				id
				cnpj
			}
		}
	}
`;

export const makeRemoteCreateOrUpdateCompany = () =>
	remoteCreateOrUpdateCompany(
		clients["client-manager"],
		createCompany,
		updateCompany
	);
