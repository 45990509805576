import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { NotFoundError, UnexpectedError } from "@/core/errors";

export const remoteLoadProposalsHistory = (httpClient, baseUrl) => ({
	run: async (proposalId) => {
		const response = await httpClient.request({
			method: HttpMethod.GET,
			url: `${baseUrl}/proposals/history/${proposalId}`,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				return response.body.map((el) => ({
					datetime: el.datetime,
					name: el.user,
					statusNew: el.proposal_data.status,
					statusOld: el.proposal_data.old_status || null,
					board: el.proposal_data.status_credit_pipeline || null,
					proposalId: el.proposal_id,
					type: el.user.search("@") !== -1 ? "user" : "service",
					action: el.action,
				}));
			}

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError(`Histórico de proposta não encontrado!`);

			default:
				throw new UnexpectedError(
					`Ocorreu um erro inesperado ao buscar histórico de proposta!`
				);
		}
	},
});
