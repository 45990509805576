export const numberFormats = {
	"pt-BR": {
		currency: {
			style: "currency",
			currency: "BRL",
			currencyDisplay: "symbol",
		},
	},
	"en-US": {
		currency: {
			style: "currency",
			currency: "USD",
			currencyDisplay: "symbol",
		},
	},
};
