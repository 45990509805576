import Picker from "../picker";
import DatePanel from "../panel/date";

export default {
	mixins: [Picker],

	name: "ElMonthsPicker",

	props: {
		type: {
			type: String,
			default: "date",
		},
		timeArrowControl: Boolean,
	},

	watch: {
		type() {
			if (this.picker) {
				this.unmountPicker();
				this.panel = DatePanel;
				this.mountPicker();
			} else {
				this.panel = DatePanel;
			}
		},
	},

	created() {
		this.panel = DatePanel;
	},
};
