import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { InvalidModelError, UnexpectedError } from "@/core/errors";
import { unmapRate } from "@/core/usecases";

export const remoteCreateRates = (httpClient, baseUrl) => ({
	run: async (rateInputs, proposal) => {
		if (!rateInputs.length) {
			return rateInputs;
		}

		const responses = await Promise.all(
			rateInputs.map((rate) =>
				httpClient.request({
					method: HttpMethod.POST,
					url: `${baseUrl}/rates`,
					body: {
						...unmapRate(rate),
						proposal: proposal.id,
						reason_for_change: proposal.reason_for_change,
					},
				})
			)
		);

		const allWereCreated = responses.every(
			({ statusCode }) => statusCode === HttpStatusCode.CREATED
		);
		if (allWereCreated) {
			return rateInputs;
		}

		const hasBadRequest = responses.some(
			({ statusCode }) => statusCode === HttpStatusCode.BAD_REQUEST
		);
		if (hasBadRequest) {
			throw new InvalidModelError("Verifique os dados das ofertas");
		}

		throw new UnexpectedError("Erro ao criar ofertas!");
	},
});
