import { hasActiveErrors } from "@/core/utils";
import {
	validateMaxValue,
	validateMinValue,
	validateNumber,
	validateRequired,
} from "@/core/validators";

const defaultMaxValue = Number.MAX_SAFE_INTEGER;

export const createAmount = (
	value,
	minAmount = 0,
	maxAmount = defaultMaxValue
) => {
	const errors = { params: {} };

	errors.required = !validateRequired(value);
	errors.number = !validateNumber(value);
	errors.minAmount = !validateMinValue(value, minAmount);
	errors.maxAmount = !validateMaxValue(value, maxAmount);
	errors.params.maxAmount = maxAmount;
	errors.params.minAmount = minAmount;

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value };
};
