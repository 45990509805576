import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { InvalidModelError, UnexpectedError } from "@/core/errors";

export const remoteLoadReceivablesSchedule = (HttpClient, baseUrl) => ({
	run: async (cnpj, offset = 0) => {
		const response = await HttpClient.request({
			url: `${baseUrl}/schedule/`,
			method: HttpMethod.GET,
			query: {
				cnpj,
				offset,
				limit: 25,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return response.body;

			case HttpStatusCode.BAD_REQUEST:
				throw new InvalidModelError("Dados inválidos");

			case HttpStatusCode.DEFAULT:
				throw new UnexpectedError("Erro inesperado");
		}
	},
});
