import { clearDocument } from "@/core/utils";

export const formatSpouseModel = (spouse) => {
	const cleanThose = ["taxId", "idNumber"];
	const contactKeys = ["email", "phone"];

	const entries = Object.entries(spouse);

	const formatted = entries
		// Extract id of Options object
		.map(([key, value]) =>
			typeof value === "object" ? [key, value?.id] : [key, value]
		)
		.map(([key, value]) =>
			cleanThose.includes(key) && value
				? [key, clearDocument(value)]
				: [key, value]
		);

	const personalContactInfo = Object.fromEntries(
		formatted.filter(([key]) => contactKeys.includes(key))
	);

	const variables = {
		...Object.fromEntries(formatted),
		personalContactInfo: {
			...spouse.personalContactInfo,
			...personalContactInfo,
		},
	};
	if (spouse.id) {
		variables.personIdGraphql = spouse.id;
		delete variables.id;
	}

	variables.idEmitter = `${variables.idEmitter}-${variables.idEmitterUF}`;
	[...contactKeys, "idEmitterUF"].forEach((it) => delete variables[it]);

	return variables;
};
