export const remoteGetAllRiskLevel = (graphQLClient, query) => ({
	run: async () =>
		graphQLClient
			.query({
				query,
			})
			.then(
				(response) =>
					response?.data?.allRiscoSocioAmbiental?.edges?.map(
						({ node: { description, id } }) => ({
							name: description,
							id,
						})
					) || []
			),
});
