import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { NotFoundError, UnexpectedError } from "@/core/errors";

const timezone = "T00:00-03:00";

export const remoteSimulateProposal = (
	httpClient,
	baseUrl,
	convertFromCents,
	convertToCents,
	formatDate,
	formatMoney,
	formatPercentage
) => ({
	run: async (id) => {
		const response = await httpClient.request({
			method: HttpMethod.GET,
			url: `${baseUrl}/proposals/simulation/${id}/`,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				const [remoteProposal] = response.body;
				const { rates, ...proposalRest } = remoteProposal;
				return {
					...proposalRest,
					rates: (rates || []).map((rate) => {
						const installments = rate.numerosParcelas;
						const grossAmount = formatMoney(
							convertFromCents(
								convertToCents(rate.valorParcelas) * installments
							)
						);

						return {
							amount: formatMoney(rate.valor),
							cet: {
								annual: formatPercentage(rate.cetAnual),
								monthly: formatPercentage(rate.cetMensal),
							},
							disbursementDate: formatDate(
								new Date(rate.dataDesembolso + timezone)
							),
							firstDueDate: formatDate(
								new Date(rate.dataPrimeiroVencimento + timezone)
							),
							lastDueDate: formatDate(
								new Date(rate.dataUltimoVencimento + timezone)
							),
							grace: rate.carencia,
							grossAmount,
							installments,
							installmentAmount: formatMoney(rate.valorParcelas),
							insterest: {
								annual: formatPercentage(rate.taxaJurosAno),
								monthly: formatPercentage(rate.taxaJurosMes),
								settling: formatPercentage(rate.taxaLiquidacao),
							},
							iof: formatMoney(rate.iof),
							rateId: rate.rateId,
							tac: formatPercentage(rate.tac),
							proposalId: rate.proposalId,
						};
					}),
				};
			}

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError(
					`No momento não foi possível simular a proposta com o id ${id}.`
				);

			default:
				throw new UnexpectedError(
					`Ocorreu um erro inesperado ao simular a proposta para o id ${id}!`
				);
		}
	},
});
