import { clearDocument } from "@/core/utils";

export const remoteCreateGuarantor = (
	graphQLClient,
	updateGuarantorMutation
) => ({
	run: async (model, transactionIdGraphql, guarantors) => {
		const variables = { guarantors: [], transactionIdGraphql };
		let operation;
		const foundGuarantorIndex = guarantors.findIndex(
			(guarantor) =>
				clearDocument(guarantor?.taxId) === clearDocument(model.taxId)
		);

		if (foundGuarantorIndex === -1) {
			operation = "create";
			variables.guarantors = [
				...guarantors,
				{ name: model.name, taxId: clearDocument(model.taxId) },
			];
		}

		if (operation) {
			await graphQLClient.mutate({
				client: "underwriting",
				variables,
				mutation: updateGuarantorMutation,
			});
		}
	},
});
