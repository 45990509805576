import Vue from "vue";
import VueI18n from "vue-i18n";
import VueTheMask from "vue-the-mask";
import { numberFormats } from "./pt_BR";
import { languages } from "./i18n/index";

Vue.use(VueI18n);
Vue.use(VueTheMask);

const messages = Object.assign(languages);

export default new VueI18n({
	locale: "br",
	fallbackLocale: "pt-BR",
	numberFormats,
	messages,
});
