import * as names from "../names";

const component = {
	[names.ASSETS_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'assets' */ "@/views/Assets/Monitoring/index"),
	[names.ASSETS_MONITORING_QMM]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'assets' */ "@/views/Assets/Monitoring/QMM"),
	[names.ASSETS_MONITORING_BALANCE]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'assets' */ "@/views/Assets/Monitoring/AccountsBalance"),
	[names.ASSETS_TRANSFER_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'assets' */ "@/views/Assets/Transfer/index"),
	[names.ASSETS_MONITORING_RETENTION]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'assets' */ "@/views/Assets/Monitoring/Retention"),
	[names.ASSETS_TRANSFER_VIEW]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'assets' */ "@/views/Assets/Transfer/Internal"),
	[names.ASSETS_TRANSFER_RETURNS]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'assets' */ "@/views/Assets/Transfer/Returns"),
};

const routes = {
	name: names.ASSETS_TRANSFER_VIEW,
	path: "transfer",
	meta: {
		label: "Transferências",
		icon: "swap_horizontal_circle",
		path: "/transfer",
		hidden: true,
	},
	children: [
		/* {
			path: "qmm",
			name: names.ASSETS_MONITORING_QMM,
			meta: { label: "QMM", path: "/assets/qmm", icon: "speed" },
		},
		{
			path: "retention",
			name: names.ASSETS_MONITORING_RETENTION,
			meta: {
				label: "Retenção",
				path: "/assets/retention",
				icon: "how_to_vote",
			},
		},
		{
			path: "balance",
			name: names.ASSETS_MONITORING_BALANCE,
			meta: {
				label: "Balanço das contas",
				path: "/assets/balance",
				icon: "account_tree",
			},
		}, */
		// {
		// 	path: "transfers",
		// 	name: names.ASSETS_TRANSFER_VIEW,
		// 	meta: {
		// 		label: "Transferências",
		// 		icon: "swap_horizontal_circle",
		// 		path: "/assets/transfers",
		// 	},
		// },
	],
};

const getRouteViewByName = (route) => {
	const view = component?.[route?.name];
	const hasChildren = route?.children?.length;
	return {
		...route,
		children: hasChildren && route.children.map(getRouteViewByName),
		component: () => (view ? view : route.component),
	};
};

export default getRouteViewByName(routes);
