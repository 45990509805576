export const formatDocument = (str) => {
	if (!str) return "";
	const cleaned = str.replace(/\D/g, "");
	const map = {
		0: { regex: "", tpl: "" },
		11: {
			regex: /(\d{3})(\d{3})(\d{3})(\d{2})/g,
			tpl: "$1.$2.$3-$4",
		},
		14: {
			regex: /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
			tpl: "$1.$2.$3/$4-$5",
		},
		15: {
			regex: /(\d{2})(\d{3})(\d{3})(\d{5})(\d{2})/g,
			tpl: "$1.$2.$3/$4-$5",
		},
	};
	const cur = map[cleaned.length || 0];
	const tpl = cur?.tpl;
	const regex = cur?.regex;

	return cleaned ? cleaned.replace(regex, tpl) : "";
};
