export const AUTH_USER = "auth/AUTH_USER";
export const AUTH_USER_PERMISSIONS = "auth/AUTH_USER_PERMISSIONS";
export const AUTH_USERS = "auth/AUTH_USERS";
export const AUTH_TOKEN = "auth/AUTH_TOKEN";
export const AUTH_LOGIN = "auth/AUTH_LOGIN";
export const AUTH_VERIFY = "auth/AUTH_VERIFY";
export const AUTH_LOGOUT = "auth/AUTH_LOGOUT";
export const AUTH_RECEITAWS = "auth/AUTH_RECEITAWS";
export const AUTH_CREATE_USER = "auth/AUTH_CREATE_USER";
export const AUTH_TERMS_DOWNLOAD = "auth/AUTH_TERMS_DOWNLOAD";
export const AUTH_GOOGLE_LOGIN = "auth/AUTH_GOOGLE_LOGIN";
export const AUTH_TYPE = "auth/AUTH_TYPE";

export const CLIENT_MANAGER_COMPANIES = "client-manager/CLIENT_MANAGER_COMPANIES";
export const CLIENT_MANAGER_INDUSTRIES = "client-manager/CLIENT_MANAGER_INDUSTRIES";
export const CLIENT_MANAGER_INDUSTRIES_ACTIVE = "client-manager/CLIENT_MANAGER_INDUSTRIES_ACTIVE";
export const CLIENT_MANAGER_PAGINATION = "client-manager/CLIENT_MANAGER_PAGINATION";
export const CLIENT_MANAGER_USES_CREDIT = "client-manager/CLIENT_MANAGER_USES_CREDIT";
export const CLIENT_MANAGER_ALL_COMPANIES = "client-manager/CLIENT_MANAGER_ALL_COMPANIES";

export const BANKING_ADDITIONAL_INFO = "banking/BANKING_ADDITIONAL_INFO";

export const BANKING_PROCESS_XLSX = "banking/BANKING_PROCESS_XLSX";
export const BANKING_CREATE_CNAB = "banking/BANKING_CREATE_CNAB";
export const BANKING_CNAB_LIST = "banking/BANKING_CNAB_LIST";
export const BANKING_CLIENTS = "banking/BANKING_CLIENTS";
export const BANKING_ACTIVE_CLIENT = "banking/BANKING_ACTIVE_CLIENT";
export const BANKING_ACCOUNTS = "banking/BANKING_ACCOUNTS";
export const BANKING_ACCOUNTS_BALANCE = "banking/BANKING_ACCOUNTS_BALANCE";
export const BANKING_ACTIVE_ACCOUNT = "banking/BANKING_ACTIVE_ACCOUNT";
export const BANKING_CREATE_PAYMENT_CNAB = "banking/BANKING_CREATE_PAYMENT_CNAB";
export const BANKING_PAYMENT_ORDER_LIST = "banking/BANKING_PAYMENT_ORDER_LIST";

export const BANKING_CNAB_TYPES = "banking/BANKING_CNAB_TYPES";
export const BANKING_CNAB_TYPE_ACTIVE = "banking/BANKING_CNAB_TYPE_ACTIVE";
export const BANKING_CNAB_RETURNS = "banking/BANKING_CNAB_RETURNS";
export const BANKING_CNABS = "banking/BANKING_CNABS";
export const BANKING_CNAB_RETURN_LINK = "banking/BANKING_CNAB_RETURN_LINK";

export const INCEPTION_LEADS = "inception/INCEPTION_LEADS";
export const INCEPTION_SAVE_CONTAS = "inception/INCEPTION_SAVE_CONTAS";
export const CREATE_INCEPTION_LEAD = "inception/CREATE_INCEPTION_LEAD";
export const DELETE_INCEPTION_LEAD = "inception/DELETE_INCEPTION_LEAD";
export const INCEPTION_ACTIVE_LEAD = "inception/INCEPTION_ACTIVE_LEAD";
export const INCEPTION_ACTIVE_LEAD_BALANCETES = "inception/INCEPTION_ACTIVE_LEAD_BALANCETES";
export const INCEPTION_CREATE_PLANO_CONTA = "inception/INCEPTION_CREATE_PLANO_CONTA";
export const INCEPTION_PLANOS_CONTA = "inception/INCEPTION_PLANOS_CONTA";
export const INCEPTION_DELETE_PLANO_CONTA = "inception/INCEPTION_DELETE_PLANO_CONTA";
export const INCEPTION_EDIT_PLANO_CONTA = "inception/INCEPTION_EDIT_PLANO_CONTA";
export const INCEPTION_PARSE_BALANCETE = "inception/INCEPTION_PARSE_BALANCETE";
export const INCEPTION_UPLOAD_BALANCETE = "inception/INCEPTION_UPLOAD_BALANCETE";
export const INCEPTION_CONTAS = "inception/INCEPTION_CONTAS";
export const INCEPTION_CLASSIFY_CONTA = "inception/INCEPTION_CLASSIFY_CONTA";

export const CONFIG_SHOW_NOTIFICATION = "config/CONFIG_SHOW_NOTIFICATION";
export const CONFIG_REPAID_OPERATIONS_PAGE_SIZE = "config/CONFIG_REPAID_OPERATIONS_PAGE_SIZE";
export const CONFIG_ONGOING_OPERATIONS_PAGE_SIZE = "config/CONFIG_ONGOING_OPERATIONS_PAGE_SIZE";

export const UNDERWRITING_TRANSACTIONS = "underwriting/UNDERWRITING_TRANSACTIONS";
export const UNDERWRITING_TRANSACTION_DETAILS = "underwriting/UNDERWRITING_TRANSACTION_DETAILS";
export const UNDERWRITING_CLIENT_PROCESS = "underwriting/UNDERWRITING_CLIENT_PROCESS";
export const UNDERWRITING_COMPANY_DETAILS = "underwriting/UNDERWRITING_COMPANY_DETAILS";
export const UNDERWRITING_CCB_LINKS = "underwriting/UNDERWRITING_CCB_LINKS";

export const MONITORING_PERIOD = "monitoring/MONITORING_PERIOD";
export const MONITORING_OPERATION = "monitoring/MONITORING_OPERATION";
export const MONITORING_OPERATION_TYPES = "monitoring/MONITORING_OPERATION_TYPES";
export const MONITORING_OPERATION_GUARANTEES = "monitoring/MONITORING_OPERATION_GUARANTEES";
export const MONITORING_OPERATION_TYPES_MAP = "monitoring/MONITORING_OPERATION_TYPES_MAP";
export const MONITORING_INVOICE_FILTER = "monitoring/MONITORING_INVOICE_FILTER";
export const MONITORING_VEHICLES = "monitoring/MONITORING_VEHICLES";

export const DOCUMENTS_CLIENT_PROCESSES = "documents/DOCUMENTS_CLIENT_PROCESSES";
export const DOCUMENTS_REVIEW = "documents/DOCUMENTS_REVIEW";
export const DOCUMENTS_PROCESSES = "documents/DOCUMENTS_PROCESSES";
export const DOCUMENTS_DOWNLOAD_LINK = "documents/DOCUMENTS_DOWNLOAD_LINK";
export const DOCUMENTS_DOWNLOAD_FILE_LINK = "documents/DOCUMENTS_DOWNLOAD_FILE_LINK";
export const DOCUMENTS_DELETE_FILE = "documents/DOCUMENTS_DELETE_FILE";
export const DOCUMENTS_ADD_PERSON_TO_GROUP = "documents/DOCUMENTS_ADD_PERSON_TO_GROUP";
export const DOCUMENTS_GET_CLIENT_PROCESS_BY_ID = "documents/DOCUMENTS_GET_CLIENT_PROCESS_BY_ID";
export const DOCUMENTS_ALL_CLIENT_PROCESSES = "documents/DOCUMENTS_ALL_CLIENT_PROCESSES";
export const DOCUMENTS_ALL_CLIENT_PROCESS_DOCUMENTS = "documents/DOCUMENTS_ALL_CLIENT_PROCESS_DOCUMENTS";
export const DOCUMENTS_PROCESSES_CREATE = "documents/DOCUMENTS_PROCESSES_CREATE";
export const DOCUMENTS_PROCESSES_UPDATE = "documents/DOCUMENTS_PROCESSES_UPDATE";
export const DOCUMENTS_PROCESSES_CREATE_TEMPLATE = "documents/DOCUMENTS_PROCESSES_CREATE_TEMPLATE";
export const DOCUMENTS_PROCESSES_CREATE_CLIENT_PROCESS = "documents/DOCUMENTS_PROCESSES_CREATE_CLIENT_PROCESS";
export const DOCUMENTS_PROCESSES_DELETE_CLIENT_PROCESS = "documents/DOCUMENTS_PROCESSES_DELETE_CLIENT_PROCESS";
export const DOCUMENTS_ANALYZE_CLIENT_PROCESS = "documents/DOCUMENTS_ANALYZE_CLIENT_PROCESS";
export const DOCUMENTS_CREATE_PENDENCY = "documents/DOCUMENTS_CREATE_PENDENCY";
export const DOCUMENTS_UPDATE_PENDENCY = "documents/DOCUMENTS_UPDATE_PENDENCY";
export const DOCUMENTS_DELETE_PENDENCY = "documents/DOCUMENTS_DELETE_PENDENCY";

export const PROPOSALS_PROPOSAL = "proposals/PROPOSALS_PROPOSAL";
export const PROPOSALS_CREATE_PROPOSAL = "proposals/PROPOSALS_CREATE_PROPOSAL";
export const PROPOSALS_DELETE_PROPOSAL = "proposals/PROPOSALS_DELETE_PROPOSAL";
export const PROPOSALS_REFUSAL_DETAILS = "proposals/PROPOSALS_REFUSAL_DETAILS";

export const BI_DRAWEE_EVENTS = "bi/BI_DRAWEE_EVENTS";

export const FORMALIZATION_PENDENCY_LIST = "formalization/FORMALIZATION_PENDENCY_LIST";
export const FORMALIZATION_PENDENCY_CREATE = "formalization/FORMALIZATION_PENDENCY_CREATE";
export const FORMALIZATION_PENDENCY_UPDATE = "formalization/FORMALIZATION_PENDENCY_UPDATE";
export const FORMALIZATION_PENDENCY_DELETE = "formalization/FORMALIZATION_PENDENCY_DELETE";

export const PEOPLE_BOARD_VISITED = "people/PEOPLE_BOARD_VISITED";
export const PEOPLE_NATURAL_PROFILE = "people/PEOPLE_NATURAL_PROFILE";
export const PEOPLE_NATURAL_PROFILE_KEY = "people/PEOPLE_NATURAL_PROFILE_KEY";
export const PEOPLE_NATURAL_PERSON = "people/PEOPLE_NATURAL_PERSON";
export const PEOPLE_COMPANY_PROCURATORS = "people/PEOPLE_COMPANY_PROCURATORS";
export const PEOPLE_COMPANY_GUARANTORS = "people/PEOPLE_COMPANY_GUARANTORS";
export const PEOPLE_ADD_COMPANY_PROCURATOR = "people/PEOPLE_ADD_COMPANY_PROCURATOR";
export const PEOPLE_REMOVE_COMPANY_PROCURATOR = "people/PEOPLE_REMOVE_COMPANY_PROCURATOR";
export const PEOPLE_SPOUSE = "people/PEOPLE_SPOUSE";
export const PEOPLE_NATURAL_PERSON_COMPLEMENTARY = "types/PEOPLE_NATURAL_PERSON_COMPLEMENTARY";
export const PEOPLE_PEP_DATA = "types/PEOPLE_PEP_DATA";
export const PEOPLE_PROFILES_LIST = "types/PEOPLE_PROFILES_LIST";

export const PEOPLE_COMPANY_PROFILE = "people/PEOPLE_COMPANY_PROFILE";
export const PEOPLE_COMPANY_PROFILE_KEY = "people/PEOPLE_COMPANY_PROFILE_KEY";
export const PEOPLE_COMPANY_CONTACT = "people/PEOPLE_COMPANY_CONTACT";
export const PEOPLE_ADD_COMPANY_GUARANTOR = "people/PEOPLE_ADD_COMPANY_GUARANTOR";
export const PEOPLE_REMOVE_COMPANY_GUARANTOR = "people/PEOPLE_REMOVE_COMPANY_GUARANTOR";

export const DASHBOARD_FILTER_STATUS = "dashboard/DASHBOARD_FILTER_STATUS";
export const DASHBOARD_GENERAL = "dashboard/DASHBOARD_GENERAL";
export const DASHBOARD_COMPANIES = "dashboard/DASHBOARD_COMPANIES";
export const DASHBOARD_COMPANY = "dashboard/DASHBOARD_COMPANY";
export const DASHBOARD_DEFAULT_TIMELINE = "dashboard/DASHBOARD_DEFAULT_TIMELINE";
export const DASHBOARD_DEFAULT_RECOVERED = "dashboard/DASHBOARD_DEFAULT_RECOVERED";
export const DASHBOARD_DEFAULT_COMPANIES = "dashboard/DASHBOARD_DEFAULT_COMPANIES";
export const DASHBOARD_COMPANY_MORE_INFO = "dashboard/DASHBOARD_COMPANY_MORE_INFO";
export const DASHBOARD_DOWNLOAD_INSTRUMENT = "dashboard/DASHBOARD_DOWNLOAD_INSTRUMENT";
export const DASHBOARD_COMPANY_SCHEDULE = "dashboard/DASHBOARD_COMPANY_SCHEDULE";

export const GET_CNAE_DESCRIPTIONS = "dashboard/GET_CNAE_DESCRIPTIONS";

export const PARTNERS_LIST = "partners/PARTNERS_LIST";
export const PARTNERS_MAP = "partners/PARTNERS_MAP";
export const PARTNERS_METADATA = "partners/PARTNERS_METADATA";
export const PARTNERSHIP_LIST = "partnership/PARTNERSHIP_LIST";
