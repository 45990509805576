import {
	DuplicationError,
	InvalidModelError,
	UnexpectedError,
} from "@/core/errors";
import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { createProposalEntity } from "@/core/entities";
import { mapProposal } from "./remoteLoadProposals";

export const remoteCreateProposal = (httpClient, baseUrl) => ({
	run: async (proposal, clientRisk) => {
		const proposalEntity = createProposalEntity(proposal, clientRisk);
		const { partner, productId, rates, vehicleId, guarantee, orderUuid, monthlyGuaranteeValue, riskPolicy, ...proposalRest } =
			proposalEntity;

		console.log("Entity", proposalRest);
		const body = {
			...proposalRest,
			product_id: productId,
			rates: rates.map(({ installments, prepaymentFee, ...rateRest }) => ({
				...rateRest,
				number_installments: installments,
				prepayment_fee: prepaymentFee,
			})),
		};

		if (partner) {
			body.partner = partner;
		}

		if (vehicleId) {
			body.vehicle_id = vehicleId;
		}

		if (guarantee) {
			body.guarantee = guarantee;
		}
		if (orderUuid) {
			body.order_uuid = orderUuid;
		}
		if (monthlyGuaranteeValue){
			body.monthly_guarantee_value = monthlyGuaranteeValue;
		}
		if (riskPolicy){
			body.risks_policy = riskPolicy;
		}
		const response = await httpClient.request({
			method: HttpMethod.POST,
			url: `${baseUrl}/proposals`,
			body,
		});

		switch (response.statusCode) {
			case HttpStatusCode.CREATED:
				return mapProposal(response.body);

			case HttpStatusCode.BAD_REQUEST: {
				if (response.body.non_field_errors?.length) {
					throw new DuplicationError(
						"Já existe uma proposta aprovada para esta empresa"
					);
				}

				throw new InvalidModelError("Dados da proposta inválidos");
			}

			default:
				throw new UnexpectedError("Erro inesperado ao criar a proposta!");
		}
	},
});
