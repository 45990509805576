export const formatDateAdapter =
	() =>
	(value, options = null) => {
		const formatter = new Intl.DateTimeFormat("pt-BR", {
			dateStyle: "short",
			...options,
		});

		return formatter.format(value);
	};
