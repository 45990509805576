import * as Comlink from "comlink";
import Worker from "worker-loader!./worker";

export const workerAdapter = () => ({
	runTask: async (taskFunction, ...args) => {
		let worker = new Worker();
		const remoteTask = Comlink.wrap(worker);
		const result = await remoteTask(Comlink.proxy(taskFunction), args);
		worker.terminate();
		worker = null;
		return result;
	},
});
