import { makeGetErrorMessages, makeWorkerAdapter } from "@/core/factories";

export const makeGetErrorMessagesWorker = () => {
	const getErrorMessages = makeGetErrorMessages();
	const worker = makeWorkerAdapter();

	return {
		run: async (...args) => {
			return worker.runTask(getErrorMessages.run, ...args);
		},
	};
};
