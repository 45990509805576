import gql from "graphql-tag";
import { UnexpectedError } from "@/core/errors";

const query = gql`
	query AllOperationType {
		allOperationType {
			edges {
				node {
					id
					description
					active
				}
			}
		}
	}
`;

export const remoteLoadOperationTypes = (graphQLClient, base64Decoder) => ({
	run: async (idAsInteger = false) => {
		try {
			const response = await graphQLClient.query({ query });

			return response.data?.allOperationType?.edges?.map(({ node }) => {
				if (!idAsInteger) {
					return node;
				}

				const id = +base64Decoder(node.id).split(":")[1];

				if (node.description === "Digital") {
					return { ...node, id, description: "Capital de giro" };
				}
				return { ...node, id };
			});
		} catch (error) {
			throw new UnexpectedError("Erro ao buscar tipos de operação");
		}
	},
});
