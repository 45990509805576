import gql from "graphql-tag";
import { UnexpectedError } from "@/core/errors";

const query = gql`
	query AllTransaction($last: Int, $taxId: String) {
		allTransaction(last: $last, borrowerTaxId: $taxId) {
			edges {
				node {
					id
					proposalId
					borrowerTaxId
					currentVersion {
						id
						maxExposure
						createdAt
						createdBy
					}
				}
			}
		}
	}
`;

export const remoteLoadTransactions = (graphQLClient) => ({
	run: async (taxId, last = 1) => {
		try {
			const response = await graphQLClient.query({
				query,
				variables: { taxId, last },
			});

			return response.data?.allTransaction?.edges?.map(({ node }) => node);
		} catch (error) {
			throw new UnexpectedError("Erro ao buscar transações");
		}
	},
});
