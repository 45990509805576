import Vue from "vue";
import VueCurrencyInput from "vue-currency-input";

const pluginOptions = {
	globalOptions: {
		distractionFree: false,
		autoDecimalMode: true,
		currency: {
			prefix: "R$ ",
			suffix: "",
		},
		locale: "pt-BR",
	},
};

Vue.use(VueCurrencyInput, pluginOptions);
