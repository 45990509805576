import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { UnexpectedError } from "@/core/errors";

export const remoteUpdateRenegotiationStatus = (httpClient, baseUrl) => ({
	run: async (renegotiationId, statusId) => {
		const response = await httpClient.request({
			url: `${baseUrl}/renegotiation`,
			method: HttpMethod.PUT,
			query: {
				renegotiation_id: renegotiationId,
				status_id: statusId,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return true;

			case HttpStatusCode.CREATED:
				return true;

			default:
				throw new UnexpectedError("Erro inesperado ao atualizar status!");
		}
	},
});
