import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { UnexpectedError } from "@/core/errors";

export const remoteSaveRenegotiationCalculation = (httpClient, baseUrl) => ({
	run: async (inputs, outputs) => {
		const response = await httpClient.request({
			url: `${baseUrl}/renegotiation`,
			method: HttpMethod.POST,
			body: {
				inputs,
				outputs,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return true;

			case HttpStatusCode.CREATED:
				return true;

			default:
				throw new UnexpectedError("Erro inesperado ao salvar renegociação!");
		}
	},
});
