import * as names from "../names";

const component = {
	[names.PARTNERSHIP_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'partnership' */ "@/views/Partnership/index"),

	[names.PARTNERSHIP_MANAGEMENT]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'partnership' */ "@/views/Partnership/Management/index"),
	[names.PARTNERSHIP_LIST]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'partnership' */ "@/views/Partnership/Management/List"),
	[names.PARTNERSHIP_ORDERS]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'partnership' */ "@/views/Partnership/Orders/index"),
	[names.PARTNERSHIP_ORDERS_LIST]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'partnership' */ "@/views/Partnership/Orders/List"),

	// [names.PARTNERSHIP_PARSER]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'partnership' */ "@/views/Partnership/WorksheetParser/index"),
};

export default {
	path: "partnership",
	name: names.PARTNERSHIP_INDEX,
	component: () => component[names.PARTNERSHIP_INDEX],
	redirect: { name: names.PARTNERSHIP_LIST },
	meta: {
		isGroup: true,
		label: "Parcerias",
		path: "/partnership",
		icon: "groups",
		hidden: true,
	},
	children: [
		{
			path: "management",
			component: () => component[names.PARTNERSHIP_MANAGEMENT],
			meta: {
				label: "Gerenciar parceiros",
				path: "/partnership/management",
				icon: "manage_accounts",
			},
			children: [
				{
					path: "",
					name: names.PARTNERSHIP_LIST,
					meta: {
						hideLabel: true,
					},
					component: () => component[names.PARTNERSHIP_LIST],
				},
			],
		},
		{
			path: "orders",
			component: () => component[names.PARTNERSHIP_ORDERS],
			meta: {
				label: "Lista de pedidos",
				path: "/partnership/orders",
				icon: "assignment",
			},
			children: [
				{
					path: "",
					name: names.PARTNERSHIP_ORDERS_LIST,
					meta: {
						hideLabel: true,
					},
					component: () => component[names.PARTNERSHIP_ORDERS_LIST],
				},
			],
		},
	],
};
