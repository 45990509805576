import axios from "axios";
import shortly from "vuex-shortly";
import * as types from "@/store/types";
import { setInterceptors } from "@/plugins/axios";

const accessBankingApi = axios.create({
	baseURL: process.env.VUE_APP_A55_BANKING_REST,
});

setInterceptors(accessBankingApi);

const state = {
	bankAdditionalInfo: {},
	collectionBill: {},
	activeClient: {},
	activeAccount: {},
	clients: [],
	accounts: [],
	accountsBalance: [],
	paymentOrderList: [],
};

const getters = {
	[types.BANKING_ADDITIONAL_INFO]: "bankAdditionalInfo",
	[types.BANKING_CLIENTS]: "clients",
	[types.BANKING_ACTIVE_CLIENT]: "activeClient",
	[types.BANKING_ACCOUNTS]: "accounts",
	[types.BANKING_ACCOUNTS_BALANCE]: "accountsBalance",
	[types.BANKING_ACTIVE_ACCOUNT]: "activeAccount",
	[types.BANKING_PAYMENT_ORDER_LIST]: "paymentOrderList",
};

const mutations = {
	[types.BANKING_ADDITIONAL_INFO]: "bankAdditionalInfo",
	[types.BANKING_CLIENTS]: "clients",
	[types.BANKING_ACTIVE_CLIENT]: "activeClient",
	[types.BANKING_ACCOUNTS]: "accounts",
	[types.BANKING_ACCOUNTS_BALANCE]: "accountsBalance",
	[types.BANKING_ACTIVE_ACCOUNT]: "activeAccount",
	[types.BANKING_PAYMENT_ORDER_LIST]: "paymentOrderList",
};

const actions = {
	[types.BANKING_ADDITIONAL_INFO]: async ({ commit }) => {
		let response = undefined;
		try {
			response = await accessBankingApi.get("/bank/additional-info");
			if (response && response.data) {
				commit(types.BANKING_ADDITIONAL_INFO, response.data);
				return response;
			}
		} catch (error) {
			return error;
		}
	},
	[types.BANKING_ACTIVE_CLIENT]: ({ commit }, client) => {
		commit(types.BANKING_ACTIVE_CLIENT, client);
	},
	[types.BANKING_CLIENTS]: ({ commit }, clients) => {
		commit(types.BANKING_CLIENTS, clients);
	},
	[types.BANKING_ACTIVE_ACCOUNT]: ({ commit }, account) => {
		commit(types.BANKING_ACTIVE_ACCOUNT, account);
	},
	[types.BANKING_ACCOUNTS]: ({ commit }, accounts) => {
		commit(types.BANKING_ACCOUNTS, accounts);
	},
	[types.BANKING_ACCOUNTS_BALANCE]: async (
		{ commit },
		{ start_date, end_date }
	) => {
		const response = await accessBankingApi.get("/account/balance", {
			params: { start_date, end_date },
		});
		commit(
			types.BANKING_ACCOUNTS_BALANCE,
			response.data.map((it) => {
				if (!it.holder_name) it.holder_name = it.holder_tax_id;
				if (!it.bank_name) it.bank_name = "A55";
				return it;
			})
		);
	},
	[types.BANKING_CREATE_PAYMENT_CNAB]: (ctx, { accountId, payload }) => {
		return accessBankingApi.post(
			`/payment/${accountId}/create-payment-cnab`,
			payload
		);
	},
	[types.BANKING_PAYMENT_ORDER_LIST]: async ({ commit }, payload) => {
		try {
			const response = await accessBankingApi.post(
				`/payment/payment-order`,
				payload
			);
			commit(types.BANKING_PAYMENT_ORDER_LIST, response?.data || []);
		} catch (error) {
			console.log(error);
		}
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
