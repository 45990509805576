import { clearDocument } from "@/core/utils";

export const remoteCreateOrUpdateCompany = (
	graphQLClient,
	createCompanyMutation,
	updateCompanyMutation
) => ({
	run: (profile, companyData) => {
		const variables =
			profile.percentageOwned >= 25
				? { ...profile, ...companyData }
				: { ...profile };

		variables.cnpj = clearDocument(variables.cnpj);
		variables.state = variables.state?.name;
		variables.industryIdGraphql = variables.industry?.id;

		const isEditting = !!variables.id;
		const [mutation, responseKey] = !isEditting
			? [createCompanyMutation, "createClient"]
			: [updateCompanyMutation, "updateClient"];

		return graphQLClient
			.mutate({
				variables,
				mutation,
			})
			.then((response) => response?.data?.[responseKey]?.client);
	},
});
