import { remoteGetClientProcurators } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const procuratorsQuery = gql`
	query ($client_Cnpj: String) {
		allProcurator(client_Cnpj: $client_Cnpj) {
			edges {
				node {
					id
					procuratorTaxId
					isSigner
					person {
						name
						taxId
					}
				}
			}
		}
	}
`;

export const makeRemoteGetClientProcurators = () =>
	remoteGetClientProcurators(clients["client-manager"], procuratorsQuery);
