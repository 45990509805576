import "@/polyfills";

import Vue from "vue";
import App from "./App.vue";
import store from "./store/";
import router from "./router/";
import "./plugins/";
import { apolloProvider } from "./plugins/apollo";
import i18n from "./plugins/i18n";
import VueGtag from "vue-gtag";
import packageJson from "../package.json";
import { convertFromCents, convertToCents } from "./core/money";

Vue.config.productionTip = false;

Vue.prototype.$tz = "T00:00-03:00";
Vue.prototype.$convertFromCents = convertFromCents;
Vue.prototype.$convertToCents = convertToCents;

window.version = `A55 - ${packageJson.name.toUpperCase()} | v${
	packageJson.version
} [${process.env.VUE_APP_MODE}]`;

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const vue = new Vue({
	render: (h) => h(App),
	router,
	store,
	i18n,
	apolloProvider,
});

Vue.use(
	VueGtag,
	{
		appName: "Midgard",
		pageTrackerScreenviewEnabled: true,
		pageTrackerTemplate(to) {
			return {
				page_title: to.name,
				page_path: to.path,
				page_location: window.location.href,
			};
		},
		config: { id: "G-RQHE450Y1P" },
		enabled: process.env.NODE_ENV === "production",
	},
	router
);

(async () => {
	await timeout(1000);
	const loader = document.querySelector(".a-loader");
	if (loader) loader.classList.add("--done");
	await timeout(1500);
	cancelAnimationFrame(window.ALoader);
	vue.$mount("#app");
})();
