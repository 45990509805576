import gql from "graphql-tag";
import { createAllocationEntity } from "@/core/entities";
import { convertToCents } from "@/core/money";
import { UnexpectedError, ValidationError } from "@/core/errors";

const mutation = gql`
	mutation CreateAllocation(
		$amount: Float!
		$transactionVersionId: ID!
		$vehicleId: ID!
	) {
		createAllocation(
			amount: $amount
			transactionVersionIdGraphql: $transactionVersionId
			vehicleIdGraphql: $vehicleId
		) {
			allocation {
				id
				amount
				transactionVersion {
					id
				}
				vehicle {
					id
					name
				}
			}
		}
	}
`;

export const remoteCreateAllocation = (graphQLClient) => ({
	run: async (allocation) => {
		try {
			const allocationEntity = createAllocationEntity(allocation);
			const { amount, transaction, vehicle } = allocationEntity;
			const response = await graphQLClient.mutate({
				mutation,
				variables: {
					amount: convertToCents(amount),
					transactionVersionId: transaction.currentVersion.id,
					vehicleId: vehicle.id,
				},
			});

			return response.data?.createAllocation?.allocation;
		} catch (error) {
			if (error instanceof ValidationError) {
				throw error;
			}

			console.log(error);
			throw new UnexpectedError("Erro ao criar alocação");
		}
	},
});
