import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { PROPOSAL_REFUSAL_REASON as REFUSAL_REASON } from "@/core/entities";
import { InvalidModelError, UnexpectedError } from "@/core/errors";

export const remoteRefuseProposal = (httpClient, baseUrl) => ({
	run: async (proposalId, refusal) => {
		const { reason } = refusal;
		const body = { reason };

		switch (refusal.reason) {
			case REFUSAL_REASON.CREDIT_AMOUNT:
				body.new_proposal_value = refusal.newCreditAmount;
				break;

			case REFUSAL_REASON.DEADLINE:
				body.new_proposal_deadline = refusal.newDeadline;
				break;

			case REFUSAL_REASON.OTHER:
				body.detail = refusal.detail;
				break;
		}

		const response = await httpClient.request({
			method: HttpMethod.PATCH,
			url: `${baseUrl}/proposals/refuse/${proposalId}/`,
			body,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return refusal;

			case HttpStatusCode.BAD_REQUEST:
				throw new InvalidModelError("Verifique os dados da recusa!");

			default:
				throw new UnexpectedError("Erro inesperado ao recusar proposta!");
		}
	},
});
