import { remoteLoadTransactionDetails } from "@/core/usecases";
import { clients } from "@/plugins/apollo";
import { gql } from "graphql-tag";

const query = gql`
	query transactionsDetails($id: ID) {
		allTransaction(id: $id) {
			edges {
				node {
					id
					transactionCode
					borrowerTaxId
					accountHolderTaxId
					partner
					operationId
					borrower {
						name
						cnpj
					}
					documentProcessId
					bankName
					bankAgency
					bankAgencyDigit
					bankAccount
					bankAccountDigit
					sentDocumentsEbox
					product {
						id
						description
					}
					currentVersion {
						id
						instrumentType {
							id
							type
						}
						financialcovenantSet {
							edges {
								node {
									id
									accountType {
										id
										description
									}
									type {
										id
										description
									}
									verificationPeriod
									valueMandatoryPrepayment
									valueRefinancing
									bankCode
									agency
									agencyDigit
									accountNumber
									accountDigit
									accountId
								}
							}
						}
						billingSet {
							edges {
								node {
									amount
									id
									verificationPeriod
									type {
										id
										description
									}
								}
							}
						}
						status {
							description
						}
						tenor
						minimumInterestRate
						maxExposure
						allocationSet {
							edges {
								node {
									amount
								}
							}
						}
						prepaymentFeeValue
						trancheSet {
							edges {
								node {
									id
									maximumAmount
									dueIn
									fixedAmortizationDate
									frontendFee
									grace
									tac
									trancheguarantorSet {
										edges {
											node {
												id
												guarantorTaxId
												percentage
												guarantorName
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const makeRemoteLoadTransactionDetails = () =>
	remoteLoadTransactionDetails(clients["underwriting"], query);
