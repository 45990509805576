import { hasActiveErrors } from "@/core/utils";
import { validateRequired, validateProposalStatus } from "@/core/validators";

export const createProposalStatus = (value) => {
	const errors = { params: {} };

	errors.required = !validateRequired(value);
	errors.status = !validateProposalStatus(value);
	errors.params.status = value;

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value };
};
