import { hasActiveErrors } from "@/core/utils";
import {
	validateMaxValue,
	validateMinValue,
	validateNumber,
} from "@/core/validators";

export const createGrace = (value, minValue = 0, maxValue = 360) => {
	const errors = { params: {} };

	errors.number = !validateNumber(value);
	errors.minValue = !validateMinValue(value, minValue);
	errors.maxValue = !validateMaxValue(value, maxValue);
	errors.params.maxValue = maxValue;
	errors.params.minValue = minValue;

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value };
};
