import { remoteGetClientBusinessData } from "@/core/usecases";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

import { makeLocalParseClientBusinessData } from "@/core/factories";

const query = gql`
	query allClient($active: Boolean, $cnpj: String!) {
		allClient(active: $active, cnpj: $cnpj) {
			edges {
				node {
					name
					cnpj
					registeredName
					societaryType
					foundingDate
					zipCode
					address
					neighborhood
					city
					state
					mainActivity {
						id
						code
						description
					}
					secondaryActivity {
						edges {
							node {
								id
								code
								description
							}
						}
					}
				}
			}
		}
	}
`;

export const makeRemoteGetClientBusinessData = () =>
	remoteGetClientBusinessData(
		clients["client-manager"],
		query,
		makeLocalParseClientBusinessData()
	);
