import axios from "axios";
import shortly from "vuex-shortly";
import * as types from "@/store/types";
import { setInterceptors } from "@/plugins/axios";

export const biAPI = axios.create({
	baseURL: process.env.VUE_APP_A55_BI_REST,
});

setInterceptors(biAPI);

const state = {
	draweeEvents: [],
};

const getters = {
	[types.BI_DRAWEE_EVENTS]: "draweeEvents",
};

const mutations = {
	[types.BI_DRAWEE_EVENTS]: "draweeEvents",
};

const actions = {
	[types.BI_DRAWEE_EVENTS]: async ({ commit }, taxId) => {
		const response = await biAPI.get(`/draweeEvent/${taxId}/aggregated`);
		commit(types.BI_DRAWEE_EVENTS, response.data);
		return response;
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
