import { remoteLoadAllTransaction } from "@/core/usecases/underwriting";
import { clients } from "@/plugins/apollo";
import { gql } from "graphql-tag";

const query = gql`
	query allTransaction(
		$first: Int
		$borrowerTaxId: String
		$borrowerName: String
		$offset: Int
	) {
		allTransaction(
			borrowerTaxId: $borrowerTaxId
			borrowerName: $borrowerName
			first: $first
			offset: $offset
		) {
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			edges {
				node {
					id
					borrowerTaxId
					borrower {
						name
					}
					currentVersion {
						totalAmount
						createdAt
						status {
							description
						}
						trancheSet {
							edges {
								node {
									maximumAmount
									dueIn
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const makeRemoteLoadAllTransaction = () =>
	remoteLoadAllTransaction(clients["underwriting"], query);
