import { clearDocument } from "@/core/utils";

export const remoteGetClientProcurators = (
	graphQLClient,
	procuratorsQuery
) => ({
	run: async (clientCnpj) => {
		const { data } = await graphQLClient.query({
			query: procuratorsQuery,
			fetchPolicy: "network-only",
			variables: { client_Cnpj: clearDocument(clientCnpj) },
		});

		return data.allProcurator.edges.map(({ node }) => node);
	},
});
