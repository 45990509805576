import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { UnexpectedError } from "@/core/errors";

export const remoteLoadAllRenegotiation = (httpClient, baseUrl) => ({
	run: async (page = 1, queryParams = {}) => {
		const response = await httpClient.request({
			url: `${baseUrl}/all-renegotiation`,
			method: HttpMethod.GET,
			query: {
				page,
				...queryParams,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return {
					data: response?.body?.results,
					metadata: {
						count: response?.body?.count,
					},
				};

			default:
				throw new UnexpectedError("Erro inesperado ao listar renegociações!");
		}
	},
});
