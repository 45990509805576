export const remoteGetAllActivities = (graphQLClient, query) => ({
	run: async () =>
		graphQLClient
			.query({
				query,
			})
			.then(
				(response) =>
					response?.data?.allActivity?.edges
						.map((it) => it.node)
						.map(({ id, code, description }) => ({
							id,
							name: `${code || "N/A"} | ${description || "N/A"}`,
						})) || []
			),
});
