import { hasActiveErrors } from "@/core/utils";
import {
	validateRequired,
	validateProposalReasonForChange,
} from "@/core/validators";

export const createProposalReasonForChange = (value) => {
	const errors = { params: {} };

	errors.required = !validateRequired(value);
	errors.reason_for_change = !validateProposalReasonForChange(value);
	errors.params.reason_for_change = value;

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value };
};
