const cleanCurrency = (string) =>
	typeof string === "string"
		? Number(string.replace(/[^0-9,]+/g, "").replace(",", "."))
		: string;

// shiftDigitLeft(12.34, 2) => 1234
export const shiftDecimalLeft = (number, decimalDigits = 2) => {
	const n = cleanCurrency(number);
	const factor = 10 ** decimalDigits;
	return (Math.round(n * factor + Number.EPSILON) * factor) / factor;
};

// shiftDigitRight(1234, 2) => 12.34
export const shiftDecimalRight = (number, decimalDigits = 2) => {
	const n = cleanCurrency(number);
	const factor = 10 ** decimalDigits;
	return Math.round(n * factor + Number.EPSILON) / factor / factor;
};
