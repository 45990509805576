import { InvalidModelError, UnexpectedError } from "@/core/errors";
import { HttpMethod, HttpStatusCode } from "@/core/adapters";

export const remoteCreateInvoice = (httpClient, baseUrl) => ({
	run: async (id, payload) => {
		const { amount, dueDate } = payload;

		const response = await httpClient.request({
			method: HttpMethod.POST,
			url: `${baseUrl}/expected_cashflow_boleto/create/on_demand/${id}`,
			body: {
				due_date: dueDate,
				amount,
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.CREATED:
				return response.body;

			case HttpStatusCode.OK:
				return response.body;

			case HttpStatusCode.BAD_REQUEST: {
				throw new InvalidModelError(
					"Dados inválidos na para criação de boleto"
				);
			}

			default:
				throw new UnexpectedError("Erro inesperado ao gerar o boleto!");
		}
	},
});
