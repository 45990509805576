import { remoteLoadExpectedCashflowEntries } from "../../usecases/operation";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const query = gql`
	query allCashflowVersion($instrumentId: Int, $instrumentIdGraphql: ID) {
		allCashflowVersion(
			instrumentId: $instrumentId
			instrumentIdGraphql: $instrumentIdGraphql
		) {
			id
			instrument {
				id
				contractNumber
			}
			# instrument {
			expectedCashflowEntries {
				edges {
					node {
						id
						amount
						date
						creditDate
						installmentId
						operation {
							id
							operationCode
							client {
								id
								name
							}
						}
					}
				}
			}
			# }
			createdDate
			description
		}
	}
`;

export const makeRemoteLoadExpectedCashflowEntries = () =>
	remoteLoadExpectedCashflowEntries(clients["monitor"], query);
