import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { NotFoundError, UnexpectedError } from "@/core/errors";

const mapBoleto = (b) => ({
	pdf: b.hibrido_url,
	amount: b.amount,
	paidAmount: b.paid_amount,
	emails: b.emails,
	dueDate: b.due_date,
	company: b.drawee?.name,
	taxId: b.drawee?.cnpj,
	vehicle: b.account?.vehicle_name,
	paymentDate: b.payment_date,
	expectedCashflowId: b.expected_cashflow_id,
	status: b.status,
	contractNumber: b.contract_number,
	installmentId: b.extra?.installmentId,
});

const getUrlParam = (url, param) => new URLSearchParams(url).get(param);

export const remoteListBoleto = (httpClient, baseUrl) => ({
	run: async (query) => {
		const response = await httpClient.request({
			url: `${baseUrl}/boleto`,
			method: HttpMethod.GET,
			query: {
				cnpj: query?.taxId || "",
				page: query?.page || 1,
				status: query?.status || "",
				contract_number: query?.contractNumber || "",
			},
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return {
					data: response.body.results.map(mapBoleto),
					metadata: {
						count: response.body.count,
						pages: {
							current: query.page || 1,
							next: getUrlParam(response.body.next, "page"),
							prev: getUrlParam(response.body.previous, "page"),
						},
					},
				};

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError("Nenhum boleto encontrado!");

			default:
				throw new UnexpectedError(
					"Ocorreu um erro inesperado ao buscar boletos!"
				);
		}
	},
});
