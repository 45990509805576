import { authorizeHttpClientDecorator } from "@/core/decorators";
import {
	makeLocalStorageAdapter,
	makeEventAdapter,
	makeHttpClientAdapter,
} from "@/core/factories";

export const makeAuthorizeHttpClientDecorator = () =>
	authorizeHttpClientDecorator(
		process.env.VUE_APP_MODE,
		makeEventAdapter(),
		makeHttpClientAdapter(),
		makeLocalStorageAdapter()
	);
