import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { NotFoundError, UnexpectedError } from "@/core/errors";

export const remoteLoadProposalRefusal = (
	httpClient,
	baseUrl,
	formatDate,
	formatMoney
) => ({
	run: async (proposalId) => {
		const response = await httpClient.request({
			method: HttpMethod.GET,
			url: `${baseUrl}/proposals/refuse/${proposalId}/`,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				const {
					new_proposal_deadline,
					new_proposal_value,
					proposal_id,
					refusal_date,
					...refusal
				} = response.body;

				return {
					...refusal,
					refusalDate: formatDate(new Date(refusal_date)),
					newCreditAmount: formatMoney(new_proposal_value || 0),
					newDeadline: new_proposal_deadline,
					proposalId: proposal_id,
				};
			}

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError(
					`Recusa da proposta #${proposalId} não encontrada!`
				);

			default:
				throw new UnexpectedError(
					`Ocorreu um erro inesperado ao buscar a recusa da proposta #${proposalId}!`
				);
		}
	},
});
