import { PROPOSAL_REFUSAL_REASON as REFUSAL_REASON } from "@/core/entities";
import {
	createAmount,
	createEnum,
	createNumber,
	createString,
} from "@/core/value-objects";
import { hasActiveErrors } from "@/core/utils";

export const createProposalRefusal = (refusalInput) => {
	const reason = createEnum(refusalInput.reason, [
		REFUSAL_REASON.CREDIT_AMOUNT,
		REFUSAL_REASON.DEADLINE,
		REFUSAL_REASON.OTHER,
		REFUSAL_REASON.RATE,
	]);
	const element = { prop: null, errors: null };

	if (refusalInput.reason === REFUSAL_REASON.CREDIT_AMOUNT) {
		const newCreditAmount = createAmount(
			refusalInput.newCreditAmount,
			20000,
			Number.MAX_SAFE_INTEGER
		);
		element.prop = "newCreditAmount";
		element.errors = newCreditAmount.errors;
	}

	if (refusalInput.reason === REFUSAL_REASON.DEADLINE) {
		const newDeadline = createNumber(refusalInput.newDeadline, 1, 18);
		element.prop = "newDeadline";
		element.errors = newDeadline.errors;
	}

	if (refusalInput.reason === REFUSAL_REASON.OTHER) {
		const detail = createString(refusalInput.detail, 10, 2500);
		element.prop = "detail";
		element.errors = detail.errors;
	}

	const errors = { reason: reason.errors };

	if (element.prop) {
		errors[element.prop] = element.errors;
	}

	const isValid = !hasActiveErrors(errors);

	return { errors, isValid, value: refusalInput };
};
