import { clearDocument, isCPF, isCNPJ } from "@/core/utils";

export const remoteGetPersonByDocument = (
	graphQLClient,
	personQuery,
	companyQuery
) => ({
	run: async (document) => {
		const testCNPJ = isCNPJ(document) ? [companyQuery, true] : [null, null];

		const [query, isCompany] = isCPF(document)
			? [personQuery, false]
			: testCNPJ;

		if (!query) throw new Error("Documento inválido");

		const cleanedDocument = clearDocument(document);

		return graphQLClient
			.query({
				query,
				fetchPolicy: "no-cache",
				errorPolicy: "ignore",
				notifyOnNetworkStatusChange: true,
				variables: isCompany
					? { cnpj: cleanedDocument }
					: { taxId: cleanedDocument },
			})
			.then(
				(res) =>
					(isCompany
						? res.data.allClient?.edges?.[0]?.node
						: res.data?.personDetails?.edges?.[0]?.node) || {
						taxId: cleanedDocument,
					}
			);
	},
});
