import Vue from "vue";
import ElementUI from "element-ui";
import lang from "element-ui/lib/locale/lang/pt-br";
import locale from "element-ui/lib/locale";
import "@/element-variables.scss";
import MonthsPicker from "@/components/ElMonthsPicker/";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

locale.use(lang);

Vue.use(ElementUI);
Vue.use(MonthsPicker);
Vue.component("multiselect", Multiselect);
