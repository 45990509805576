export const remoteUpdateDisbursementAccount = (
	getBankList,
	graphQLClient,
	mutation
) => ({
	run: async (model, transactionIdGraphql) => {
		const variables = {
			...model,
			bankName: model?.bankName?.id,
			transactionIdGraphql,
		};

		return graphQLClient
			.mutate({
				fetchPolicy: "no-cache",
				mutation,
				variables,
			})
			.then((response) => response?.data?.updateTransactionTerms?.transaction)
			.then((response) => ({
				...response,
				bankName: getBankList.run().find((it) => it.id === response?.bankName),
			}));
	},
});
