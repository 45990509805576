export const remoteDeleteCompany = (graphQLClient, companyDeleteMutation) => ({
	run: async (company) =>
		graphQLClient.mutate({
			client: "client-manager",
			mutation: companyDeleteMutation,
			variables: {
				childCompanyTaxId: company.childCompanyTaxId,
				parentCompanyTaxId: company.taxId,
			},
		}),
});
