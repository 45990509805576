import axios from "axios";
import { setInterceptors } from "@/plugins/axios";
import * as types from "@/store/types";
import shortly from "vuex-shortly";
import {
	makeRemoteLoadOperationTypes,
	makeRemoteLoadVehicles,
	makeRemoteLoadOperationGuarantees,
} from "@/core/factories";

const loadOperationTypes = makeRemoteLoadOperationTypes();
const loadVehicles = makeRemoteLoadVehicles();
const loadOperationGuarantees = makeRemoteLoadOperationGuarantees();

export const monitorApi = axios.create({
	baseURL: process.env.VUE_APP_A55_MONITOR_REST,
});

setInterceptors(monitorApi);

const state = {
	period: undefined,
	account: undefined,
	operation: undefined,
	operationTypes: [],
	operationGuarantees: [],
	qmmPeriods: [],
	filter: "",
	vehicles: [],
};

const getters = {
	[types.MONITORING_PERIOD]: "period",
	[types.MONITORING_OPERATION]: "operation",
	[types.MONITORING_OPERATION_TYPES]: "operationTypes",
	[types.MONITORING_OPERATION_GUARANTEES]: "operationGuarantee",
	[types.MONITORING_INVOICE_FILTER]: "filter",
	[types.MONITORING_VEHICLES]: "vehicles",
	[types.MONITORING_OPERATION_TYPES_MAP]: (state) =>
		state.operationTypes.reduce(
			(operationTypesMap, operationType) => ({
				...operationTypesMap,
				[operationType.id]: operationType,
			}),
			{}
		),
};

const mutations = {
	[types.MONITORING_PERIOD]: "period",
	[types.MONITORING_OPERATION]: "operation",
	[types.MONITORING_OPERATION_TYPES]: "operationTypes",
	[types.MONITORING_OPERATION_GUARANTEES]: "operationGuarantee",
	[types.MONITORING_INVOICE_FILTER]: "filter",
	[types.MONITORING_VEHICLES]: "vehicles",
};

const actions = {
	[types.MONITORING_PERIOD]: ({ commit }, period) => {
		commit(types.MONITORING_PERIOD, period);
	},
	[types.MONITORING_OPERATION]: ({ commit }, operation) => {
		commit(types.MONITORING_OPERATION, operation);
	},
	[types.MONITORING_OPERATION_TYPES]: async ({ commit }) => {
		const operationTypes = await loadOperationTypes.run(true);
		commit(types.MONITORING_OPERATION_TYPES, operationTypes);
	},
	[types.MONITORING_OPERATION_GUARANTEES]: async ({ commit }) => {
		const operationGuarantees = await loadOperationGuarantees.run(true);
		commit(types.MONITORING_OPERATION_GUARANTEES, operationGuarantees);
	},
	[types.MONITORING_INVOICE_FILTER]: ({ commit }, filter) => {
		commit(types.MONITORING_INVOICE_FILTER, filter);
	},
	[types.MONITORING_VEHICLES]: async ({ commit }) => {
		const vehicles = await loadVehicles.run(true);
		commit(types.MONITORING_VEHICLES, vehicles);
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
