import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import { setInterceptors } from "@/plugins/axios";

const formalizationApi = axios.create({
	baseURL: process.env.VUE_APP_A55_FORMALIZATION_API,
});
const httpResourcePendency = "pendencies";

setInterceptors(formalizationApi);

const state = {
	pendencies: [],
};

const getters = {
	[types.FORMALIZATION_PENDENCY_LIST]: "pendencies",
};

const mutations = {
	[types.FORMALIZATION_PENDENCY_LIST]: "pendencies",
};

const actions = {
	[types.FORMALIZATION_PENDENCY_LIST]: async (
		{ commit },
		{ clientProcessId }
	) => {
		const params = {
			clientProcessId,
		};
		const response = await formalizationApi.get(
			`/api/${httpResourcePendency}`,
			{ params }
		);
		const results = response.data.results;
		commit(types.FORMALIZATION_PENDENCY_LIST, results);
		return response;
	},
	[types.FORMALIZATION_PENDENCY_CREATE]: async (ctx, { payload }) => {
		return formalizationApi.post(`/api/${httpResourcePendency}`, payload);
	},
	[types.FORMALIZATION_PENDENCY_UPDATE]: async (ctx, { id, payload }) => {
		return formalizationApi.patch(
			`/api/${httpResourcePendency}/${id}`,
			payload
		);
	},
	[types.FORMALIZATION_PENDENCY_DELETE]: async (ctx, { id }) => {
		return formalizationApi.delete(`/api/${httpResourcePendency}/${id}`);
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
