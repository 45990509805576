import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { NotFoundError, UnexpectedError } from "@/core/errors";

export const remoteLoadClientProcess = (HttpClient, baseUrl) => ({
	run: async (id) => {
		const response = await HttpClient.request({
			method: HttpMethod.GET,
			url: `${baseUrl}/clientProcess/${id}`,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return response.body;

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError(`Processo com o id ${id} não encontrado!`);

			default:
				throw new UnexpectedError(
					`Ocorreu um erro inesperado ao buscar processo id ${id}`
				);
		}
	},
});
