import { remoteDeleteProcurator } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const procuratorDelete = gql`
	mutation deleteProcurator($procuratorIdGraphql: ID) {
		deleteProcurator(procuratorIdGraphql: $procuratorIdGraphql) {
			procurator {
				id
			}
		}
	}
`;

export const makeRemoteDeleteProcurator = () =>
	remoteDeleteProcurator(clients["client-manager"], procuratorDelete);
