import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { UnexpectedError } from "@/core/errors";

export const remoteLoadRenegotiationStatus = (httpClient, baseUrl) => ({
	run: async () => {
		const response = await httpClient.request({
			url: `${baseUrl}/renegotiation-status`,
			method: HttpMethod.GET,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK:
				return response.body;

			default:
				throw new UnexpectedError("Erro inesperado ao carregar status!");
		}
	},
});
