/* eslint-disable */
import { convertFromCents } from "@/core/money";
import bankCodes from "@/plugins/bankCodes.json";

const tz = "T00:00-03:00";

const getDayWord = (quantity) => (quantity !== 1 ? "dias" : "dia");
const getMonthWord = (quantity) => (quantity !== 1 ? "meses" : "mês");

const mapDocumentGroups = (documentGroups) =>
	documentGroups
		.filter((groupName) => !groupName.includes("naoassina"))
		.map((groupName) =>
			groupName.toUpperCase().replace("_ASSINA", "").replace("_", " ")
		);

const formatDate = (value, options = null) => {
	const formatter = new Intl.DateTimeFormat("pt-BR", {
		dateStyle: "long",
		...options,
	});
	return formatter.format(value);
};

const formatPercentage = (value, fractionDigits = 2) =>
	value.toFixed(fractionDigits).replace(".", ",") + "%";

const formatMoney = (value, options = null) => {
	return new Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
		...options,
	}).format(value);
};

const formatPhone = (value) => {
	const regex =
		value.length === 11 ? /(\d{2})(\d{5})(\d{4})/ : /(\d{2})(\d{4})(\d{4})/;
	return value.replace(regex, "($1) $2-$3");
};

const formatDocument = (documentNumber) => {
	let regex;
	let replacer;

	if (documentNumber.length === 11) {
		regex = /(\d{3})(\d{3})(\d{3})(\d{2})/;
		replacer = "$1.$2.$3-$4";
	} else {
		regex = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
		replacer = "$1.$2.$3/$4-$5";
	}
	return documentNumber.replace(regex, replacer);
};

const cleanDocument = (formattedDocumentNumber) =>
	formattedDocumentNumber.replace(/\.|-|\//g, "");

const formatZipcode = (value) => {
	const regex = /(\d{5})(\d{3})/;
	return value.replace(regex, "$1-$2");
};

const CUB = "Comunhão Universal de Bens";

const marriageRegimeMap = {
	"Nao Ha": "Não Aplicável",
	"Comunhao Univesal de Bens": CUB,
	"Comunhao Universal de Bens": CUB,
	"Comunhao Parcial de Bens": "Comunhão Parcial de Bens",
	"Separacao total de Bens": "Separação total de Bens",
	"Participacao final nos aquestos": "Participação final nos aquestos",
};

const mapPerson = (person, isPep = false) => {
	const baseProps = {
		name: person.name.toUpperCase(),
		taxId: formatDocument(person.taxId),
		pepOrganization: person.pepOrganization,
		pepPeriod: person.pepPeriod,
		pepPosition: person.pepPosition,
	};

	if (isPep) {
		return baseProps;
	}

	const [idEmitter, idEmitterUF] = (person.idEmitter || "-").split("-");
	const marriageRegimeDescription =
		marriageRegimeMap[person.marriageRegime?.description];

	return {
		...person,
		...baseProps,
		birthDate: person.birthDate
			? formatDate(new Date(person.birthDate + tz), {
					dateStyle: "short",
			  })
			: null,
		idEmitter,
		idEmitterUF,
		idEmissionDate: person.idEmissionDate
			? formatDate(new Date(person.idEmissionDate + tz), {
					dateStyle: "short",
			  })
			: null,
		monthlyIncome:
			typeof person.monthlyIncome === "number"
				? formatMoney(convertFromCents(person.monthlyIncome))
				: null,
		netWorth:
			typeof person.netWorth === "number"
				? formatMoney(convertFromCents(person.netWorth))
				: null,
		pepRelationships:
			person.pepRelationships?.edges.map(({ node }) =>
				mapPepRelationship(node)
			) || [],
		spouse: person.spouse ? mapPerson(person.spouse) : null,
		phone: person.phone ? formatPhone(person.phone) : null,
		personalContactInfo: {
			...person.personalContactInfo,
			zipCode: formatZipcode(person.personalContactInfo?.zipCode || ""),
			phone: formatPhone(person.personalContactInfo?.phone || ""),
		},
		marriageRegime: person.marriageRegime
			? {
					...person.marriageRegime,
					description: marriageRegimeDescription,
			  }
			: null,
		maritalStatus: person.maritalStatus
			? {
					...person.maritalStatus,
					description: person.maritalStatus.statusDescription,
			  }
			: null,
		showSpouseDetails:
			marriageRegimeDescription ===
				marriageRegimeMap["Separacao total de Bens"] ||
			!["Solteiro(a)", "Divorciado(a)"].includes(
				person.maritalStatus?.statusDescription
			),
	};
};

const mapRepresentatives = ({
	representative,
	clientProcess,
	getPersonDocumentGroups,
}) => {
	return {
		...representative,
		documentGroups: mapDocumentGroups(
			getPersonDocumentGroups.run(
				cleanDocument(representative.representative.taxId),
				clientProcess
			)
		),
		person: {
			...mapPerson(representative.representative),
			pepRelationships:
				representative.representative?.pepRelationships.edges.map(({ node }) =>
					mapPepRelationship(node)
				),
		},
		startDate: representative.startDate
			? formatDate(new Date(representative.startDate + tz), {
					dateStyle: "short",
			  })
			: null,
	};
};

const mapPepRelationship = (pepRelationship) =>
	pepRelationship.person2
		? {
				...mapPerson(pepRelationship.person2, true),
				relationshipType: pepRelationship.relationshipType,
		  }
		: null;

export const saveCompanyProfileToFile = (
	fileRepository,
	getPeopleRepository,
	getPersonDocumentGroups
	// simulateOperation
) => ({
	run: async ({ clientProcess, company, transaction }) => {
		const tranche = transaction.currentVersion.trancheSet?.edges?.length
			? transaction.currentVersion.trancheSet.edges[0].node
			: null;
		const deadline = transaction.currentVersion.tenor;
		const requestedAmount = transaction.currentVersion.maxExposure;
		const tacAmount =
			(tranche.frontendFee * 100 * (requestedAmount * 100)) / Math.pow(100, 3);

		// const operationSimulation = await simulateOperation.run({
		// 	amounts: {
		// 		otherExpenses: 0,
		// 		requested: requestedAmount,
		// 		tac: tacAmount,
		// 	},
		// 	client: {
		// 		document: {
		// 			type: "cnpj",
		// 			number: company.cnpj,
		// 		},
		// 		name: company.registeredName,
		// 	},
		// 	grace: tranche.grace || 0,
		// 	percentages: {
		// 		interest: transaction.currentVersion.minimumInterestRate,
		// 		tac: tranche.frontendFee,
		// 	},
		// 	firstDueDate: tranche.fixedAmortizationDate,
		// 	deadline,
		// });

		const additionalData = {
			operationType: deadline > 1 ? "PMT" : "Bullet",
			cet: {
				annual: "indisponível", //formatPercentage(operationSimulation.percentages.cet.annual),
				monthly: "indisponível", //formatPercentage(operationSimulation.percentages.cet.monthly),
			},
			todayDate: formatDate(new Date()),
		};

		const allRepresentatives = company.clientRepresentatives.map(
			(representative) =>
				mapRepresentatives({
					representative,
					clientProcess,
					getPersonDocumentGroups,
				})
		);

		const representatives = allRepresentatives.filter(
			({ percentageOwned }) => percentageOwned > 0
		);

		const directors = allRepresentatives.filter(
			({ isSigner, percentageOwned }) => isSigner && percentageOwned <= 0
		);

		const mappedGuarantors = tranche.trancheguarantorSet.edges.map(
			({ node }) => ({
				name: node.guarantorName,
				taxId: formatDocument(node.guarantorTaxId),
				percentageOwned: node.percentage,
			})
		);

		const guarantorsTaxIdsToKeep = mappedGuarantors
			.filter(
				(guarantor) =>
					!representatives.some(
						(representative) => representative.person.taxId === guarantor.taxId
					)
			)
			.map((guarantor) => cleanDocument(guarantor.taxId));

		const remainingGuarantors = (
			await getPeopleRepository.run(guarantorsTaxIdsToKeep)
		).map((guarantor) => ({
			person: mapPerson(guarantor),
			documentGroups: mapDocumentGroups(
				getPersonDocumentGroups.run(
					cleanDocument(guarantor.taxId),
					clientProcess
				)
			),
			percentageOwned:
				mappedGuarantors.find(
					(mappedGuarantor) =>
						cleanDocument(mappedGuarantor.taxId) === guarantor.taxId
				)?.percentageOwned || 0,
		}));

		const proposalGuarantors = mappedGuarantors
			.map((mappedGuarantor) => {
				const foundGuarantor = remainingGuarantors.find(
					(guarantor) => guarantor.person.taxId === mappedGuarantor.taxId
				);

				if (foundGuarantor) {
					return foundGuarantor;
				}

				return representatives.find(
					(representative) =>
						representative.person.taxId === mappedGuarantor.taxId
				);
			})
			.filter((guarantor) => !!guarantor);
		const proposalAnuents = proposalGuarantors
			.filter((guarantor) =>
				["Comunhão Parcial de Bens", CUB].includes(
					guarantor.person.marriageRegime?.description
				)
			)
			.map((guarantor) => guarantor.person.spouse);

		const procurators = company.procurators.map((procurator) => ({
			...procurator,
			person: mapPerson(procurator.person),
			documentGroups: mapDocumentGroups(
				getPersonDocumentGroups.run(
					cleanDocument(procurator.person.taxId),
					clientProcess
				)
			),
			percentageOwned: procurator.percentageOwned || 0,
		}));

		const politicallyExposedPeople = [
			...representatives,
			...remainingGuarantors,
			...procurators,
		].filter(({ person }) => person.pep);

		const parsedcompany = {
			...company,
			socialCapital: formatMoney(convertFromCents(company.socialCapital || 0)),
			averageMonthlyRevenue: formatMoney(
				convertFromCents(
					company.averageMonthlyRevenue ||
						company.averageAnnualRevenue / 12 ||
						0
				)
			),
			averageAnnualRevenue: formatMoney(
				convertFromCents(company.averageAnnualRevenue || 0)
			),
			netAssetsValue: formatMoney(
				convertFromCents(company.netAssetsValue || 0)
			),
			secondaryActivities: company.secondaryActivities || [],
			foundingDate: company.foundingDate
				? formatDate(new Date(company.foundingDate + tz), {
						dateStyle: "short",
				  })
				: "",
			zipCode: company.zipCode && formatZipcode(company.zipCode),
			phone: company.phone && formatPhone(company.phone),
			directors,
			procurators,
			representatives,
			remainingGuarantors,
			proposalGuarantors,
			proposalAnuents,
			politicallyExposedPeople,
			socioEnvironmentalRisk: company.riscoSocioAmbiental,
			parentCompanies:
				company.parentCompanies?.map((company) => ({
					...company,
					parentCompany: {
						...company.parentCompany,
						foundingDate: company.parentCompany?.foundingDate
							? formatDate(new Date(company.parentCompany.foundingDate + tz), {
									dateStyle: "short",
							  })
							: null,
						phone: formatPhone(company.parentCompany?.phone || ""),
					},
				})) || [],
		};

		const parsedTransaction = {
			...transaction,
			bankCode: transaction.bankName,
			bankName: bankCodes.find((bank) => bank.code === transaction.bankName)
				?.name,
			currentVersion: {
				...transaction.currentVersion,
				covenantType:
					transaction.currentVersion.maxExposure < 500000 // verify: it's not in cents
						? "Aval"
						: "Aval + Conta Vinculada",
				maxExposure: formatMoney(transaction.currentVersion.maxExposure || 0), // verify: it's not in cents
				minimumInterestRate:
					formatPercentage(transaction.currentVersion.minimumInterestRate) +
					" a.m.",
				prepaymentFeeValue: formatPercentage(
					transaction.currentVersion.prepaymentFeeValue
				),
				tranche: tranche
					? {
							...tranche,
							dueIn: formatDate(new Date(tranche.dueIn + tz), {
								dateStyle: "short",
							}),
							frontendFee: formatPercentage(tranche.frontendFee),
					  }
					: {},
			},
		};

		const proposalInfos = [
			`Instrumento da Operação: Cédula de Crédito Bancário (CCB)`,
			`Volume da Operação: ${parsedTransaction.currentVersion.maxExposure}`,
			`Custo Operacional: ${parsedTransaction.currentVersion.tranche.frontendFee}`,
			`Prazo: ${parsedTransaction.currentVersion.tenor} ${getMonthWord(
				parsedTransaction.currentVersion.tenor
			)}`,
			`Carência: ${parsedTransaction.currentVersion.tranche.grace} ${getDayWord(
				parsedTransaction.currentVersion.tranche.grace
			)}`,
			`Tipo de Operação: ${additionalData.operationType}`,
			`Taxa de Juros: ${parsedTransaction.currentVersion.minimumInterestRate}`,
			`CET Aproximada: ${additionalData.cet.monthly}`,
			`Data Prevista de Desembolso: ${parsedTransaction.currentVersion.tranche.dueIn}`,
			`Garantia: ${parsedTransaction.currentVersion.covenantType}`,
			`Avalista${
				parsedcompany.proposalGuarantors.length ? "s" : ""
			}: ${parsedcompany.proposalGuarantors
				.map(({ person }) => person.name)
				.join(", ")}`,
			`Anuente${
				parsedcompany.proposalAnuents.length ? "s" : ""
			}: ${parsedcompany.proposalAnuents.map(({ name }) => name).join(", ")}`,
			`Tarifa de Liquidação Antecipada:  ${parsedTransaction.currentVersion.prepaymentFeeValue}`,
			"Pagamento das parcelas: Boleto (em casos excepcionais a a55 poderá encaminhar instrução de TED)",
		];

		if (parsedTransaction.currentVersion.maxExposure > 500000) {
			proposalInfos.push(
				"Acesso de visualização das contas bancárias necessário"
			);
		}

		const companyName = parsedcompany.name ? `-${parsedcompany.name}` : "";
		const fileName = `ficha-cadastral${companyName}`
			.toLowerCase()
			.replace(/\s/gi, "-")
			.replace(/\./g, "");

		return fileRepository.save({
			additionalData,
			company: parsedcompany,
			fileName,
			proposalInfos,
			transaction: parsedTransaction,
		});
	},
});
/* eslint-enable */
