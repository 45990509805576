import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { UnexpectedError } from "@/core/errors";

export const remoteLoadPartners = (httpClient, baseUrl) => ({
	run: async (input = {}) => {
		const query = {};

		if (input.metadata?.limit) {
			query.limit = input.metadata.limit;
		}

		if (input.metadata?.page) {
			query.page = input.metadata.page;
		}

		const response = await httpClient.request({
			method: HttpMethod.GET,
			url: `${baseUrl}/partner`,
			query,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				const { results, ...metadata } = response.body;
				const data = results.map(
					({
						active_integration,
						fantasy_name,
						hubspot_orig,
						partner_type,
						...partner
					}) => ({
						activeIntegration: active_integration,
						fantasyName: fantasy_name,
						hubspotOrigination: hubspot_orig,
						partnerType: partner_type,
						...partner,
					})
				);

				return { data, metadata };
			}

			default:
				throw new UnexpectedError("Erro ao buscar parceiros");
		}
	},
});
