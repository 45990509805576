import * as types from "@/store/types";
import shortly from "vuex-shortly";

const state = {
	showNotification: true,
	repaidOperationsPageSize: 15,
	ongoingOperationsPageSize: 15,
};

const getters = {
	[types.CONFIG_SHOW_NOTIFICATION]: "showNotification",
	[types.CONFIG_REPAID_OPERATIONS_PAGE_SIZE]: "repaidOperationsPageSize",
	[types.CONFIG_ONGOING_OPERATIONS_PAGE_SIZE]: "ongoingOperationsPageSize",
};

const mutations = {
	[types.CONFIG_SHOW_NOTIFICATION]: "showNotification",
	[types.CONFIG_REPAID_OPERATIONS_PAGE_SIZE]: "repaidOperationsPageSize",
	[types.CONFIG_ONGOING_OPERATIONS_PAGE_SIZE]: "ongoingOperationsPageSize",
};

const actions = {
	[types.CONFIG_ONGOING_OPERATIONS_PAGE_SIZE]: ({ commit }, pageSize) => {
		commit(types.CONFIG_ONGOING_OPERATIONS_PAGE_SIZE, pageSize);
	},
	[types.CONFIG_REPAID_OPERATIONS_PAGE_SIZE]: ({ commit }, pageSize) => {
		commit(types.CONFIG_REPAID_OPERATIONS_PAGE_SIZE, pageSize);
	},
	[types.CONFIG_SHOW_NOTIFICATION]: ({ commit }, show) => {
		commit(types.CONFIG_SHOW_NOTIFICATION, show);
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
