import gql from "graphql-tag";

const query = gql`
	query ($operation_Client_Cnpj: String) {
		allInstrument(operation_Client_Cnpj: $operation_Client_Cnpj) {
			edges {
				node {
					id
					status {
						status
					}
					contractNumber
				}
			}
		}
	}
`;

export const remoteLoadContract = (graphQLClient) => ({
	run: async (operationClientCnpj) => {
		const response = await graphQLClient.query({
			query,
			variables: { operation_Client_Cnpj: operationClientCnpj },
		});

		return response.data.allInstrument.edges
			.map((it) => ({
				id: it.node?.contractNumber,
				cnpj: operationClientCnpj,
				active: it.node?.status?.status.toLowerCase() === "ongoing",
			}))
			.filter((it) => it.active);
	},
});
