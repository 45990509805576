export const remoteCreateOrUpdateParentCompany = (
	createOrUpdateCompany,
	createCompanyRelationship
) => ({
	run: async (profile, companyData, childCompanyId) => {
		const client = await createOrUpdateCompany.run(profile, companyData);

		return createCompanyRelationship.run(
			client.cnpj,
			childCompanyId,
			profile.percentageOwned
		);
	},
});
