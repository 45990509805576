import { getDocumentSchema } from "@/core/usecases/process";

export const remoteValidateAllDocuments = (loadClientProcess) => ({
	run: async (documentProcessId) => {
		const { documents } = await loadClientProcess.run(documentProcessId);

		const schema = getDocumentSchema();

		const flattenedSchema = {};

		Object.entries(schema).forEach(([key, value]) => {
			if (value.type != undefined) {
				flattenedSchema[key] = value;
			} else {
				Object.entries(value).forEach(([innerKey, innerValue]) => {
					flattenedSchema[innerKey] = innerValue;
				});
			}
		});

		const validateDocuments = (docs, flatSchema) =>
			docs
				.map((doc) => {
					const docKey = doc.document_key;

					doc.type = docKey.includes("/") ? docKey.split("/")[1] : docKey;

					const isRequired = flatSchema?.[doc.type]?.required;
					if (isRequired && !Object.keys(doc?.files).length) {
						return {
							taxId: doc.holder_tax_id,
							name: "Documentos",
							path: doc.holder_tax_id,
							errors: [doc.label],
						};
					}
					return {};
				})
				.filter((obj) => obj.name);

		return validateDocuments(documents, flattenedSchema);
	},
});
