import Vue from "vue";
import router from "@/router/";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const disallowedModesForRelease = ["development", "test"];

if (!disallowedModesForRelease.includes(process.env.VUE_APP_MODE)) {
	Sentry.init({
		Vue,
		environment: process.env.VUE_APP_MODE,
		dsn: "https://023e058de3f442ff915152ae54b81f43@o290375.ingest.sentry.io/6418976",
		ignoreErrors: ["ResizeObserver loop limit exceeded"],
		trackComponents: true,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
		],
		tracesSampleRate: 1.0,
	});
}
