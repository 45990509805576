import { HttpMethod, HttpStatusCode } from "@/core/adapters";

export const remoteLoadPartnership = (httpClient, baseUrl) => ({
	run: async (input = {}) => {
		const query = {};
		let url = input.id
			? `${baseUrl}/partner?id=${input.id}`
			: `${baseUrl}/partner`;

		if (input.metadata?.limit) {
			query.limit = input.metadata.limit;
		}

		if (input.metadata?.page) {
			query.page = input.metadata.page;
		}

		const response = await httpClient.request({
			method: HttpMethod.GET,
			url,
			query,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				const { results, ...metadata } = response.body;

				return { results, metadata };
			}

			default:
				throw new String("Erro ao buscar os parceiros.");
		}
	},
});
