import store from "@/store";
import * as types from "@/store/types";

import { clearDocument, filterKeysByModel } from "@/core/utils";
import { getDefaultModel, parseNaturalPerson } from "@/core/usecases/people";

export const storeNaturalPerson = async (args) => {
	const { apollo, document, query } = args;
	const variables = { taxId: clearDocument(document) };

	const response = await apollo
		.query({
			client: "client-manager",
			query,
			fetchPolicy: "no-cache",
			errorPolicy: "ignore",
			notifyOnNetworkStatusChange: true,
			variables,
		})
		.then(
			(res) =>
				res.data?.personDetails?.edges?.[0]?.node || {
					taxId: clearDocument(document),
				}
		);

	storePersonProfile(args, response);
	storePersonData(response);
};

const storePersonProfile = (args, response) => {
	const { boardmembers, document, relatedTaxId } = args;

	const doc = clearDocument(document);
	const foundMember = boardmembers.find(({ taxId }) => taxId === doc) || {};

	const defaultModel = getDefaultModel("personProfile");
	const payload = filterKeysByModel(
		{
			...defaultModel,
			...foundMember,
			...response,
			clientRepresentativeId: foundMember?.id,
			percentageOwned: foundMember?.percentage,
			cpf: doc,
			taxId: doc,
			relatedTaxId,
		},
		defaultModel
	);

	store.dispatch(types.PEOPLE_NATURAL_PROFILE, payload);
};

export const storePersonData = (person) => {
	if (!person) return;
	const defaultModel = getDefaultModel("naturalPerson");
	const model = {
		...defaultModel,
		...parseNaturalPerson(person),
		shouldStoreComplementary: true,
		shouldStorePep: true,
	};

	store.dispatch(types.PEOPLE_NATURAL_PERSON, model);
};
