import { formatSpouseModel } from "@/core/usecases/people";

export const remoteSubmitMaritalData = (
	graphQLClient,
	getPersonByDocument,
	createSpouseMutation,
	updateSpouseMutation,
	maritalStatusUpdateMutation
) => ({
	run: async (model, person, spouseModel, isMarried) => {
		const { maritalStatusIdGraphql, marriageRegime } = model;

		if (isMarried) {
			const searchedSpouse = getPersonByDocument.run(spouseModel.taxId);
			const spouse = {
				...spouseModel,
				...searchedSpouse,
			};

			const spouseExists = !!spouse?.id;
			const [spouseMutation, responseKey] = spouseExists
				? [updateSpouseMutation, "updatePerson"]
				: [createSpouseMutation, "createPerson"];

			const spouseVariables = formatSpouseModel(spouse);

			const spouseId = await graphQLClient
				.mutate({
					mutation: spouseMutation,
					variables: { ...spouseVariables },
				})
				.then((res) => res?.data?.[responseKey]?.person?.id);

			if (!spouseId) throw Error("Failed to create/update spouse");

			await graphQLClient.mutate({
				mutation: maritalStatusUpdateMutation,
				variables: {
					personIdGraphql: person.id,
					maritalStatusIdGraphql: maritalStatusIdGraphql?.id,
					spouseIdGraphql: spouseId,
					marriageRegimeId: atob(marriageRegime?.id).split(":")[1],
				},
			});

			await graphQLClient.mutate({
				mutation: maritalStatusUpdateMutation,
				variables: {
					personIdGraphql: spouseId,
					maritalStatusIdGraphql: maritalStatusIdGraphql?.id,
					spouseIdGraphql: person.id,
					marriageRegimeId: atob(marriageRegime?.id).split(":")[1],
				},
			});
		} else {
			await graphQLClient.mutate({
				client: "client-manager",
				mutation: maritalStatusUpdateMutation,
				variables: {
					personIdGraphql: person.id,
					maritalStatusIdGraphql: maritalStatusIdGraphql?.id,
					spouseIdGraphql: null,
					marriageRegimeId: 1,
				},
			});
		}
	},
});
