import shortly from "vuex-shortly";
import * as types from "@/store/types";
import { getModelKeyMutator, filterKeysByModel } from "@/core/utils";

import {
	getProcuratorAttributes,
	getDefaultModel,
	getGuarantorAttributes,
	parseNaturalPerson,
} from "@/core/usecases/people";

const defaultState = {
	personProfile: getDefaultModel("personProfile"),
	naturalPerson: getDefaultModel("naturalPerson"),
	companyProfile: getDefaultModel("companyProfile"),
	companyContact: getDefaultModel("companyContact"),
	spouse: getDefaultModel("spouse"),
	naturalPersonComplementary: getDefaultModel("naturalPersonComplementary"),
	pepForm: getDefaultModel("pepForm"),
	pepRelationsForm: [],
	companyProcurators: [],
	companyGuarantors: [],
	profilesList: [],
	peopleBoardVisited: false,
};

const getters = {
	[types.PEOPLE_SPOUSE]: "spouse",
	[types.PEOPLE_NATURAL_PERSON]: "naturalPerson",
	[types.PEOPLE_NATURAL_PROFILE]: "personProfile",
	[types.PEOPLE_COMPANY_PROFILE]: "companyProfile",
	[types.PEOPLE_COMPANY_CONTACT]: "companyContact",
	[types.PEOPLE_COMPANY_PROCURATORS]: "companyProcurators",
	[types.PEOPLE_COMPANY_GUARANTORS]: "companyGuarantors",
	[types.PEOPLE_NATURAL_PERSON_COMPLEMENTARY]: "naturalPersonComplementary",
	[types.PEOPLE_PEP_DATA]: "pepForm",
	[types.PEOPLE_PROFILES_LIST]: "profilesList",
	[types.PEOPLE_BOARD_VISITED]: "peopleBoardVisited",
};

const mutations = {
	[types.PEOPLE_BOARD_VISITED]: "peopleBoardVisited",
	[types.PEOPLE_PROFILES_LIST]: "profilesList",
	[types.PEOPLE_SPOUSE]: "spouse",
	[types.PEOPLE_NATURAL_PERSON]: "naturalPerson",
	[types.PEOPLE_NATURAL_PROFILE]: "personProfile",
	[types.PEOPLE_NATURAL_PROFILE_KEY]: getModelKeyMutator("personProfile"),
	[types.PEOPLE_COMPANY_PROFILE]: "companyProfile",
	[types.PEOPLE_COMPANY_PROFILE_KEY]: getModelKeyMutator("companyProfile"),
	[types.PEOPLE_COMPANY_CONTACT]: "companyContact",
	[types.PEOPLE_COMPANY_PROCURATORS]: "companyProcurators",
	[types.PEOPLE_COMPANY_GUARANTORS]: "companyGuarantors",
	[types.PEOPLE_PEP_DATA]: "pepForm",
	[types.PEOPLE_NATURAL_PERSON_COMPLEMENTARY]: "naturalPersonComplementary",
	[types.PEOPLE_ADD_COMPANY_PROCURATOR]: (state, payload) => {
		state.companyProcurators.push(payload);
	},
	[types.PEOPLE_REMOVE_COMPANY_PROCURATOR]: (state, payload) => {
		const index = state.companyProcurators.findIndex(
			(procurator) => procurator.id === payload.id
		);

		if (index > -1) {
			state.companyProcurators.splice(index, 1);
		}
	},
	[types.PEOPLE_ADD_COMPANY_GUARANTOR]: (state, payload) => {
		state.companyGuarantors.push(payload);
	},
	[types.PEOPLE_REMOVE_COMPANY_GUARANTOR]: (state, payload) => {
		const index = state.companyGuarantors.findIndex(
			(guarantor) => guarantor.taxId === payload.taxId
		);

		if (index > -1) {
			state.companyGuarantors.splice(index, 1);
		}
	},
};

const actions = {
	[types.PEOPLE_BOARD_VISITED]: ({ commit }) => {
		commit(types.PEOPLE_BOARD_VISITED, true);
	},
	[types.PEOPLE_PROFILES_LIST]: ({ commit }, payload) => {
		commit(types.PEOPLE_PROFILES_LIST, payload);
	},
	[types.PEOPLE_PEP_DATA]: ({ commit }, payload) => {
		commit(types.PEOPLE_PEP_DATA, payload);
	},

	[types.PEOPLE_SPOUSE]: ({ commit }, payload) => {
		commit(types.PEOPLE_SPOUSE, payload);
	},
	[types.PEOPLE_NATURAL_PERSON_COMPLEMENTARY]: ({ commit }, payload) => {
		commit(types.PEOPLE_NATURAL_PERSON_COMPLEMENTARY, payload);
	},
	[types.PEOPLE_NATURAL_PERSON]: ({ commit }, payload) => {
		const personPayload = filterKeysByModel(
			payload,
			getDefaultModel("naturalPerson")
		);

		commit(types.PEOPLE_NATURAL_PERSON, personPayload);

		if (payload?.shouldStoreComplementary) {
			const complementary = filterKeysByModel(
				payload,
				getDefaultModel("naturalPersonComplementary")
			);

			commit(types.PEOPLE_NATURAL_PERSON_COMPLEMENTARY, complementary);
		}

		if (payload?.shouldStorePep) {
			const pepModel = filterKeysByModel(payload, getDefaultModel("pepForm"));
			commit(types.PEOPLE_PEP_DATA, pepModel);
		}

		if (payload.spouse) {
			commit(types.PEOPLE_SPOUSE, {
				...getDefaultModel("spouse"),
				...parseNaturalPerson(payload.spouse),
			});
		} else {
			commit(types.PEOPLE_SPOUSE, {
				...getDefaultModel("spouse"),
			});
		}
	},
	[types.PEOPLE_NATURAL_PROFILE]: ({ commit, state }, payload) => {
		commit(types.PEOPLE_NATURAL_PROFILE, {
			...payload,
			...getProcuratorAttributes(payload.taxId, state.companyProcurators),
			...getGuarantorAttributes(payload.taxId, state.companyGuarantors),
		});
	},
	[types.PEOPLE_ADD_COMPANY_PROCURATOR]: ({ commit }, payload) => {
		commit(types.PEOPLE_ADD_COMPANY_PROCURATOR, payload);
	},
	[types.PEOPLE_REMOVE_COMPANY_PROCURATOR]: ({ commit }, payload) => {
		commit(types.PEOPLE_REMOVE_COMPANY_PROCURATOR, payload);
	},
	[types.PEOPLE_ADD_COMPANY_GUARANTOR]: ({ commit }, payload) => {
		commit(types.PEOPLE_ADD_COMPANY_GUARANTOR, payload);
	},
	[types.PEOPLE_REMOVE_COMPANY_GUARANTOR]: ({ commit }, payload) => {
		commit(types.PEOPLE_REMOVE_COMPANY_GUARANTOR, payload);
	},
	[types.PEOPLE_NATURAL_PROFILE_KEY]: ({ commit }, input) => {
		commit(types.PEOPLE_NATURAL_PROFILE_KEY, input);
	},
	[types.PEOPLE_COMPANY_PROFILE]: ({ commit }, payload) => {
		commit(types.PEOPLE_COMPANY_PROFILE, payload);
	},
	[types.PEOPLE_COMPANY_PROFILE_KEY]: ({ commit }, input) => {
		commit(types.PEOPLE_COMPANY_PROFILE_KEY, input);
	},
	[types.PEOPLE_COMPANY_CONTACT]: ({ commit }, payload) => {
		commit(types.PEOPLE_COMPANY_CONTACT, payload);
	},
	[types.PEOPLE_COMPANY_PROCURATORS]: ({ commit }, payload) => {
		commit(types.PEOPLE_COMPANY_PROCURATORS, payload);
	},
	[types.PEOPLE_COMPANY_GUARANTORS]: ({ commit }, payload) => {
		commit(types.PEOPLE_COMPANY_GUARANTORS, payload);
	},
};

export default shortly({
	state: defaultState,
	getters,
	mutations,
	actions,
});
