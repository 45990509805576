import { remoteDeleteRepresentative } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const deleteRepresentativeMutation = gql`
	mutation deleteClientRepresentative($clientRepresentativeIdGraphql: ID) {
		deleteClientRepresentative(
			clientRepresentativeIdGraphql: $clientRepresentativeIdGraphql
		) {
			message
		}
	}
`;

export const makeRemoteDeleteRepresentative = () =>
	remoteDeleteRepresentative(
		clients["client-manager"],
		deleteRepresentativeMutation
	);
