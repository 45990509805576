import { remoteDeleteCompany } from "@/core/usecases/people";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const deleteCompanyMutation = gql`
	mutation deleteCompanyRelationship(
		$childCompanyTaxId: String!
		$parentCompanyTaxId: String!
	) {
		deleteCompanyRelationship(
			childCompanyTaxId: $childCompanyTaxId
			parentCompanyTaxId: $parentCompanyTaxId
		) {
			message
		}
	}
`;

export const makeRemoteDeleteCompany = () =>
	remoteDeleteCompany(clients["client-manager"], deleteCompanyMutation);
