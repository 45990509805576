import {
	InvalidModelError,
	UnexpectedError,
	DuplicationError,
} from "@/core/errors";
import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { PROPOSAL_STATUS, createProposalEntity } from "@/core/entities";

export const unmapRate = ({ installments, prepaymentFee, ...rateRest }) => ({
	...rateRest,
	number_installments: installments,
	prepayment_fee: prepaymentFee,
});

export const remoteUpdateProposal = (
	httpClient,
	baseUrl,
	createRates,
	refuseProposal
) => ({
	run: async (proposal, clientRisk, refusal, oldProposal) => {
		const proposalEntity = createProposalEntity(
			proposal,
			clientRisk,
			refusal,
			oldProposal
		);
		const {
			id,
			cnpj,
			observation,
			reason_for_change,
			partner,
			productId,
			rates,
			status,
			vehicleId,
			has_escrow_account,
			guarantee,
			monthlyGuaranteeValue,
			risks_policy
		} = proposalEntity;

		const ratesToCreate = rates.filter((rate) => !rate.id);
		const ratesToUpdate = rates.filter((rate) => !!rate.id);

		const body = {
			id,
			cnpj,
			product_id: productId,
			rates: ratesToUpdate.map(unmapRate),
			status,
			has_escrow_account,
		};

		if (vehicleId) {
			body.vehicle_id = vehicleId;
		}

		if (observation) {
			body.observation = observation;
		}

		if (reason_for_change) {
			body.reason_for_change = reason_for_change;
		}

		if (partner || ["", null].includes(partner)) {
			body.partner = partner;
		}

		if (guarantee) {
			body.guarantee = guarantee;
		}
		if (monthlyGuaranteeValue) {
			body.monthly_guarantee_value = monthlyGuaranteeValue;
		}
		if (risks_policy){
			body.risks_policy = risks_policy;
		}
		const response = await httpClient.request({
			method: HttpMethod.PATCH,
			url: `${baseUrl}/proposals/update/${proposalEntity.id}`,
			body,
		});

		switch (response.statusCode) {
			case HttpStatusCode.OK: {
				if (
					oldProposal.status !== PROPOSAL_STATUS.REFUSED &&
					proposal.status === PROPOSAL_STATUS.REFUSED
				) {
					await refuseProposal.run(id, refusal);
				}

				await createRates.run(ratesToCreate, proposalEntity);

				return proposalEntity;
			}

			case HttpStatusCode.BAD_REQUEST: {
				if (
					response.body[0] ===
					"There is already an approved proposal for this cnpj"
				) {
					throw new DuplicationError(
						"Já existe uma proposta aprovada para esta empresa"
					);
				}

				const message = response.body?.status
					? "Não é possível alterar a proposta para este status"
					: "Dados da proposta inválidos";

				throw new InvalidModelError(message);
			}

			case HttpStatusCode.FORBIDDEN: {
				const message = response.body?.detail;

				throw new UnexpectedError(message);
			}

			default:
				throw new UnexpectedError("Erro inesperado ao atualizar a proposta!");
		}
	},
});
