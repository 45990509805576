/* eslint-disable sonarjs/cognitive-complexity */
const amountKeys = ["minAmount", "maxAmount"];
const percentKeys = ["minPercent", "maxPercent"];
const allErrorKeys = [
	...amountKeys,
	...percentKeys,
	"cnpj",
	"each",
	"enum",
	"maxLength",
	"minLength",
	"maxValue",
	"minValue",
	"number",
	"required",
	"status",
	"string",
];

export const getErrorMessages = (i18n, formatMoney, formatPercent) => ({
	// getAs = 'list' or 'single'
	run: async (errors, getAs = "list") => {
		const modelKeys = Object.keys(errors);
		let mappedModel = {};

		modelLoop: for (const modelKey of modelKeys) {
			const errorsKeys = Object.keys(errors[modelKey]).filter(
				(errorKey) => errorKey !== "params"
			);
			const errorMessages = [];

			for (const errorKey of errorsKeys) {
				const foundInErrorKeys = allErrorKeys.includes(errorKey);

				if (
					!foundInErrorKeys ||
					(foundInErrorKeys && Object.keys(errors[modelKey][errorKey]).length)
				) {
					const modelMessages = {
						...mappedModel?.[modelKey],
						...(await getErrorMessages(i18n, formatMoney, formatPercent).run(
							errors[modelKey],
							getAs
						)),
					};

					mappedModel = Object.keys(modelMessages).length
						? {
								...mappedModel,
								[modelKey]: modelMessages,
						  }
						: mappedModel;

					continue modelLoop;
				}

				if (errorKey === "each") {
					if (errors[modelKey]?.each?.[0]?.params) {
						mappedModel = {
							...mappedModel,
							[modelKey]: {
								...mappedModel[modelKey],
								each: await getErrorMessages(
									i18n,
									formatMoney,
									formatPercent
								).run(errors[modelKey].each, getAs),
							},
						};
					} else {
						const each = [];

						for (const nestedErrors of errors[modelKey].each) {
							each.push(
								await getErrorMessages(i18n, formatMoney, formatPercent).run(
									nestedErrors,
									getAs
								)
							);
						}

						mappedModel = {
							...mappedModel,
							[modelKey]: { ...mappedModel[modelKey], each },
						};
					}

					continue modelLoop;
				}

				const hasError = errors[modelKey][errorKey];

				if (hasError) {
					const errorParamValue = errors[modelKey]?.params?.[errorKey];

					errorMessages.push(
						i18n.t(`validations.${errorKey}`, {
							[errorKey]: amountKeys.includes(errorKey)
								? formatMoney(errorParamValue)
								: percentKeys.includes(errorKey)
								? formatPercent(errorParamValue)
								: errorParamValue,
						})
					);

					if (getAs === "single") {
						mappedModel = { ...mappedModel, [modelKey]: errorMessages[0] };
						continue modelLoop;
					}
				}
			}

			mappedModel = errorMessages.length
				? { ...mappedModel, [modelKey]: errorMessages }
				: mappedModel;
		}

		return mappedModel;
	},
});
/* eslint-enable sonarjs/cognitive-complexity */
