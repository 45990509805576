<template>
	<transition
		name="el-zoom-in-top"
		@after-enter="handleEnter"
		@after-leave="handleLeave"
	>
		<div
			v-show="visible"
			class="el-picker-panel el-date-picker el-popper"
			:class="[{ 'has-sidebar': $slots.sidebar || shortcuts }, popperClass]"
		>
			<div class="el-picker-panel__body-wrapper">
				<slot name="sidebar" class="el-picker-panel__sidebar" />
				<div v-if="shortcuts" class="el-picker-panel__sidebar">
					<button
						v-for="(shortcut, key) in shortcuts"
						:key="key"
						type="button"
						class="el-picker-panel__shortcut"
						@click="handleShortcutClick(shortcut)"
					>
						{{ shortcut.text }}
					</button>
				</div>
				<div class="el-picker-panel__body">
					<div
						v-show="currentView !== 'time'"
						class="el-date-picker__header"
						:class="{
							'el-date-picker__header--bordered':
								currentView === 'year' || currentView === 'month',
						}"
					>
						<button
							type="button"
							:aria-label="t(`el.datepicker.prevYear`)"
							class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left"
							@click="prevYear"
						/>
						<span
							class="el-date-picker__header-label"
							role="button"
							@click="showYearPicker"
							>{{ yearLabel }}</span
						>
						<button
							type="button"
							:aria-label="t(`el.datepicker.nextYear`)"
							class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right"
							@click="nextYear"
						/>
					</div>
					<div class="el-picker-panel__content">
						<date-table
							v-show="currentView === 'date'"
							:year="date"
							:value="value"
							:default-value="defaultValue ? new Date(defaultValue) : null"
							:date="date"
							:disabled-date="disabledDate"
							@pick="handleMonthPick"
						/>
						<year-table
							v-show="currentView === 'year'"
							:value="value"
							:default-value="defaultValue ? new Date(defaultValue) : null"
							:date="date"
							:disabled-date="disabledDate"
							@pick="handleYearPick"
						/>
					</div>
					<div
						v-show="footerVisible && currentView === 'date'"
						class="el-picker-panel__footer"
					>
						<el-button
							plain
							size="mini"
							class="el-picker-panel__link-btn"
							@click="confirm"
						>
							{{ t("el.datepicker.confirm") }}
						</el-button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script type="text/babel">
import {
	formatDate,
	parseDate,
	getWeekNumber,
	isDate,
	modifyDate,
	modifyTime,
	modifyWithTimeString,
	clearTime,
	prevYear,
	nextYear,
	prevMonth,
	nextMonth,
	changeYearMonthAndClampDate,
	extractDateFormat,
	extractTimeFormat,
	timeWithinRange,
} from "element-ui/src/utils/date-util";
import Clickoutside from "element-ui/src/utils/clickoutside";
import YearTable from "../basic/year-table";
import DateTable from "../basic/month-table";
import lang from "element-ui/lib/locale/lang/en";
import { t, use } from "element-ui/src/locale";

use(lang);

export default {
	components: {
		DateTable,
		YearTable,
	},
	directives: {
		Clickoutside,
	},
	mixins: [
		{
			methods: {
				t(...args) {
					return t.apply(this, args);
				},
			},
		},
	],
	data() {
		return {
			popperClass: "",
			date: new Date(),
			value: "",
			defaultValue: null, // use getDefaultValue() for time computation
			defaultTime: null,
			selectionMode: "day",
			shortcuts: "",
			visible: false,
			currentView: "date",
			disabledDate: "",
			cellClassName: "",
			selectableRange: [],
			firstDayOfWeek: 7,
			showWeekNumber: false,
			format: "",
			arrowControl: false,
			userInputDate: null,
			userInputTime: null,
		};
	},
	computed: {
		year() {
			return this.date.getFullYear();
		},

		month() {
			return this.date.getMonth();
		},

		week() {
			return getWeekNumber(this.date);
		},

		monthDate() {
			return this.date.getDate();
		},

		footerVisible() {
			return this.selectionMode === "months";
		},

		visibleDate() {
			if (this.userInputDate !== null) {
				return this.userInputDate;
			} else {
				return formatDate(this.value || this.defaultValue, this.dateFormat);
			}
		},

		yearLabel() {
			const yearTranslation = this.t("el.datepicker.year");

			if (this.currentView === "year") {
				const startYear = Math.floor(this.year / 10) * 10;
				if (yearTranslation) {
					return (
						startYear +
						" " +
						yearTranslation +
						" - " +
						(startYear + 9) +
						" " +
						yearTranslation
					);
				}
				return startYear + " - " + (startYear + 9);
			}
			return this.year;
		},

		timeFormat() {
			if (this.format) {
				return extractTimeFormat(this.format);
			} else {
				return "HH:mm:ss";
			}
		},

		dateFormat() {
			if (this.format) {
				return extractDateFormat(this.format);
			} else {
				return "yyyy-MM-dd";
			}
		},
	},
	watch: {
		value(val) {
			if (isDate(val)) {
				this.date = new Date(val);
			} else {
				this.date = this.getDefaultValue();
			}
		},

		defaultValue(val) {
			if (!isDate(this.value)) {
				this.date = val ? new Date(val) : new Date();
			}
		},

		selectionMode(newVal) {
			if (newVal === "month") {
				/* istanbul ignore next */
				if (this.currentView !== "year" || this.currentView !== "month") {
					this.currentView = "month";
				}
			} else if (newVal === "months") {
				this.currentView = "date";
			}
		},
	},
	methods: {
		handleClear() {
			this.date = this.getDefaultValue();
			this.$emit("pick", null);
		},

		emit(value, ...args) {
			if (!value) {
				this.$emit("pick", value, ...args);
			} else if (Array.isArray(value)) {
				const dates = value
					.map((date) => clearTime(date))
					.sort((a, b) => {
						const A = a.getTime();
						const B = b.getTime();
						return A - B;
					});
				this.$emit("pick", dates, ...args);
			} else {
				this.$emit("pick", clearTime(value), ...args);
			}
			this.userInputDate = null;
		},

		showMonthPicker() {
			this.currentView = "month";
		},

		showYearPicker() {
			this.currentView = "year";
		},

		prevMonth() {
			this.date = prevMonth(this.date);
		},

		nextMonth() {
			this.date = nextMonth(this.date);
		},

		prevYear() {
			if (this.currentView === "year") {
				this.date = prevYear(this.date, 10);
			} else {
				this.date = prevYear(this.date);
			}
		},

		nextYear() {
			if (this.currentView === "year") {
				this.date = nextYear(this.date, 10);
			} else {
				this.date = nextYear(this.date);
			}
		},

		handleShortcutClick(shortcut) {
			if (shortcut.onClick) {
				shortcut.onClick(this);
			}
		},

		handleMonthPick(value) {
			this.emit(value, true);
		},

		handleDatePick(value) {
			this.emit(value, true);
		},

		handleYearPick(year) {
			this.date = changeYearMonthAndClampDate(this.date, year, this.month);
			this.currentView = "date";
		},

		changeToNow() {
			// NOTE: not a permanent solution
			//       consider disable "now" button in the future
			if (
				(!this.disabledDate || !this.disabledDate(new Date())) &&
				this.checkDateWithinRange(new Date())
			) {
				this.date = new Date();
				this.emit(this.date);
			}
		},

		confirm() {
			if (this.selectionMode === "months") {
				this.emit(this.value);
			} else {
				// value were emitted in handle{Date,Time}Pick, nothing to update here
				// deal with the scenario where: user opens the picker, then confirm without doing anything
				const value = this.value
					? this.value
					: modifyWithTimeString(this.getDefaultValue(), this.defaultTime);
				this.date = new Date(value); // refresh date
				this.emit(value);
			}
		},

		resetView() {
			if (this.selectionMode === "month") {
				this.currentView = "month";
			} else if (this.selectionMode === "year") {
				this.currentView = "year";
			} else {
				this.currentView = "date";
			}
		},

		handleEnter() {
			document.body.addEventListener("keydown", this.handleKeydown);
		},

		handleLeave() {
			this.$emit("dodestroy");
			document.body.removeEventListener("keydown", this.handleKeydown);
		},

		handleKeydown(event) {
			const keyCode = event.keyCode;
			const list = [38, 40, 37, 39];
			if (this.visible) {
				if (list.indexOf(keyCode) !== -1) {
					this.handleKeyControl(keyCode);
					event.stopPropagation();
					event.preventDefault();
				}
				if (
					keyCode === 13 &&
					this.userInputDate === null &&
					this.userInputTime === null
				) {
					// Enter
					this.emit(this.date, false);
				}
			}
		},

		handleKeyControl(keyCode) {
			const mapping = {
				year: {
					38: -4,
					40: 4,
					37: -1,
					39: 1,
					offset: (date, step) => date.setFullYear(date.getFullYear() + step),
				},
				month: {
					38: -4,
					40: 4,
					37: -1,
					39: 1,
					offset: (date, step) => date.setMonth(date.getMonth() + step),
				},
				week: {
					38: -1,
					40: 1,
					37: -1,
					39: 1,
					offset: (date, step) => date.setDate(date.getDate() + step * 7),
				},
				day: {
					38: -7,
					40: 7,
					37: -1,
					39: 1,
					offset: (date, step) => date.setDate(date.getDate() + step),
				},
			};
			const mode = this.selectionMode;
			const year = 3.1536e10;
			const now = this.date.getTime();
			const newDate = new Date(this.date.getTime());
			while (Math.abs(now - newDate.getTime()) <= year) {
				const map = mapping[mode];
				map.offset(newDate, map[keyCode]);
				if (
					typeof this.disabledDate === "function" &&
					this.disabledDate(newDate)
				) {
					continue;
				}
				this.date = newDate;
				this.$emit("pick", newDate, true);
				break;
			}
		},

		handleVisibleTimeChange(value) {
			const time = parseDate(value, this.timeFormat);
			if (time && this.checkDateWithinRange(time)) {
				this.date = modifyDate(time, this.year, this.month, this.monthDate);
				this.userInputTime = null;
				this.emit(this.date, true);
			}
		},

		handleVisibleDateChange(value) {
			const date = parseDate(value, this.dateFormat);
			if (date) {
				if (
					typeof this.disabledDate === "function" &&
					this.disabledDate(date)
				) {
					return;
				}
				this.date = modifyTime(
					date,
					this.date.getHours(),
					this.date.getMinutes(),
					this.date.getSeconds()
				);
				this.userInputDate = null;
				this.resetView();
				this.emit(this.date, true);
			}
		},

		isValidValue(value) {
			return (
				value &&
				!isNaN(value) &&
				(typeof this.disabledDate === "function"
					? !this.disabledDate(value)
					: true) &&
				this.checkDateWithinRange(value)
			);
		},

		getDefaultValue() {
			// if default-value is set, return it
			// otherwise, return now (the moment this method gets called)
			return this.defaultValue ? new Date(this.defaultValue) : new Date();
		},

		checkDateWithinRange(date) {
			return this.selectableRange.length > 0
				? timeWithinRange(date, this.selectableRange, this.format || "HH:mm:ss")
				: true;
		},
	},
};
</script>
