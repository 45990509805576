import * as types from "@/store/types";
import shortly from "vuex-shortly";
import axios from "axios";
import { setInterceptors } from "@/plugins/axios";

export const monitorApi = axios.create({
	baseURL: process.env.VUE_APP_A55_MONITOR_REST,
});

export const publicIBGEApi = axios.create({
	baseURL: "https://cnae.ibge.gov.br/",
});

export const payhopApi = axios.create({
	baseURL: process.env.VUE_APP_A55_PAYHOP_API,
});

setInterceptors(monitorApi);
setInterceptors(payhopApi);

const emptyMoreInfo = {
	proposals: [],
	instruments: [],
	board: [],
};

const state = {
	options: [],
	companies: {
		data: [],
		metadata: {},
	},
	company: {
		company_detail: {},
		instrument_detail: [],
	},
	companyMoreInfo: { ...emptyMoreInfo },
	companySchedule: [],
	general: {},
	defaultTimeline: {},
	defaultRecovered: {},
	defaultCompanies: {
		data: [],
		metadata: {},
	},
};

const getters = {
	[types.DASHBOARD_FILTER_STATUS]: "options",
	[types.DASHBOARD_COMPANIES]: "companies",
	[types.DASHBOARD_COMPANY]: "company",
	[types.DASHBOARD_COMPANY_MORE_INFO]: "companyMoreInfo",
	[types.DASHBOARD_COMPANY_SCHEDULE]: "companySchedule",
	[types.DASHBOARD_GENERAL]: "general",
	[types.DASHBOARD_DEFAULT_TIMELINE]: "defaultTimeline",
	[types.DASHBOARD_DEFAULT_RECOVERED]: "defaultRecovered",
	[types.DASHBOARD_DEFAULT_COMPANIES]: "defaultCompanies",
};

const mutations = {
	[types.DASHBOARD_FILTER_STATUS]: "options",
	[types.DASHBOARD_COMPANIES]: "companies",
	[types.DASHBOARD_COMPANY]: "company",
	[types.DASHBOARD_COMPANY_MORE_INFO]: "companyMoreInfo",
	[types.DASHBOARD_COMPANY_SCHEDULE]: "companySchedule",
	[types.DASHBOARD_GENERAL]: "general",
	[types.DASHBOARD_DEFAULT_TIMELINE]: "defaultTimeline",
	[types.DASHBOARD_DEFAULT_RECOVERED]: "defaultRecovered",
	[types.DASHBOARD_DEFAULT_COMPANIES]: "defaultCompanies",
};

const actions = {
	[types.DASHBOARD_FILTER_STATUS]: async ({ commit }, options) => {
		commit(types.DASHBOARD_FILTER_STATUS, options);
	},
	[types.DASHBOARD_COMPANIES]: async ({ commit }, params) => {
		const response = await monitorApi.get("/bff/companies", {
			params: {
				...params,
				per_page: 8,
			},
		});
		commit(
			types.DASHBOARD_COMPANIES,
			response.data || {
				data: [],
				metadata: {},
			}
		);
	},
	[types.DASHBOARD_GENERAL]: async ({ commit }) => {
		const response = await monitorApi.get("/bff/general");
		commit(types.DASHBOARD_GENERAL, response.data?.data || {});
	},
	[types.DASHBOARD_DEFAULT_TIMELINE]: async ({ commit }) => {
		const response = await monitorApi.get("/bff/default");
		commit(types.DASHBOARD_DEFAULT_TIMELINE, response.data?.data || {});
	},
	[types.DASHBOARD_DEFAULT_RECOVERED]: async (
		{ commit },
		year = new Date().getFullYear
	) => {
		const response = await monitorApi.get("/bff/recovered", {
			params: {
				year,
			},
		});
		commit(types.DASHBOARD_DEFAULT_RECOVERED, response.data?.data || {});
	},
	[types.DASHBOARD_DEFAULT_COMPANIES]: async ({ commit }, paramsData) => {
		const params = {
			page: paramsData.page || 1,
			query: paramsData.query || "",
			per_page: 7,
		};

		if (paramsData.type === "executed") {
			params.legal = true;
		}

		const response = await monitorApi.get("/bff/company-default", {
			params,
		});
		commit(
			types.DASHBOARD_DEFAULT_COMPANIES,
			response.data || {
				data: [],
				metadata: {},
			}
		);
	},
	[types.DASHBOARD_COMPANY]: async ({ commit }, params) => {
		const response = await monitorApi.get("/bff/company", {
			params,
		});
		commit(types.DASHBOARD_COMPANY, response.data?.data || {});
	},
	[types.DASHBOARD_COMPANY_MORE_INFO]: async ({ commit }, tax_id) => {
		try {
			const response = await monitorApi.get(`/bff/company/more-info`, {
				params: {
					tax_id,
				},
			});

			commit(
				types.DASHBOARD_COMPANY_MORE_INFO,
				response.data?.data || { ...emptyMoreInfo }
			);
		} catch (error) {
			commit(types.DASHBOARD_COMPANY_MORE_INFO, { ...emptyMoreInfo });
			throw error;
		}
	},
	[types.DASHBOARD_DOWNLOAD_INSTRUMENT]: async (ctx, instument_id) => {
		const response = await monitorApi.get(
			`/download-ccb/instrument/${instument_id}`
		);

		return response.data?.result?.link;
	},
	[types.GET_CNAE_DESCRIPTIONS]: async (ctx, cnaeCodes) => {
		const descriptions = [];

		cnaeCodes.forEach(async (subclasse) => {
			const response = await publicIBGEApi.get("", {
				params: {
					view: "subclasse",
					tipo: "cnae",
					versao: "10",
					subclasse,
				},
			});

			const doc = new DOMParser().parseFromString(response.data, "text/html");

			descriptions.push({
				code: subclasse,
				name: doc.querySelector(".destaque")?.innerHTML.substring(10),
			});
		});

		return descriptions;
	},
	[types.DASHBOARD_COMPANY_SCHEDULE]: async ({ commit }, tax_id) => {
		try {
			const response = await payhopApi.get("/schedule-updated", {
				params: { tax_id },
			});

			const result = response.data?.filter((it) => it?.free_value !== null);

			commit(types.DASHBOARD_COMPANY_SCHEDULE, result || []);
		} catch (error) {
			commit(types.DASHBOARD_COMPANY_SCHEDULE, []);
			throw error;
		}
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
