export const remoteDeleteGuarantor = (
	graphQLClient,
	updateGuarantorMutation
) => ({
	run: async (person, guarantors, transactionId) => {
		const { taxId } = person;

		const updatedGuarantors = guarantors.filter(
			(guarantor) => guarantor.taxId !== taxId
		);

		const variables = {
			guarantors: updatedGuarantors,
			transactionIdGraphql: transactionId,
		};
		await graphQLClient.mutate({
			client: "underwriting",
			mutation: updateGuarantorMutation,
			variables,
		});

		return updatedGuarantors;
	},
});
