import { localStoreCompanyData, getDefaultModel } from "@/core/usecases/people";

import store from "@/store";
import * as types from "@/store/types";

import { UFS } from "@/core/constants";

const ufs = [...UFS]
	.sort((a, b) => (a.nome > b.nome ? 1 : -1))
	.map(({ sigla }) => ({
		id: sigla,
		name: sigla,
	}));

export const makeLocalStoreCompanyData = () =>
	localStoreCompanyData(
		store,
		ufs,
		types.PEOPLE_COMPANY_PROFILE,
		getDefaultModel("companyProfile"),
		types.PEOPLE_COMPANY_CONTACT,
		getDefaultModel("companyContact")
	);
