import * as types from "@/store/types";
import shortly from "vuex-shortly";
import { makeRemoteLoadPartners } from "@/core/factories";

const loadPartners = makeRemoteLoadPartners();

const state = {
	partners: [],
	metadata: null,
};

const getters = {
	[types.PARTNERS_LIST]: "partners",
	[types.PARTNERS_MAP]: (state) =>
		state.partners.reduce(
			(partnersMap, partner) => ({
				...partnersMap,
				[partner.cnpj]: partner,
			}),
			{}
		),
};

const mutations = {
	[types.PARTNERS_LIST]: "partners",
	[types.PARTNERS_METADATA]: "metadata",
};

const actions = {
	[types.PARTNERS_LIST]: async (
		{ commit, state },
		metadata = { limit: 50 }
	) => {
		if (state.partners.length) {
			return state.partners;
		}

		const response = await loadPartners.run({ metadata });
		commit(types.PARTNERS_LIST, response.data);
		commit(types.PARTNERS_METADATA, response.metadata);
		return response;
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});