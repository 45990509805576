import { makeSimulateOperationUseCase } from "@a55-tech/front-core";

import { tokenKey } from "@/core/utils";
import { saveCompanyProfileToFile } from "@/core/usecases";
import { makeRemoteGetPeopleRepository } from "./remoteGetPeopleRepositoryFactory";
import { makeGetPersonDocumentGroups } from "./getPersonDocumentGroupsFactory";
import { companyProfilePreviewRepository } from "../repositories";

export const makeSaveCompanyProfilePreviewFactory = () =>
	saveCompanyProfileToFile(
		companyProfilePreviewRepository(),
		makeRemoteGetPeopleRepository(),
		makeGetPersonDocumentGroups(),
		makeSimulateOperationUseCase(
			process.env.VUE_APP_A55_CUSTODY_API_REST,
			process.env.VUE_APP_A55_PROPOSALS_API,
			tokenKey
		)
	);
