import i18n from "@/plugins/i18n";
import { getErrorMessages } from "@/core/usecases";

const formatMoney = (value, options = null) => {
	return new Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
		...options,
	}).format(value);
};

const formatPercentage = (value, fractionDigits = 2) =>
	value.toFixed(fractionDigits).replace(".", ",") + "%";

export const makeGetErrorMessages = () =>
	getErrorMessages(i18n, formatMoney, formatPercentage);
