export const getProcuratorAttributes = (procuratorTaxId, procuratorsList) => {
	const foundProcurator = procuratorsList.find(
		(procurator) => procurator.procuratorTaxId === procuratorTaxId
	);

	return {
		isProcurator: !!foundProcurator,
		procuratorId: foundProcurator?.id,
		isSignerProcurator: foundProcurator?.isSigner || false,
	};
};
