export const remoteGetClientBusinessData = (
	graphQLClient,
	query,
	parseClientBusinessData
) => ({
	run: async (companyTaxId) =>
		graphQLClient
			.query({
				fetchPolicy: "no-cache",
				query,
				variables: {
					cnpj: companyTaxId,
				},
			})
			.then((response) => response.data?.allClient?.edges?.[0]?.node)
			.then(parseClientBusinessData.run),
});
