import { remoteSimulateProposalAccepted } from "@/core/usecases";
import { makeAuthorizeHttpClientDecorator } from "./authorizeHttpClientDecoratorFactory";
import { makeFormatDateAdapter } from "./formatDateAdapterFactory";
import { makeFormatMoneyAdapter } from "./formatMoneyAdapterFactory";
import { convertFromCents, convertToCents } from "@/core/money";

const formatPercentage = (value, fractionDigits = 2) =>
	value.toFixed(fractionDigits).replace(".", ",") + "%";

export const makeSimulateProposalAccepted = () =>
	remoteSimulateProposalAccepted(
		makeAuthorizeHttpClientDecorator(),
		process.env.VUE_APP_A55_PROPOSALS_API,
		convertFromCents,
		convertToCents,
		makeFormatDateAdapter(),
		makeFormatMoneyAdapter(),
		formatPercentage
	);
