export const remoteUpdateClientGeneralData = (
	graphQLClient,
	mutation,
	parseClientGeneralData
) => ({
	run: async (companyTaxId, model) => {
		const riscoSocioAmbientalIdGraphql = model.riscoSocioAmbiental.id;
		const taxTypeIdGraphql = model.taxType.id;

		const variables = {
			cnpj: companyTaxId,
			...model,
			riscoSocioAmbientalIdGraphql,
			taxTypeIdGraphql,
		};

		return graphQLClient
			.mutate({
				fetchPolicy: "no-cache",
				mutation,
				variables,
			})
			.then((response) => response.data?.updateClient?.client)
			.then(parseClientGeneralData.run);
	},
});
