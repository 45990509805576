import axios from "axios";

export const createHttpClientAdapter = () => ({
	async request(request) {
		let response;

		try {
			response = await axios.request({
				url: request.url,
				method: request.method,
				headers: request.headers,
				data: request.body,
				params: request.query,
			});
		} catch (error) {
			response = error.response;
		}

		return {
			body: response.data,
			statusCode: response.status,
		};
	},
});

export const HttpMethod = {
	DELETE: "DELETE",
	GET: "GET",
	PATCH: "PATCH",
	POST: "POST",
	OPTIONS: "OPTIONS",
	PUT: "PUT",
};

export const HttpStatusCode = {
	OK: 200,
	CREATED: 201,
	NO_CONTENT: 204,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	SERVER_ERROR: 500,
	SERVICE_UNAVAILABLE: 503,
};
