export const remoteUpdateComplementaryData = (
	graphQLClient,
	updateComplementaryData
) => ({
	run: async (models, person) => {
		try {
			const personIdGraphql = person.id;

			await graphQLClient.mutate({
				mutation: updateComplementaryData,
				variables: {
					personIdGraphql,
					...models.complementaryStored,
					...models.pepStored,
				},
			});
		} catch (e) {
			console.log(e);
		}
	},
});
