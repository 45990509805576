export const remoteDeletePerson = (
	remoteDeleteGuarantor,
	remoteDeleteRepresentative,
	remoteDeleteProcurator,
	remoteDeleteCompany
) => ({
	run: async (person, guarantors, transactionId) => {
		const actions = {
			PF: remoteDeleteRepresentative,
			PJ: remoteDeleteCompany,
		};

		if (person?.id) {
			await actions[person.type].run(person);
		}

		const foundGuarantor = guarantors.find(
			(guarantor) => guarantor.taxId === person.taxId
		);

		if (foundGuarantor) {
			const updatedGuarantors = await remoteDeleteGuarantor.run(
				person,
				guarantors,
				transactionId
			);

			if (person?.id) {
				return updatedGuarantors;
			}
		}

		if (person?.procuratorId) {
			await remoteDeleteProcurator.run(person.procuratorId);
		}

		return guarantors;
	},
});
