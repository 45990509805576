import { remoteUpdateProposal } from "@/core/usecases";
import { makeRemoteCreateRates } from "./remoteCreateRatesFactory";
import { makeRemoteRefuseProposal } from "./remoteRefuseProposalFactory";
import { makeAuthorizeHttpClientDecorator } from "./authorizeHttpClientDecoratorFactory";

export const makeRemoteUpdateProposal = () =>
	remoteUpdateProposal(
		makeAuthorizeHttpClientDecorator(),
		process.env.VUE_APP_A55_PROPOSALS_API,
		makeRemoteCreateRates(),
		makeRemoteRefuseProposal()
	);
