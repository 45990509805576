export const remoteGetClientGeneralData = (
	graphQLClient,
	query,
	parseClientGeneralData
) => ({
	run: async (companyTaxId) =>
		graphQLClient
			.query({
				fetchPolicy: "no-cache",
				query,
				variables: {
					cnpj: companyTaxId,
				},
			})
			.then((response) => response.data?.allClient?.edges?.[0]?.node)
			.then(parseClientGeneralData.run),
});
