export const formatMoneyAdapter =
	() =>
	(value, options = null) => {
		const formatter = new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
			...options,
		});

		return formatter.format(value);
	};
