export const remoteGetAllTaxType = (graphQLClient, query) => ({
	run: async () =>
		graphQLClient
			.query({
				query,
			})
			.then(
				(response) =>
					response?.data?.allTaxType?.edges
						?.map(({ node }) => node)
						?.map(({ description, id }) => ({
							name: description,
							id,
						})) || []
			),
});
