import Vue from "vue";

if (process.env.NODE_ENV === "test") {
	Vue.config.silent = true;
	Vue.config.devtools = false;
	Vue.config.productionTip = false;
}

export const createEventAdapter = () => {
	const eventBus = new Vue();

	return {
		emit(event, payload) {
			eventBus.$emit(event, payload);
		},
		on(event, callback) {
			eventBus.$on(event, callback);
		},
	};
};
