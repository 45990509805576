import shortly from "vuex-shortly";
import * as types from "@/store/types";

const state = {
	transactions: [],
	transaction: null,
	clientProcess: null,
	companyDetails: null,
	ccbLinks: null,
};

const getters = {
	[types.UNDERWRITING_TRANSACTIONS]: "transactions",
	[types.UNDERWRITING_TRANSACTION_DETAILS]: "transaction",
	[types.UNDERWRITING_CLIENT_PROCESS]: "clientProcess",
	[types.UNDERWRITING_COMPANY_DETAILS]: "companyDetails",
	[types.UNDERWRITING_CCB_LINKS]: "ccbLinks",
};

const mutations = {
	[types.UNDERWRITING_TRANSACTIONS]: "transactions",
	[types.UNDERWRITING_TRANSACTION_DETAILS]: "transaction",
	[types.UNDERWRITING_CLIENT_PROCESS]: "clientProcess",
	[types.UNDERWRITING_COMPANY_DETAILS]: "companyDetails",
	[types.UNDERWRITING_CCB_LINKS]: "ccbLinks",
};

const actions = {
	[types.UNDERWRITING_TRANSACTIONS]: async ({ commit }, arr) => {
		commit(types.UNDERWRITING_TRANSACTIONS, [...arr]);
	},
	[types.UNDERWRITING_TRANSACTION_DETAILS]: ({ commit }, details) => {
		if (details) commit(types.UNDERWRITING_TRANSACTION_DETAILS, details);
		else commit(types.UNDERWRITING_TRANSACTION_DETAILS, {});
	},
	[types.UNDERWRITING_CLIENT_PROCESS]: ({ commit }, clientProcess) => {
		if (clientProcess) commit(types.UNDERWRITING_CLIENT_PROCESS, clientProcess);
		else commit(types.UNDERWRITING_CLIENT_PROCESS, {});
	},
	[types.UNDERWRITING_COMPANY_DETAILS]: ({ commit }, companyDetails) => {
		if (companyDetails)
			commit(types.UNDERWRITING_COMPANY_DETAILS, companyDetails);
		else commit(types.UNDERWRITING_COMPANY_DETAILS, {});
	},
	[types.UNDERWRITING_CCB_LINKS]: async ({ commit }, ccbLinks) => {
		commit(types.UNDERWRITING_CCB_LINKS, ccbLinks);
	},
};

export default shortly({
	state,
	getters,
	mutations,
	actions,
});
