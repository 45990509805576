import { remoteGetAllTaxType } from "@/core/usecases";
import { clients } from "@/plugins/apollo";
import gql from "graphql-tag";

const query = gql`
	query {
		allTaxType {
			edges {
				node {
					id
					description
				}
			}
		}
	}
`;

export const makeRemoteGetAllTaxType = () =>
	remoteGetAllTaxType(clients["client-manager"], query);
