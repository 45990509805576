export const remoteGetDisbursementAccount = (
	getBankList,
	graphQLClient,
	query
) => ({
	run: async (companyTaxId) => {
		const variables = {
			borrowerTaxId: companyTaxId,
		};

		return graphQLClient
			.query({
				fetchPolicy: "no-cache",
				errorPolicy: "ignore",
				query,
				variables,
			})
			.then((response) => response?.data?.allTransaction?.edges[0].node)
			.then((response) =>
				response.bankName
					? {
							...response,
							bankName: getBankList
								.run()
								.find((it) => it.id === response?.bankName),
					  }
					: {
							bankName: "",
							bankAgency: "",
							bankAccount: "",
							bankAccountDigit: "",
					  }
			);
	},
});
