import * as names from "../names";

const component = {
	[names.FORMALIZATION_INDEX]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'underwriting' */ "@/views/Formalization/Underwriting"),
	[names.FORMALIZATION_TRANSACTION_VIEW]: import(
		/* webpackPrefetch: true */ /* webpackChunkName: 'underwriting' */ "@/views/Formalization/Underwriting/TransactionView"
	),
	[names.FORMALIZATION_TRANSACTION_DOCS]: import(
		/* webpackPrefetch: true */ /* webpackChunkName: 'underwriting' */ "@/views/Formalization/Underwriting/ClientProcessList"
	),
	[names.FORMALIZATION_TRANSACTION_CCB]: import(/* webpackPrefetch: true */ /* webpackChunkName: 'underwriting' */ "@/views/Formalization/Underwriting/Ccb"),
	[names.FORMALIZATION_TRANSACTION_PROFILE]: import(
		/* webpackPrefetch: true */ /* webpackChunkName: 'underwriting' */ "@/views/Formalization/Underwriting/CompanyProfile"
	),
	[names.FORMALIZATION_TRANSACTION_ALLOCATION]: import(
		/* webpackPrefetch: true */ /* webpackChunkName: 'underwriting' */ "@/views/Formalization/Underwriting/Allocation"
	),
};

const routes = {
	path: "formalization",
	name: names.FORMALIZATION_INDEX,
	meta: {
		label: "Formalização",
		icon: "assignment",
		path: "/formalization",
		hidden: true,
	},
	children: [
		{
			path: ":transactionId",
			name: names.FORMALIZATION_TRANSACTION_VIEW,
			redirect: { name: names.FORMALIZATION_TRANSACTION_DOCS },
			meta: {
				hideLabel: true,
				label: "transaction",
			},
			children: [
				{
					path: "docs",
					name: names.FORMALIZATION_TRANSACTION_DOCS,
					meta: {
						label: "Documentação",
					},
				},
				{
					// props: true,
					path: "profile",
					name: names.FORMALIZATION_TRANSACTION_PROFILE,
					meta: {
						label: "Ficha Cadastral",
					},
				},
				{
					path: "ccb",
					name: names.FORMALIZATION_TRANSACTION_CCB,
					meta: {
						label: "CCB",
					},
				},
				{
					path: "allocation",
					name: names.FORMALIZATION_TRANSACTION_ALLOCATION,
					meta: {
						label: "Alocação",
					},
					props: (route) => ({ transactionId: route.params.transactionId }),
				},
			],
		},
	],
};

const getRouteViewByName = (route) => {
	const view = component?.[route?.name];
	const hasChildren = route?.children?.length;
	return {
		...route,
		children: hasChildren && route.children.map(getRouteViewByName),
		component: () => (view ? view : route.component),
		hidden: true,
	};
};

export default getRouteViewByName(routes);
