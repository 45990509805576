import { HttpMethod, HttpStatusCode } from "@/core/adapters";
import { PROPOSAL_STATUS } from "@/core/entities";
import {
	NotFoundError,
	UnauthorizedError,
	UnexpectedError,
} from "@/core/errors";

export const remoteDeleteRate = (httpClient, baseUrl) => ({
	run: async (rate, currentProposal, oldProposal) => {
		const blockedStatusesForUpdate = [
			PROPOSAL_STATUS.ACCEPTED,
			PROPOSAL_STATUS.INDICATIVE,
			PROPOSAL_STATUS.REFUSED,
		];

		if (blockedStatusesForUpdate.includes(oldProposal.status)) {
			throw new UnauthorizedError(
				"Não é possível deletar ofertas de propostas com status de aceita, recusada ou indicativa!"
			);
		}

		if (currentProposal.rates.length === 1) {
			throw new UnauthorizedError(
				"A proposta precisa ter pelo menos uma oferta!"
			);
		}

		const body = { reason_for_change: currentProposal.reason_for_change };

		const response = await httpClient.request({
			method: HttpMethod.DELETE,
			url: `${baseUrl}/rates/${rate.id}`,
			body,
		});

		switch (response.statusCode) {
			case HttpStatusCode.NO_CONTENT:
				return true;

			case HttpStatusCode.NOT_FOUND:
				throw new NotFoundError(`Oferta com id #${rate.id} não encontrada!`);

			default:
				throw new UnexpectedError("Erro ao deletar oferta!");
		}
	},
});
