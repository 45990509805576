import {
	ApolloClient,
	InMemoryCache,
	HttpLink,
	ApolloLink,
} from "@apollo/client/core";
import Vue from "vue";
import store from "@/store/";
import VueApollo from "vue-apollo";
import * as types from "@/store/types";

Vue.use(VueApollo);

const clientsUris = {
	auth: process.env.VUE_APP_A55_AUTH_GRAPHQL,
	"client-manager": process.env.VUE_APP_A55_CLIENT_MANAGER_GRAPHQL,
	monitor: process.env.VUE_APP_A55_MONITOR_GRAPHQL,
	underwriting: process.env.VUE_APP_A55_UNDERWRITING_GRAPHQL,
};

const getHeader = () => {
	return `Token ${store.getters[types.AUTH_TOKEN]}`;
};

const makeClient = (uri) => {
	return new ApolloClient({
		cache: new InMemoryCache({
			addTypename: false,
		}),
		subscriptions: false,
		link: new ApolloLink((operation, forward) => {
			operation.setContext({
				headers: {
					authorization: getHeader(),
				},
			});
			return forward(operation);
		}).concat(new HttpLink({ uri })),
	});
};

const makeClients = () => {
	return Object.keys(clientsUris).reduce((acc, curr) => {
		acc[curr] = makeClient(clientsUris[curr]);
		return acc;
	}, {});
};

function createApolloProvider(clients, defaultClient) {
	return new VueApollo({
		clients,
		defaultClient,
		defaultOptions: {
			$query: {
				fetchPolicy: "cache-and-network",
			},
		},
		errorHandler(error) {
			// eslint-disable-next-line
			console.log(
				"%cError",
				"background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
				error.message
			);
		},
	});
}

const clients = makeClients();

const apolloProvider = createApolloProvider(clients, clients.monitor);

export { clients, apolloProvider };
