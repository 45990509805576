export const remoteDeleteRepresentative = (
	graphQLClient,
	representativeDeleteMutation
) => ({
	run: async (person) => {
		try {
			return await graphQLClient.mutate({
				client: "client-manager",
				mutation: representativeDeleteMutation,
				variables: {
					clientRepresentativeIdGraphql: person?.id,
				},
			});
		} catch (error) {
			console.log(error);
		}
	},
});
