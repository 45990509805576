export const remoteCreateCompanyRelationship = (graphQLClient, mutation) => ({
	run: (parentCompanyId, childCompanyId, percentageOwned) => {
		const variables = {
			parentCompanyId,
			childCompanyId,
			percentageOwned,
		};

		return graphQLClient.mutate({
			mutation,
			variables,
		});
	},
});
