import { NotFoundError, UnexpectedError } from "@/core/errors";
import { getLocalDate } from "@/core/utils";

export const remoteLoadDownloadCCBLink = (httpClient, url) => ({
	run: async (operationId) => {
		const response = await httpClient.request({
			method: "GET",
			url: `${url}/download-ccb/${operationId}`,
		});

		switch (response.statusCode) {
			case 200: {
				const data = response.body?.result;
				return data.map(({ date, link }) => ({
					link,
					date: getLocalDate(date),
				}));
			}

			case 404:
				throw new NotFoundError("Arquivo não encontrado!");

			default:
				throw new UnexpectedError("Erro ao obter link de download do arquivo!");
		}
	},
});
